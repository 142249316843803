// // implemeted trading view chart 

import React, { useEffect, useRef } from 'react';
import './TvChart.css';

let tvScriptLoadingPromise;

export default function TvChart({ symbol }) {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;
        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;

    function createWidget() {
      if (document.getElementById('tradingview_0afdf') && 'TradingView' in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: symbol,
          interval: "D",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "in",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_0afdf"
        });
      }
    }
  }, [symbol]);

  return (
    <div className='tradingview-widget-container'>
      <div id='tradingview_0afdf' />
      <div className="tradingview-widget-copyright">
        {/* <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a> */}
      </div>
    </div>
  );
}


// import React, { useEffect, useRef } from 'react';
// import './TvChart.css';


//  let tvScriptLoadingPromise;
 
//  export default function TvChart({ symbol  }) { // symbol prop received here
//    const onLoadScriptRef = useRef();
 
//    useEffect(
//      () => {
//        onLoadScriptRef.current = createWidget;
 
//        if (!tvScriptLoadingPromise) {
//          tvScriptLoadingPromise = new Promise((resolve) => {
//            const script = document.createElement('script');
//            script.id = 'tradingview-widget-loading-script';
//            script.src = 'https://s3.tradingview.com/tv.js';
//            script.type = 'text/javascript';
//            script.onload = resolve;
 
//            document.head.appendChild(script);
//          });
//        }
 
//        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
 
//        return () => onLoadScriptRef.current = null;
 
//        function createWidget() {
//          if (document.getElementById('tradingview_0afdf') && 'TradingView' in window) {
//            new window.TradingView.widget({
//              autosize: true,
//              symbol: symbol, // use symbol prop here
//              interval: "D",
//              timezone: "Etc/UTC",
//              theme: "dark",
//              style: "1",
//              locale: "in",
//              toolbar_bg: "#f1f3f6",
//              enable_publishing: false,
//              allow_symbol_change: true,
//              container_id: "tradingview_0afdf"
//            });
//          }
//        }
//      },
//      [symbol] // add symbol prop as dependency here
//    );
 
//    return (
//      <div className='tradingview-widget-container'>
//        <div id='tradingview_0afdf' />
//        <div className="tradingview-widget-copyright">
//          {/* <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a> */}
//        </div>
//      </div>
//    );
//  }
 



