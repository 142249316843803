import React from 'react'
import Feature from './Feature'

const FeatureList = ({features}) => {
  return (
    <div className="flex flex-wrap justify-center gap-3 max-md:w-[90%] mx-auto">
    {features.map((feature) => (
      <Feature key={feature.id} feature={feature} />
    ))}
  </div>
  )
}

export default FeatureList