import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CurrentPrice from "../../../Screener/components/stock-price";
import PricePercentage from "../../../Screener/components/price-percentage";
import MarketCapitalisation from "../../../Screener/components/marketcap";
import PriceToEarning from "../../../Screener/components/PEratio";
import PriceToBook from "../../../Screener/components/PBratio";
import "./watchlistTable.css";

// replacing the % with "-" hypens
const getHyphenatedName = (name) => {
  return name ? name.replace(/ /g, "-") : "";
};


function WatchlistTable({ watchlist, handleRemoveFromWatchlist, getFinalStatusColor, getFinalStatusText }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemToDelete(null);
  };

  const confirmDelete = () => {
    handleRemoveFromWatchlist(itemToDelete);
    handleCloseDialog();
  };

  return (
    <div className="watchlist_table_container">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer className="watchlist_table_scroll">
          <Table stickyHeader aria-label="watchlist table" className="watchlist_table">
            <TableHead>
              <TableRow className="watchlist_table_header">
                <TableCell className="header_cell_serial">S. No</TableCell>
                <TableCell className="header_cell_company">Company Name</TableCell>
                <TableCell className="header_cell_shariah">Status</TableCell>
                <TableCell className="header_cell_currentprice">Current Price</TableCell>
                <TableCell className="header_cell_pricechange">Change</TableCell>
                <TableCell className="header_cell_Marketcap">Market Cap.</TableCell>
                <TableCell className="header_cell_revenuegrowth">Revenue Growth</TableCell>
                <TableCell className="header_cell_profitgrowth">Profit Growth</TableCell>
                <TableCell className="header_cell_peratio">P/E Ratio</TableCell>
                <TableCell className="header_cell_pbratio">P/B Ratio</TableCell>
                <TableCell className="header_cell_actionheader">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {watchlist.map((item, index) => (
                <TableRow hover key={index} className="watchlist_table_row">
                  <TableCell className="data_cell_serial">{index + 1}</TableCell>
                  <TableCell className="data_cell_company">
                    {/* <Link className="Watchlist_compnayName_muitable" to={`/company/${encodeURIComponent(item["Company Name"])}`}>
                      {item["Company Name"]}
                    </Link> */}
                    <Link className="Watchlist_compnayName_muitable" to={`/company-name/${encodeURIComponent(getHyphenatedName(item["Company Name"]))}`}>
                      {item["Company Name"]}
                    </Link>
                  </TableCell>
                  <TableCell className="data_cell_status">
                    <span className="shariahStatus_watchlist" style={{ color: getFinalStatusColor(item) }}>
                      {getFinalStatusText(item)}
                    </span>
                  </TableCell>
                  <TableCell className="data_cell_currentPrice">
                    <CurrentPrice className={"watchlist__currentprice"} symbol={item["NSE_Symbol_NS"]} />
                  </TableCell>
                  <TableCell className="data_cell_pricechnage">
                    <PricePercentage className={"watchlist__pricechange_css"} symbol={item["NSE_Symbol_NS"]} />
                  </TableCell>
                  <TableCell className="data_cell_marketcap">
                    <MarketCapitalisation className={"watchlist_css_marketcap"} symbol={item["NSE_Symbol_NS"]} outstandingShares={item["Outstanding Shares"]} />
                  </TableCell>
                  <TableCell className="data_cell_salesgrowth">{item["Sales Growth"] ? parseFloat(item["Sales Growth"]).toFixed(2) : "0.00"}</TableCell>
                  <TableCell className="data_cell_profitgrowth">{item["Profit Growth"] ? parseFloat(item["Profit Growth"]).toFixed(2) : "0.00"}</TableCell>
                  <TableCell className="data_cell_peratio">
                    <PriceToEarning className={"watchlistcss__peratio"} symbol={item["NSE_Symbol_NS"]} earningPerShare={item["EPS"]} />
                  </TableCell>
                  <TableCell className="data_cell_pbratio">
                    <PriceToBook className={"watchlistcss__pbratio"}  symbol={item["NSE_Symbol_NS"]} bookValuePerShare={item["BV PS"]} />
                  </TableCell>
                  <TableCell className="data_cell_actions">
                    <div  onClick={() => {
                        setItemToDelete(item);
                        setOpenDialog(true);
                    }}>
                      <FaTrash className='watchlist_delte__icon' />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
  open={openDialog}
  onClose={handleCloseDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  className="custom-dialog__watchlistdelte_dialog"
>
  <DialogTitle id="alert-dialog-title" className="custom-dialog-title_watchlistdelete">Confirm Deletion</DialogTitle>
  <DialogContent className="custom-dialog-content__watchlistdelete">
    <DialogContentText id="alert-dialog-description" className="custom-dialog-content-text__watchlist">
      Are you sure you want to delete this item from your watchlist?
    </DialogContentText>
  </DialogContent>
  <DialogActions className="custom-dialog-actions__watchlist">
    <Button onClick={handleCloseDialog} className="custom-dialog-cancel-button watchlist">Cancel</Button>
    <Button onClick={confirmDelete} autoFocus className="custom-dialog-confirm-button__watchlist">Confirm</Button>
  </DialogActions>
</Dialog>

    </div>
  );
}

export default WatchlistTable;

