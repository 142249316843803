import React from "react";
import compliantLogo from "../../../images/Compliant.png";
import nonCompliantLogo from "../../../images/Non-compliant.png";
import undefinedImage from "../../../images/excalmatorymark.png"; // Adjust the path


function ShariahComplianceCalculator({ shariahCompliance }) {
  const calculateComplianceStatus = () => {
    if (
      !shariahCompliance ||
      shariahCompliance.businessPermissibilityStatus !== "Yes"
    ) {
      return { status: "Non-Compliant", color: "orange", image: nonCompliantLogo };
    }

    const { interestToRevenue, loanToMarketCap, investmentToMarketCap } =
      shariahCompliance;

    if (
      (interestToRevenue === 0 &&
        loanToMarketCap === 0 &&
        investmentToMarketCap === 0) ||
      (interestToRevenue === 0 && loanToMarketCap === 0) ||
      (interestToRevenue === 0 && investmentToMarketCap === 0) ||
      (loanToMarketCap === 0 && investmentToMarketCap === 0)
    ) {
      return { status: "Undefined", color: "blue", image: undefinedImage };
    } else if (
      interestToRevenue < 0.05 &&
      loanToMarketCap < 0.33 &&
      investmentToMarketCap < 0.33
    ) {
      return { status: "Compliant", color: "green", image: compliantLogo };
    } else {
      return { status: "Non-Compliant", color: "orange", image: nonCompliantLogo };
    }
  };

  const { status, color, image } = calculateComplianceStatus();

  return (
    <div
      className={`shariah-compliance-container p-2 rounded-lg ${status.toLowerCase()}-status ${status === "Compliant" ? "border-green-500" : status === "Non-Compliant" ? "border-orange-300" : "border-blue-500"}`}
    >
      <h6 className="text-xl font-semibold text-center mt-0 p-2">Compliance Status</h6>
      <div className="mt-2">
      <img
  className="mx-auto w-25 h-25"
  src={image}
  alt={status}
/>
      </div>


<div className="flex justify-center items-center mt-2">
  <p
    className={`compliance-status-text border-2 px-2 py-1 rounded inline-block ${
      status === "Compliant" ? "text-green-500 border-green-500" : status === "Non-Compliant" ? "text-orange-500 border-orange-500" : "text-blue-500 border-blue-500"
    }`}
  >
    {status}
  </p>
</div>


    </div>
  );
}

export default ShariahComplianceCalculator;
