import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Policies-conflicts.css";

function PoliciesConflict() {
  const [data, setData] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const cacheKey = "policiesConflictData";
      const cacheTimeKey = "policiesConflictCacheTime";
      const cachedData = localStorage.getItem(cacheKey);
      const cachedTime = localStorage.getItem(cacheTimeKey);
      const now = new Date();

      if (
        cachedData &&
        cachedTime &&
        now.getTime() - cachedTime < 5 * 60 * 1000
      ) {
        // Check if data is less than 5 minutes old
        setData(JSON.parse(cachedData));
      } else {
        try {
          const response = await axios.get(
            `${SERVER_URL}/finsha/policies/conflict`,
            {
              withCredentials: true,
            }
          );
          setData(response.data);
          localStorage.setItem(cacheKey, JSON.stringify(response.data)); // Cache the new data
          localStorage.setItem(cacheTimeKey, now.getTime().toString()); // Update cache timestamp
        } catch (error) {
          console.error(`Error: ${error}`);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="policy-page">
        <h1 className="policy-title">
          Conflict of Interest Management Policy and Procedure
        </h1>
        <h3 className="policy-subtitle">
          For the Research Analyst as per Form A of SEBI RA Regulations, 2014
        </h3>
        {data &&
          data.sections.map((section, index) => (
            <div key={index} className="">
              {/* {section.title && (
                <h2 className="section-titlee">{section.title}</h2>
              )} */}
              {section.paragraph && <p className="">{section.paragraph}</p>}
              {section.content &&
                section.content.map((content, cIndex) => (
                  <div key={cIndex} className="content section">
                    <h3 className="section-titlee">{content.title}</h3>
                    {content.paragraph &&
                      content.paragraph.map((para, pIndex) => (
                        <p key={pIndex} className="content-paragraph">
                          {para}
                        </p>
                      ))}
                    {content.paragraph1 &&
                      content.paragraph1.map((para, pIndex) => (
                        <p key={pIndex} className="content-paragraph">
                          {para}
                        </p>
                      ))}
                    {content.bulletPoints &&
                      content.bulletPoints.map((point, pointIndex) => (
                        <li
                          key={pointIndex}
                          className="content-point list-none"
                        >
                          {point}
                        </li>
                      ))}
                    {content.table && (
                      <div className="table-container">
                        <table className="content-table">
                          <thead className="table-head">
                            <tr className="table-row">
                              {content.table.headings.map((heading, hIndex) => (
                                <th key={hIndex} className="table-heading">
                                  {heading}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="table-body">
                            {content.table.rows &&
                            content.table.rows.length > 0 ? (
                              content.table.rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className="table-row">
                                  {Object.values(row).map((cell, cellIndex) => (
                                    <td key={cellIndex} className="table-cell">
                                      {cell === null || cell === ""
                                        ? "NIL"
                                        : cell}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={content.table.headings.length}
                                  className="table-cell-nil"
                                >
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {/* {content.table && (
                      <div className="table-container">
                        <table className="content-table">
                          <thead className="table-head">
                            <tr className="table-row">
                              {content.table.headings.map((heading, hIndex) => (
                                <th key={hIndex} className="table-heading">
                                  {heading}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="table-body">
                            {content.table.rows.map((row, rowIndex) => (
                              <tr key={rowIndex} className="table-row">
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td key={cellIndex} className="table-cell">
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      
                    )} */}
                  </div>
                ))}
            </div>
          ))}
        <div className="grievance-section">
          <p className="grievance-description">
            If you have any grievances, you may submit your Compliants to the
            SEBI Grievance Redressal through SCORES by clicking on the following
            link:
            <a href="https://scores.sebi.gov.in" className="sebi-scores-link">
              {" "}
              https://scores.sebi.gov.in
            </a>
          </p>
          <p className="direct-grievance-text">
            You can also submit your grievances to us directly through our
            <a
              href="https://www.finsha.in/investorsportal"
              className="submit-grievance-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Grievance Portal
            </a>
          </p>
          <p>Last Update : 26/08/2024</p>
        </div>
      </div>
    </>
  );
}

export default PoliciesConflict;
