import React, { useState, useEffect } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";

function MarketCapitalisation({ symbol, outstandingShares, className }) {
  const [marketCap, setMarketCap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const priceRes = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          {
            withCredentials: true,
          }
        );
        const currentPrice = priceRes.data.price;
        const calculatedMarketCap = (
          (currentPrice * outstandingShares) /
          10000000
        ).toFixed(2);

        setMarketCap(calculatedMarketCap);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol, outstandingShares]);

  if (loading) {
    return <Skeleton variant="text" width={200} height={40} />;
  }

  if (error) {
    return <div>---</div>; // Display an error message
  }

  return (
    <>
      <h2 className={className}>₹ {marketCap} cr</h2>
    </>
  );
}

export default MarketCapitalisation;
