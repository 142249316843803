import React from 'react'
import UserExperience from './UserExperience'

const UserExperienceList = ({users}) => {
  return (
    <div className='flex gap-6 overflow-x-auto no-scrollbar'>
    {users.map((user, index) => (
      <UserExperience key={index} user={user} />
    ))}
  </div>
  )
}

export default UserExperienceList;
