// reccuring payment is removed soo there will be noo cancel button
import React from "react";
import { Box } from "@mui/material";
import "./CancelSubscription.css"; // Make sure to import your CSS file
import CardMembershipIcon from "@mui/icons-material/CardMembership";

const CancelSubscription = ({ user }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  };

  return (
    <Box className="cancel-subscription-container">
      <div className="container_cancelsubscription__button">
        <h5 className="managesusbcription__currentplan">
          Current Plan{" "}
          <CardMembershipIcon style={{ color: "white", fontSize: "18px" }} />
        </h5>
        <div className="cancel-subscription-header">
          <div className="left-side">
            <h6
              className="cancel_subscription_typography font-bold bg-clip-text text-transparent"
              style={{
                backgroundImage: "linear-gradient(to right, #00c6ff, #0072ff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {user.subscription.tier === "QuickWin Starter"
                ? "SmartHold Pro"
                : user.subscription.tier}
            </h6>

            {user.subscription.expiryDate && (
              <p className="renewdate_parah">
                Your plan expires on {formatDate(user.subscription.expiryDate)}
              </p>
            )}
          </div>
        </div>
        {user.pendingSubscription && user.pendingSubscription.id && (
          <div className="pending-subscription-section">
            <p className="renewdate_parah">
              You have updated your{" "}
              <span className="text-green-600">
                {user.subscription.duration}
              </span>{" "}
              plan to{" "}
              <span className="text-green-500">
                {user.pendingSubscription.duration}
              </span>{" "}
              and it will activate on <br></br>{" "}
              <span className="text-gray-200 font-bold">
                {formatDate(user.subscription.expiryDate)}
              </span>{" "}
              after the expiry of the current plan.
            </p>
          </div>
        )}
      </div>
    </Box>
  );
};

export default CancelSubscription;

// import React from "react";
// import axios from "axios";
// import { Box, Typography, Modal } from "@mui/material";
// import "./CancelSubscription.css"; // Make sure to import your CSS file
// import CardMembershipIcon from "@mui/icons-material/CardMembership";

// const CancelSubscription = ({ user }) => {
//   const [isModalOpen, setIsModalOpen] = React.useState(false);
//   const [isLoading, setIsLoading] = React.useState(false);
//   const [error, setError] = React.useState(null);
//   const [success, setSuccess] = React.useState(null);
//   const SERVER_URL = process.env.REACT_APP_BACKEND_LOCAL_URL

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setError(null);
//     setSuccess(null);

//     if (success) {
//       window.location.reload();
//     }
//   };

//   const handleCancelSubscription = async () => {
//     try {
//       setIsLoading(true);
//       if (user.subscription.status === "cancelled") {
//         setError("Your subscription is already cancelled.");
//         setIsLoading(false);
//         return;
//       }

//       const response = await axios.post(
//         `${SERVER_URL}/wallet/cancel-subscription`,
//         {
//           subscriptionId: user.subscription.id,
//           userId: user._id,
//         }
//       );

//       if (response.status === 200) {
//         setSuccess("Subscription cancelled successfully");

//         // Update the user object locally to reflect the cancelled status
//         const updatedUser = {
//           ...user,
//           subscription: {
//             ...user.subscription,
//             status: "cancelled",
//           },
//         };

//         // If using Context API or another state management method, update the state here
//         setUser(updatedUser); // Assuming `setUser` is your state updater for the user state
//       } else {
//         setError("Failed to cancel subscription");
//       }
//     } catch (error) {
//       setError(
//         error.response?.data?.error?.description ||
//           "An error occurred while cancelling the subscription"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const options = { day: "2-digit", month: "short", year: "numeric" };
//     return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
//   };

//   return (
//     <Box className="cancel-subscription-container">
//       <div className="container_cancelsubscription__button">
//         <h5 className="managesusbcription__currentplan">
//           Current Plan{" "}
//           <CardMembershipIcon style={{ color: "white", fontSize: "18px" }} />
//         </h5>
//         <div className="cancel-subscription-header">
//           <div className="left-side">
//             <h6 className="cancel_subscription_typography">
//               {user.subscription.tier} Subscription
//               {user.subscription.isFree && " (Free)"}
//             </h6>
//             {user.subscription.expiryDate && (
//               <p className="renewdate_parah">
//                 {user.subscription.isFree ||
//                 (user.subscription.status === "cancelled" &&
//                   user.subscription.activated)
//                   ? "Your plan expires on"
//                   : user.subscription.paymentType === "One-Time"
//                   ? "Your plan expires on"
//                   : user.subscription.paymentType === "Recurring"
//                   ? "Your plan will renew on"
//                   : new Date(user.subscription.expiryDate) < new Date()
//                   ? "Your plan expired on"
//                   : "Your plan renews on"}{" "}
//                 {formatDate(user.subscription.expiryDate)}
//               </p>
//             )}
//           </div>
//           <button
//             onClick={handleOpenModal}
//             className="cancel_subscription__button"
//             disabled={
//               user.subscription.status === "cancelled" ||
//               !user.subscription.activated ||
//               user.subscription.isFree || // Disable button if the subscription is free
//               user.subscription.paymentType === "One-Time" // Disable button if the payment type is One-Time
//             }
//           >
//             {user.subscription.status === "cancelled"
//               ? "Cancelled"
//               : !user.subscription.activated
//               ? "Not Subscribed"
//               : "Cancel"}
//           </button>
//         </div>
//         {user.pendingSubscription && user.pendingSubscription.id && (
//           <div className="pending-subscription-section">
//             <p className="renewdate_parah">
//               You have updated your{" "}
//               <span className="text-green-600">
//                 {user.subscription.duration}
//               </span>{" "}
//               plan to{" "}
//               <span className="text-green-500">
//                 {user.pendingSubscription.duration}
//               </span>{" "}
//               and it will activate on <br></br>{" "}
//               <span className="text-gray-200 font-bold">
//                 {formatDate(user.pendingSubscription.activationDate)}
//               </span>{" "}
//               after the expiry of the current plan.
//             </p>
//           </div>
//         )}
//       </div>

//       <Modal
//         open={isModalOpen}
//         onClose={handleCloseModal}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//         className="cancel-subscription-modal"
//       >
//         <Box className="cancel-subscription-box">
//           {success ? (
//             <>
//               <Typography
//                 id="modal-modal-title"
//                 className="cancelsubscription_confirmtitle"
//                 style={{ color: "green" }}
//               >
//                 {success}
//               </Typography>
//               <button
//                 onClick={handleCloseModal}
//                 variant="contained"
//                 color="primary"
//                 className="cancel-subscription-close__confirmmodel"
//               >
//                 Close
//               </button>
//             </>
//           ) : (
//             <>
//               <Typography
//                 id="modal-modal-title"
//                 className="cancelsubscription_confirmtitle"
//               >
//                 Are you sure you want to cancel your subscription?
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="p"
//                 className="cancel-subscription-message"
//               >
//                 Your plan will be canceled, but is still available until the end
//                 of your billing period on{" "}
//                 {formatDate(user.subscription.expiryDate)}.
//               </Typography>
//               <Typography
//                 variant="body1"
//                 component="p"
//                 className="cancel-subscription-message"
//               >
//                 If you change your mind, you can renew your subscription.
//               </Typography>
//               {error && (
//                 <Typography variant="body2" color="error">
//                   {error}
//                 </Typography>
//               )}
//               <div className="flex justify-end mt-5">
//                 <button
//                   onClick={handleCancelSubscription}
//                   className="mr-2 px-4 py-2 border-2 border-white text-white font-semibold rounded-lg shadow-md hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
//                   disabled={isLoading}
//                 >
//                   {isLoading ? "Cancelling..." : "Confirm"}
//                 </button>
//                 <button
//                   onClick={handleCloseModal}
//                   className="px-4 py-2 bg-gradient-to-r from-green-400 to-green-600 text-white font-semibold rounded-lg shadow-md hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </>
//           )}
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default CancelSubscription;
