import React, { useEffect, useState } from "react";
import NonHalalIncomeChart from "./Nonhalalchart/NonHalalIncomeChart";
import TotalLoanChart from "./TotalLoanchart/TotalLoanChart";
import TotalInvestmentChart from "./TotalInvestment/TotalInvestmentChart";
import { Tooltip } from "@mui/material"; // Import MUI Tooltip
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";

function ShariaCompliantInvestmentChartComponent({ companyName }) {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [activeChart, setActiveChart] = useState("A");
  const [nonHalalData, setNonHalalData] = useState(
    initialData(
      ["#FFB200", "#4CAF50"],
      ["Non Compliant Income", "Total Income"]
    )
  );
  const [loanData, setLoanData] = useState(
    initialData(["#FFA726", "#7E57C2"], ["Total Loan", "Total Market Capital"])
  );
  const [investmentData, setInvestmentData] = useState(
    initialData(
      ["#42A5F5", "#FFEB3B"],
      ["Total Investment", "Total Market Capital"]
    )
  );

  function initialData(backgroundColor, labels) {
    return {
      datasets: [{ data: [0, 0], backgroundColor }],
      labels,
    };
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${SERVER_URL}/screener/company/shariah/${companyName}`,
          {
            withCredentials: true,
          }
        );
        const shariahData = response.data;
        // try {
        //   const response = await fetch(
        //     `${SERVER_URL}/screener/company/shariah/${companyName}`,
        //     {
        //       credentials: "include",
        //     }
        //   );
        //   const shariahData = await response.json();

        setNonHalalData(
          transformData(
            shariahData["Interest to Revenue"],
            ["#FFB200", "#4CAF50"],
            ["Non Compliant Income", "Total Income"]
          )
        );
        setLoanData(
          transformData(
            shariahData["Loan to Market Cap"],
            ["#FFA726", "#7E57C2"],
            ["Total Loan", "Total Market Capital"]
          )
        );
        setInvestmentData(
          transformData(
            shariahData["Investment to Market Cap"],
            ["#42A5F5", "#FFEB3B"],
            ["Total Investment", "Total Market Capital"]
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    function transformData(data, backgroundColor, labels) {
      const percentage = data * 100;
      return {
        datasets: [{ data: [percentage, 100 - percentage], backgroundColor }],
        labels,
      };
    }

    fetchData();
  }, [companyName]);

  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row items-center gap-2 mt-1 mb-1 md:mt-0">
        <ButtonWithInfo
          active={activeChart === "A"}
          onClick={() => setActiveChart("A")}
          shortText="NCI/TI"
          fullText="Non-Compliant Income to Total Income"
        />
        <ButtonWithInfo
          active={activeChart === "B"}
          onClick={() => setActiveChart("B")}
          shortText="TL/AMC"
          fullText="Total Loan to Average Market Capitalisation"
        />
        <ButtonWithInfo
          active={activeChart === "C"}
          onClick={() => setActiveChart("C")}
          shortText="TI/AMC"
          fullText="Total Investment to Average Market Capitalisation"
        />
      </div>
      {activeChart === "A" && <NonHalalIncomeChart data={nonHalalData} />}
      {activeChart === "B" && <TotalLoanChart data={loanData} />}
      {activeChart === "C" && <TotalInvestmentChart data={investmentData} />}
    </div>
  );
}

const ButtonWithInfo = ({ active, onClick, shortText, fullText }) => (
  <button
    className={`${
      active
        ? "bg-gradient-to-r from-green-400 to-green-800 text-white border-transparent"
        : "bg-gradient-to-r from-gray-700 to-gray-900 text-white border-gray-300"
    } px-3 rounded-full font-bold shadow-md border-1 transition-all duration-300 w-48 flex justify-between items-center text-sm`}
    onClick={onClick}
  >
    <span className="flex-grow">{shortText}</span>{" "}
    {/* Text taking up 90% of the button */}
    <Tooltip title={fullText} arrow>
      <span>
        <InfoIcon />
      </span>
    </Tooltip>
  </button>
);

export default ShariaCompliantInvestmentChartComponent;
