import React from 'react';
import GaugeChart from 'react-gauge-chart';
import "./TotalInvestmentChart.css"

function TotalInvestmentChart({ data }) {
  const percentage = data.datasets[0].data[0]; 
  const chartProps = {
    nrOfLevels: 30, // Adjust this as needed
    colors: ["green", "orange", "red"], // Adjust the colors as needed
    arcWidth: 0.3, // Adjust the arc width as needed
    percent: percentage/100, // Ensure this is a value between 0 and 1
    textColor: "black",
    fontSize:"24px",
  };


  return (
  
      <div className="chart-and-data-container_totalInvestment">
        <div className="chart-section_totalInvestment">
          <GaugeChart 
            id="gauge-chart-investment"
            {...chartProps} 
          />
        </div>
        <div className="data-section_totalInvestment">
          <h6 className='totalInvestment_percentage_title'>Total Investment to Avg. Market Cap.</h6>
          <p className='totalInvestment_percentage_data'>{percentage.toFixed(2)}%</p>
        </div>
      </div>
  );
}

export default TotalInvestmentChart;
