import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./ReportDetails.css";
import RatingStarComponent from "../RatingStar/RatingStarComponent";
import axios from "axios";
// import FinancialCard from "../../../Shared/UIElements/Cards/components/FinancialCard"; (to be used later)
import StockPrice from "../../../Screener/components/CurrentPrice";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ReportDetails() {
  const { companyName } = useParams(); // Get the companyName parameter from the route
  const [report, setReport] = useState(null); // State to store the report details
  const location = useLocation(); // Get the current location object
  const [copied, setCopied] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

  // Fetch report details when the component mounts or when companyName changes with replace of space with hypen
  useEffect(() => {
    const formattedCompanyName = companyName.replace(/-/g, " ");
    axios
      .get(`${SERVER_URL}/finview/reports/name/${formattedCompanyName}`)
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the report details: ",
          error
        );
      });
  }, [companyName]);

  // Function to share the report link using the Web Share API
  const shareReport = async () => {
    if (navigator.share) {
      try {
        // Get the current page URL
        let url = window.location.href;
        // Replace %20 with _ using a regular expression
        let modifiedUrl = url.replace(/%20/g, "_");

        await navigator.share({
          title: `Report on ${report.companyName}`, // Optional: You can set a title for the shared content
          url: modifiedUrl, // Share the modified page URL
        });
        console.log("Report shared successfully");
      } catch (error) {
        console.error("Error sharing the report:", error);
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Your browser doesn't support the Web Share API");
    }
  };

  // Function to copy the report link to the clipboard
  const copyReportLink = async () => {
    try {
      // Get the current page URL without replacing %20 with _
      let url = window.location.href;

      await navigator.clipboard.writeText(url);
      setCopied(true);

      // Reset the icon after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy:", error);
      alert("Failed to copy the link.");
    }
  };

  // Render a message if the report is not found
  if (!report) {
    return <div>Report not found</div>;
  }

  return (
    <div className="researchreport__main-container">
      <div className="research-detail__company-container flex justify-between items-start">
        <div>
          <h1 className="research-detail__company-name">
            {report.companyName}
          </h1>
          <span className="research-detail__sector">{report.Sector}</span>
          <StockPrice
            className="company-details-stock-price-small"
            symbol={report.symbol}
          />
        </div>

        <div className="relative">
          <div
            className="report-copy-share mb-2"
            style={{
              top: 0,
              right: 0,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {navigator.share && (
              <ShareIcon
                className="action-icon share-icon mr-4"
                style={{ fontSize: "16px" }}
                onClick={shareReport}
              />
            )}
            {copied ? (
              <span className="text-green-500 text-sm font-semibold">
                Copied!
              </span>
            ) : (
              <ContentCopyIcon
                className="action-icon copy-icon cursor-pointer mr-5"
                style={{ fontSize: "16px" }}
                onClick={copyReportLink}
              />
            )}
          </div>

          {/* Posted Date at the bottom-right */}
          <div
            className="research-detail__posted-date text-right"
            style={{
              bottom: 0,
              right: 0,
              fontSize: "12px", // Optional: Adjust the size
              color: "#777", // Optional: Adjust the color
            }}
          >
            Posted on: {new Date(report.createdAt).toLocaleDateString()}{" "}
            {new Date(report.createdAt).toLocaleTimeString()}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-around border-b-2 border-gray-300">
        <div className="flex flex-col items-center p-2">
          <h6 className="text-2 font-semibold text-gray-700 mb-2">Status</h6>
          <p
            className={`text-2 font-bold border-2 rounded-md px-2 py-1 ${
              report.ShariahStatus === "Compliant"
                ? "text-white bg-green-600"
                : "text-red-500 border-red-500"
            }`}
          >
            {report.ShariahStatus}
          </p>
        </div>

        <div className="flex items-center text-2 font-bold p-3">
          <div className="flex flex-col items-center">
            <h6 className="text-2 font-semibold text-gray-700 mb-2 ">
              Strategy
            </h6>
            <p className="border-2 rounded-md border-black px-2 py-1 bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text">
              {report.Strategy}
            </p>
          </div>
        </div>

        <div className="flex items-center p-3">
          <div className="flex flex-col items-center">
            <h6 className="text-2 font-semibold text-gray-700 mb-2">
              Finsha Rating
            </h6>
            <RatingStarComponent value={report.rating} className="text-xl" />
          </div>
        </div>
      </div>

      <div className="space-y-4 p-4 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-gray-600">
              Recommendation:
            </p>
            <p className="text-lg font-bold text-gray-900">
              {report.recommendation}
            </p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-gray-600">Buy Range:</p>
            <p className="text-lg font-bold text-gray-900">{report.buyRange}</p>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-gray-600">Target Price:</p>
            <p className="text-lg font-bold text-gray-900">
              {report.targetPrice}
            </p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-gray-600">Stop Loss:</p>
            <p className="text-lg font-bold text-gray-900">{report.stopLoss}</p>
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-1/2 pr-2">
            <p className="text-sm font-semibold text-gray-600">
              Holding Period:
            </p>
            <p className="text-lg font-bold text-gray-900">{report.Duration}</p>
          </div>
          <div className="w-1/2 pl-2">
            <p className="text-sm font-semibold text-gray-600">
              CapitalInvestment:
            </p>
            <p className="text-lg font-bold text-gray-900">
              {report.capitalInvestment}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-2 p-2">
        <h5 className="text-lg font-bold text-gray-900">Summary:</h5>
        <p>{report.Summary}</p>
      </div>

      <div className="flex justify-end">
        <a
          className="inline-flex items-center px-4 py-2 text-white font-semibold rounded-full shadow-lg transition duration-200 transform hover:scale-105"
          href={report.reportLink}
          download="Research_Report"
          title="Download Full Research Report"
          style={{
            background: "linear-gradient(145deg, #4A90E2, #50C9C3)",
            boxShadow:
              "0px 4px 10px rgba(0, 0, 0, 0.15), 0px 7px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FileDownloadIcon className="mr-2" />
          Download Full Report
        </a>
      </div>
    </div>
  );
}

export default ReportDetails;
