import React, { useEffect, useState, useRef } from "react";
import peesimage from "../images/3pp.png";
import ShariahComliantCertificate from "../images/Shariah-certificate.jpeg";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./Methodology.css";
import AnimatedHeading from "./AnimatedHeading";

const MethologyPage = () => {
  const [data, setData] = useState({ sections: [] });
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const certificateRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`${SERVER_URL}/finsha/methodology`);
  //       const newData = await res.json();
  //       setData(newData); // Update state with new data
  //     } catch (err) {
  //       console.error("Error fetching methodology data:", err);
  //       // If the error has a response property, it could provide more insight
  //       if (err.response) {
  //         console.log("Response body:", err.response);
  //       }
  //     }
  //     // }
  //   };

  //   fetchData();
  // }, []); // This effect depends on no props or state, so it only runs once after the initial render

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/finsha/methodology`);
        const newData = res.data;
        setData(newData); // Update state with new data
      } catch (err) {
        console.error("Error fetching methodology data:", err);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return (
      <div className="flex justify-center items-center h-screen text-gray-500">
        Loading...
      </div>
    );
  }

  // function to scroll to the certificate section
  const handleSeeCertificateClick = () => {
    if (certificateRef.current) {
      certificateRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mt-24 font-roboto fade-in">
      <Helmet>
        <title>Shariah-Compliant Investing Methodology | Finsha</title>
        <meta
          name="description"
          content="What makes a stock Compliant or Non-Compliant? Understand the methodology behind the Shariah-Compliant investing. Learn about our rating systems and measures."
        />
        <meta
          name="keywords"
          content="Investing Methodology, Shariah Compliance, Rating System"
        />
        <meta
          name="tags"
          content="Shariah Compliant, Investing Methodology, Rating System"
        />
      </Helmet>
      <div className="container max-w-[1200px] mx-auto p-6 rounded-lg">
        <h1 className="heading text-4xl font-bold text-center text-gray-800 mb-10 slide-in-left">
          Our Methodology
        </h1>

        {data.sections &&
          data.sections.map((section, index) => {
            if (index === 0) {
              return (
                <>
                  <div
                    key={index}
                    // className="section mb-12 bg-gray-100 p-8 rounded-lg shadow-lg"
                  >
                    {/* <h2 className="text-3xl font-bold text-blue-700 mb-6">
                      {section.title}
                    </h2> */}
                    <p className="text-gray-900 leading-relaxed mb-6">
                      {section.paragraph}
                    </p>
                    {section.bulletPoints && (
                      <ul className="text-gray-700 mb-6">
                        {section.bulletPoints.map((bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="mt-12 text-center fade-in-up">
                    <div className="mb-8 p-6 bg-gradient-to-r from-blue-400 to-green-400 rounded-lg shadow-md flex max-md:flex-col max-md:w-full overflow-hidden md:grid md:grid-cols-2">
                      <h3 className="text-3xl md:text-4xl text-[#fafafa] font-bold mb-4 slide-in-left flex items-center">
                        <AnimatedHeading
                          heading={`Finsha’s Methodology by Mufti Abdul Aziz Sahab`}
                        />
                      </h3>
                      <div className="flex justify-center">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/qjkf0jw36Ss?si=w7JUXb5aajOJFelk"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </>
              );
            } else if (index === 1) {
              return (
                <div
                  key={index}
                  className="section special-section mb-12 bg-yellow-50 p-8 rounded-lg shadow-lg"
                >
                  <h2 className="text-3xl font-bold text-gray-700 mb-6">
                    {section.title}
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-6">
                    {section.paragraph}
                  </p>
                  {section.bulletPoints && (
                    <ul className="text-gray-600 mb-6">
                      {section.bulletPoints.map((bullet, bIndex) => (
                        <li key={bIndex} className="mb-2">
                          {bullet}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className="special-content mt-6 flex justify-center">
                    <button
                      className="certificate-button"
                      onClick={handleSeeCertificateClick}
                    >
                      See Certificate
                    </button>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className={`section mb-12 ${
                    index % 2 === 0 ? "fade-in-left" : "fade-in-right"
                  }`}
                >
                  <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                    {section.title}
                  </h2>
                  <p className="text-gray-600 leading-relaxed mb-6">
                    {section.paragraph}
                  </p>
                  {section.bulletPoints && (
                    <ul className="text-gray-600 mb-6">
                      {section.bulletPoints.map((bullet, bIndex) => (
                        <li key={bIndex} className="mb-2">
                          {bullet}
                        </li>
                      ))}
                    </ul>
                  )}
                  {section.bulletPointsforstart && (
                    <ol className="text-gray-600 mb-6">
                      {section.bulletPointsforstart.map((bullet, bIndex) => (
                        <li key={bIndex} className="mb-2">
                          {bullet.substring(0)}
                        </li>
                      ))}
                    </ol>
                  )}
                  {section.bulletPointsbusinessscreening && (
                    <ol className="text-gray-600 mb-6">
                      {section.bulletPointsbusinessscreening.map(
                        (bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        )
                      )}
                    </ol>
                  )}
                  {section.bulletPointsbusinessreening && (
                    <ol className="text-gray-600 mb-6">
                      {section.bulletPointsbusinessreening.map(
                        (bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        )
                      )}
                    </ol>
                  )}
                  {section.bulletPointspermissibilityone && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                      <ol className=" text-gray-600 mb-6">
                        {section.bulletPointspermissibilityone.map(
                          (bullet, bIndex) => (
                            <li key={bIndex} className="mb-2">
                              {bullet}
                            </li>
                          )
                        )}
                      </ol>
                      <img
                        className="w-[70%] max-sm:w-[90%] max-h-[300px] rounded-lg shadow-sm mx-auto hover:scale-105 transform transition-transform duration-300 ease-out"
                        src={peesimage}
                        alt="3 p's"
                      />
                    </div>
                  )}
                  <p className="mt-6 text-gray-600">
                    {section.Businessspermiss}
                  </p>
                  {section.bulletPointspermissibilitytwo && (
                    <ol className="ml-6 mt-4">
                      {section.bulletPointspermissibilitytwo.map(
                        (bullet, bIndex) => (
                          <li key={bIndex} className="text-gray-500 mb-2">
                            {bullet}
                          </li>
                        )
                      )}
                    </ol>
                  )}
                  {section.bulletPoints2 && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                      <ol className=" text-gray-600 mb-6">
                        {section.bulletPoints2.map((bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        ))}
                      </ol>
                      <div className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-inner shadow-slate-300 space-y-2 md:space-y-0 md:space-x-4">
                        <div className="transform -skew-x-12">
                          Non-Permissible Income
                        </div>
                        <div className="w-[40%] mx-auto border-t-2 border-black mb-1"></div>
                        <div className="transform -skew-x-12">Total Income</div>
                      </div>
                    </div>
                  )}
                  {section.bulletPoints3 && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                      <ol className=" text-gray-600 mb-6">
                        {section.bulletPoints3.map((bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        ))}
                      </ol>

                      <div className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-inner shadow-slate-300 space-y-2 md:space-y-0 md:space-x-4">
                        <div className="transform -skew-x-12">
                          Interest-Based Investment
                        </div>
                        <div className="w-[50%] mx-auto border-t-2 border-black mb-1"></div>
                        <div className="transform -skew-x-12">
                          Average Market Capitalization
                        </div>
                      </div>
                    </div>
                  )}
                  {section.bulletPoints4 && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                      <ol className=" text-gray-600 mb-6">
                        {section.bulletPoints4.map((bullet, bIndex) => (
                          <li key={bIndex} className="mb-2">
                            {bullet}
                          </li>
                        ))}
                      </ol>

                      <div className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-inner shadow-slate-300 space-y-2 md:space-y-0 md:space-x-4 ">
                        <div className="transform -skew-x-12">
                          Interest-Based Loan
                        </div>
                        <div className="w-[45%] border-t-2 border-black mb-1"></div>
                        <div className="transform -skew-x-12">
                          Average Market Capitalization
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}

        <div className="mt-8 grid grid-cols-1 gap-6 items-center justify-center fade-in-up">
          <div className="flex flex-col md:flex-row items-center justify-center p-4 bg-white rounded-lg shadow-inner shadow-slate-300 space-y-2 md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-center">
              <div className="transform -skew-x-12">Non-Permissible Income</div>
              <div className="w-[70%] border-t-2 border-black mb-1"></div>
              <div className="transform -skew-x-12">Total Income</div>
            </div>
            <div className="text-4xl font-bold">×</div>
            <div className="transform -skew-x-12">
              (Dividend + Capital Gain)
            </div>
          </div>
        </div>

        <div ref={certificateRef} className="text-center mt-12 fade-in-up">
          <h3 className="text-3xl font-semibold text-gray-700 mb-4 slide-in-right">
            Finsha Shariah Compliant Certificate
          </h3>
          {
            <img
              className="rounded-lg max-w-[90%] shadow-sm mx-auto hover:scale-105 transform transition-transform duration-300 ease-out"
              src={ShariahComliantCertificate}
              alt="Shariah Compliant Certificate"
            />
          }
        </div>
      </div>
    </div>
  );
};

export default MethologyPage;
