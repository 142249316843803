import React from "react";

const Toast = ({ header, message, onClose, children, icon }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      <div className="bg-gradient-to-r from-gray-800 to-black text-white rounded-lg p-6 relative w-full max-w-md"> {/* Increased width with max-w-md */}
        {header && (
          <div className="flex justify-center items-center relative mb-4">
            <span className="text-2xl font-bold">{header}</span>
            <span
              onClick={onClose}
              className="absolute right-0 top-0 text-gray-400 cursor-pointer text-xl"
            >
              x
            </span>
          </div>
        )}
        <div className="text-center mb-4">
          {icon && <span className="mr-2">{icon}</span>}
          <p className="text-red-500">{message}</p>
        </div>
        {children}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded transition duration-300"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
