import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./FullArticle.css";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";

function FullArticle() {
  const { title } = useParams();
  const decodedTitle = title.replace(/-/g, " "); //decode the title by replacing the space with "-"
  const [article, setArticle] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // useEffect(() => {
  //   // Fetch using the decoded title
  //   fetch(
  //     `${SERVER_URL}/finview/articles/title/${encodeURIComponent(
  //       decodedTitle
  //     )}`,
  //     {
  //       credentials: "include",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => setArticle(data))
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, [decodedTitle]);

  // replace fetch with axios for code consistency
  useEffect(() => {
    // Fetch using the decoded title
    axios
      .get(
        `${SERVER_URL}/finview/articles/title/${encodeURIComponent(
          decodedTitle
        )}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setArticle(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [decodedTitle]);

  // if there are no article available it will show this message
  if (!article) {
    return <div>Article not found</div>;
  }

  // The purpose of this function is to convert a regular YouTube video URL into a "no-cookie" version of the URL.
  function convertToNoCookieURL(url) {
    return url.replace("www.youtube.com", "www.youtube-nocookie.com");
  }

  // share button funtion to share the link of the full article
  function shareArticle(article) {
    // Replace spaces encoded as `%20` with underscores in the URL
    const formattedUrl = window.location.href.replace(/%20/g, "_");

    if (navigator.share) {
      navigator
        .share({
          title: "Check out this article!",
          text: article.title, // Assuming 'article' is an object with a 'title' property
          url: formattedUrl, // Use the modified URL here
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      // Fallback for browsers that do not support the Web Share API
      copyToClipboard(); // Make sure this function uses the modified URL as well
    }
  }

  // copy button funtion which on click copy the url witht he converted url
  function copyToClipboard() {
    // Replace spaces encoded as `%20` with underscores in the URL
    const formattedUrl = window.location.href.replace(/%20/g, "_");

    navigator.clipboard
      .writeText(formattedUrl)
      .then(() => {
        setIsCopied(true);
        // Optionally reset `isCopied` after a few seconds
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch((err) => {
        console.error("Error copying URL:", err);
      });
  }

  // formated date which is shown in DD-MM-YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    // Format date as 'DD-MM-YYYY'
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    // Format time as 'hh:mm am/pm'
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${(hours % 12).toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <div className="full-article-container">
        <div className="article-info">
          <h1 className="article-title">{article.title}</h1>
        </div>
        {/* div for the copy and share */}
        <div className="article-header_details_date">
          <p className="article-date_fulldetails">
            Posted on :{formatDate(article.date)}
          </p>
          <ShareIcon
            className="share-icon"
            onClick={shareArticle}
            fontSize="18px"
            style={{ color: "#0D7C66" }}
          />
          {isCopied ? (
            <span className="copied-text text-green-500">Copied!</span>
          ) : (
            <ContentCopyIcon
              className="copy-icon"
              onClick={copyToClipboard}
              fontSize="18px"
              style={{ color: "#0D7C66" }}
            />
          )}
        </div>
        {/* the portion where the articles and the videos are shown if video is avaible it will show if not it will show only the paragraph */}
        {article.videoUrl ? (
          <div className="video-wrapper">
            <iframe
              src={convertToNoCookieURL(article.videoUrl)}
              frameBorder="0"
              allowFullScreen
              title={`Video for article ${article.title}`}
              className="article-video"
            />
          </div>
        ) : article.imageUrl ? (
          <div className="image-wrapper">
            <img
              src={article.imageUrl}
              alt={`Image for article ${article.title}`}
              className="w-full h-48 rounded-lg shadow-lg border border-gray-300"
            />
          </div>
        ) : null}

        <div className="article-text">
          {article?.content?.split("\n").map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>
      </div>
    </>
  );
}

export default FullArticle;
