import React, { useState, useEffect } from "react";
import axios from "axios";

function CurrentPrice({ symbol, className }) {
  const [price, setPrice] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    let isMounted = true;

    const fetchPrice = async () => {
      try {
        const priceRes = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          {
            withCredentials: true,
          }
        );
        if (isMounted) {
          setPrice(priceRes.data.price);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchPrice();

    return () => {
      isMounted = false;
    };
  }, [symbol]);

  return (
    <div className={className}>
      {price && <h4>₹ {price}</h4>}
      {error && <p></p>}
    </div>
  );
}

export default CurrentPrice;
