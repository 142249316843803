import React, { useState } from 'react';
import PostMarketView from "../component/PostMarketView";
import SearchArticleComponent from "../component/SearchArticleComponent";
import ClearIcon from '@mui/icons-material/Clear';

function AdminMarketView() {
  const [activeComponent, setActiveComponent] = useState(null);

  const buttons = [
    {
      id: 'postMarketView',
      title: 'Post Market View',
      description: 'This is where we can post the latest market analysis data so that it is immediately visible to users, helping them stay informed about market trends.',
      gradient: 'bg-gradient-to-r from-green-400 to-teal-500 hover:from-green-500 hover:to-teal-600',
      buttonText: 'Post New Market Article',
    },
    {
      id: 'searchArticle',
      title: 'Delete / Update Market Analysis',
      description: 'Search and manage articles related to market analysis. You can update existing market analysis data and also delete it.',
      gradient: 'bg-gradient-to-r from-red-500 to-orange-600 hover:from-red-600 hover:to-orange-700',
      buttonText: 'Delete or Update MarketView',
    },
  ];

  return (
    <div className="flex flex-col p-6 bg-gradient-to-b from-gray-900 via-gray-800 to-black min-h-screen">
      <div>
    <h1 className='text-left mb-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 tracking-wider drop-shadow-lg'>MarketView Dashboard</h1>
    </div>
      {activeComponent === null ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8 w-full max-w-4xl">
          {buttons.map((button) => (
            <div 
              key={button.id} 
              className="bg-gray-800 text-white p-6 rounded-lg shadow-lg flex flex-col justify-between transform hover:scale-105 transition-transform"
            >
              <div>
                <h2 className="text-lg font-semibold mb-2 text-center">{button.title}</h2>
                <p className="text-sm text-gray-400 mb-4 text-center">{button.description}</p>
              </div>
              <button
                onClick={() => setActiveComponent(button.id)}
                className={`${button.gradient} text-white font-medium py-2 px-4 rounded-full shadow-md transform hover:scale-105 transition-transform`}
                style={{
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15)',
                }}
              >
                {button.buttonText}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full max-w-4xl">
          <button
            className="bg-gradient-to-r from-gray-700 to-gray-600 text-white px-4 py-2 rounded-lg shadow-lg hover:shadow-2xl transform hover:translate-y-1 transition-transform mb-6"
            onClick={() => setActiveComponent(null)}
          >
            <ClearIcon />
            Back
          </button>

          {activeComponent === 'postMarketView' && (
            <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg mb-6">
              <PostMarketView />
            </div>
          )}
          {activeComponent === 'searchArticle' && (
            <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg mb-6">
              <SearchArticleComponent />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AdminMarketView;

