import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import "./PortfolioSearch.css";

const PortfolioSearchBar = ({
  handleSelectResult,
  initialValue,
  autoFocus,
  portfolio,
}) => {
  const [query, setQuery] = useState(initialValue || "");
  const [results, setResults] = useState([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const filterAvailableStocks = (options) => {
    return options.filter(
      (option) =>
        !portfolio.some((entry) => entry.companyName === option["Company Name"])
    );
  };

  const fetchResults = debounce(async (searchQuery) => {
    if (searchQuery.length === 0 || !showDropdown) {
      setResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${SERVER_URL}/search?query=${searchQuery}`,
        {
          withCredentials: true,
        }
      );
      const availableOptions = filterAvailableStocks(response.data);
      setResults(availableOptions);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300);

  useEffect(() => {
    fetchResults(query);
  }, [query]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setQuery(item["Company Name"]);
    setResults([]);
    setShowDropdown(false);
    handleSelectResult(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveResultIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setActiveResultIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter") {
      if (activeResultIndex !== -1) {
        handleSelect(results[activeResultIndex]);
      } else if (results.length > 0) {
        handleSelect(results[0]);
      }
    }
  };

  useEffect(() => {
    setActiveResultIndex(-1);
  }, [query]);

  useEffect(() => {
    if (initialValue) {
      setQuery(initialValue);
      setShowDropdown(false);
    }
  }, [initialValue]);

  return (
    <div className="relative w-full">
      <div className="relative flex items-center">
        <SearchIcon className="absolute left-3 text-gray-500" />
        <input
          ref={inputRef}
          className="w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
          type="text"
          value={query}
          onFocus={() => setShowDropdown(true)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {query === "" && (
          <span className="placeholder-text animate-fade">
            Search for a company...
          </span>
        )}
      </div>
      {query && results.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-y-auto">
          {results.map((item, index) => (
            <li
              key={index}
              className={`flex justify-between p-2 cursor-pointer text-sm ${
                index === activeResultIndex ? "bg-blue-100" : "bg-white"
              } hover:bg-blue-50`}
              onClick={() => handleSelect(item)}
            >
              <span>{item["Company Name"]}</span>
              <span>{item["NSE_Symbol_NS"]}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PortfolioSearchBar;
