import React, { useState } from "react";
import axios from "axios";
import { Modal, Box, Typography, Button } from "@mui/material";

const PostMarketView = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [formData, setFormData] = useState({
    title: "",
    summary: "",
    content: "",
    videoUrl: "",
    imageUrl: "",
  });
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${SERVER_URL}/admin/market-view`, formData);
      setModalMessage("Article posted successfully");
      setOpen(true);
      setFormData({
        title: "",
        summary: "",
        content: "",
        videoUrl: "",
        imageUrl: "",
      });
    } catch (error) {
      console.error("There was an error posting the article!", error);
      setModalMessage("Failed to post article");
      setOpen(true);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <div className="post-article-form max-w-3xl mx-auto mt-10 p-8 bg-gray-900 text-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-6 text-center text-indigo-400">
        Post a New Article
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-300"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>
        <div>
          <label
            htmlFor="summary"
            className="block text-sm font-medium text-gray-300"
          >
            Summary
          </label>
          <input
            type="text"
            id="summary"
            name="summary"
            value={formData.summary}
            onChange={handleChange}
            className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>
        <div>
          <label
            htmlFor="content"
            className="block text-sm font-medium text-gray-300"
          >
            Content
          </label>
          <textarea
            id="content"
            name="content"
            value={formData.content}
            onChange={handleChange}
            className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500 h-40"
            required
          />
        </div>
        <div>
          <label
            htmlFor="videoUrl"
            className="block text-sm font-medium text-gray-300"
          >
            Video URL
          </label>
          <input
            type="text"
            id="videoUrl"
            name="videoUrl"
            value={formData.videoUrl}
            onChange={handleChange}
            className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label
            htmlFor="imageUrl"
            className="block text-sm font-medium text-gray-300"
          >
            Image URL
          </label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={formData.imageUrl}
            onChange={handleChange}
            className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-auto py-2 px-6 bg-gradient-to-r from-green-600 to-green-600 hover:from-purple-500 hover:to-indigo-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-indigo-300 transition-all duration-300 transform hover:scale-105"
          >
            Post Article
          </button>
        </div>
      </form>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Button
            onClick={handleClose}
            sx={{ mt: 2 }}
            variant="contained"
            color={modalMessage.includes("successfully") ? "primary" : "error"}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default PostMarketView;
