import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TermsandCondition.css";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      const cacheKey = "termsAndConditionsData";
      const cacheTimeKey = "termsAndConditionsCacheTime";
      const now = new Date();
      const cachedData = localStorage.getItem(cacheKey);
      const cachedTime = localStorage.getItem(cacheTimeKey);

      if (
        cachedData &&
        cachedTime &&
        now.getTime() - cachedTime < 5 * 60 * 1000
      ) {
        setTermsAndConditions(JSON.parse(cachedData));
      } else {
        try {
          const response = await axios.get(
            `${SERVER_URL}/finsha/termsandconditions`,
            {
              withCredentials: true,
            }
          );
          setTermsAndConditions(response.data);
          // console.log("DATATNC",response.data);
          localStorage.setItem(cacheKey, JSON.stringify(response.data));
          localStorage.setItem(cacheTimeKey, now.getTime().toString());
        } catch (error) {
          console.error("Error fetching terms and conditions:", error);
        }
      }
    };

    fetchTermsAndConditions();
  }, [SERVER_URL]);

  useEffect(() => {
    document.title =
      "Terms and Conditions | Finsha.in - Shariah Compliant Investment";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Explore the detailed terms and conditions for using Finsha.in, your trusted platform for Shariah-compliant investment solutions.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content =
      "Finsha, Shariah Compliant Investment, Islamic Finance, Terms and Conditions, Investment Platform";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement("meta");
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    const structuredDataScript = document.createElement("script");
    structuredDataScript.type = "application/ld+json";
    structuredDataScript.innerHTML = `
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://finsha.in/termsandconditions"
        },
        "headline": "Terms and Conditions",
        "description": "This page outlines the terms and conditions of using Finsha.in, a leading provider of Shariah Compliant investment services. We adhere strictly to Islamic finance principles.",
        "publisher": {
          "@type": "Organization",
          "name": "Finsha.in"
        }
      }
    `;
    document.head.appendChild(structuredDataScript);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(structuredDataScript);
    };
  }, []);

  if (termsAndConditions === null) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="mt-14 font-roboto max-w-[1200px] mx-auto">
      <div
        className="termandcon-container"
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        <h1 className="termsncon" itemProp="headline">
          Terms & Conditions
        </h1>
        <div className="section-container">
          {termsAndConditions.sections.map((section, index) => (
            <div
              key={index}
              className={section.className || `section-${index}`}
              itemScope
              itemType="https://schema.org/Question"
            >
              <h2 className="heading-style" itemProp="name">
                {section.title}
              </h2>
              {section.content?.map((paragraph, pIndex) => (
                <p
                  key={pIndex}
                  className="content-style"
                  itemProp="acceptedAnswer text"
                >
                  {paragraph}
                </p>
              ))}
              {section.subsections?.map((subsection, subIndex) => (
                <div
                  key={subIndex}
                  itemScope
                  itemType="https://schema.org/Question"
                >
                  <h3 className="subheading-style" itemProp="name">
                    {subsection.title}
                  </h3>
                  {subsection.content?.map((paragraph, pIndex) => (
                    <p
                      key={pIndex}
                      className="subcontent-style"
                      itemProp="acceptedAnswer text"
                    >
                      {paragraph}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Helmet } from "react-helmet";
// import "./TermsandCondition.css";

// const TermsAndConditions = () => {
//   const [termsAndConditions, setTermsAndConditions] = useState(null);
//   const SERVER_URL = process.env.REACT_APP_BACKEND_LOCAL_URL

//   useEffect(() => {
//     const fetchData = async () => {
//       const cacheKey = "termsAndConditionsData";
//       const cacheTimeKey = "termsAndConditionsCacheTime";
//       const now = new Date();
//       const cachedData = localStorage.getItem(cacheKey);
//       const cachedTime = localStorage.getItem(cacheTimeKey);

//       // Check if cached data is present and fresh (less than 5 minutes old)
//       if (
//         cachedData &&
//         cachedTime &&
//         now.getTime() - cachedTime < 5 * 60 * 1000
//       ) {
//         setTermsAndConditions(JSON.parse(cachedData));
//       } else {
//         try {
//           const response = await axios.get(
//             `${SERVER_URL}/finsha/termsandconditions`,
//             {
//               withCredentials: true,
//             }
//           );
//           setTermsAndConditions(response.data);
//           // Update cache with new data
//           localStorage.setItem(cacheKey, JSON.stringify(response.data));
//           localStorage.setItem(cacheTimeKey, now.getTime().toString());
//         } catch (error) {
//           console.error("Error fetching terms and conditions:", error);
//         }
//       }
//     };

//     fetchData();
//   }, []);

//   // useEffect(() => {
//   //   axios.get(`${SERVER_URL}/finsha/termsandconditions`)
//   //     .then(response => {
//   //       setTermsAndConditions(response.data);
//   //     })
//   //     .catch(error => {
//   //       // console.log(error);
//   //     });
//   // }, []);

//   if (termsAndConditions === null) {
//     return "Loading...";
//   }

//   return (
//     <div className="-mt-[15px]">
//       <Helmet>
//         <title>
//           Terms and Conditions | Finsha.in - Shariah Compliant Investment
//         </title>
//         <meta
//           name="description"
//           content="Learn about the terms and conditions of using Finsha.in, a leading provider of Shariah Compliant investment services. We adhere strictly to Islamic finance principles."
//         />
//         <script type="application/ld+json">
//           {`
//           {
//             "@context": "https://schema.org",
//             "@type": "Article",
//             "mainEntityOfPage": {
//               "@type": "WebPage",
//               "@id": "https://finsha.in/termsandconditions"
//             },
//             "headline": "Terms and Conditions",
//             "description": "This page outlines the terms and conditions of using Finsha.in, a leading provider of Shariah Compliant investment services. We adhere strictly to Islamic finance principles.",
//             "publisher": {
//               "@type": "Organization",
//               "name": "Finsha.in"
//             }
//           }
//           `}
//         </script>
//       </Helmet>

//       <div
//         className="termandcon-container"
//         itemScope
//         itemType="https://schema.org/FAQPage"
//       >
//         <h1 className="termsncon text-3xl" itemProp="headline">
//           Terms & Conditions{" "}
//         </h1>
//         {termsAndConditions.sections.map((section, index) => (
//           <div key={index} itemScope itemType="https://schema.org/Question">
//             <h2 className="heading-style" itemProp="name">
//               {section.title}
//             </h2>
//             {section.content &&
//               section.content.map((paragraph, pIndex) => (
//                 <p
//                   key={pIndex}
//                   className="content-style"
//                   itemProp="acceptedAnswer text"
//                 >
//                   {paragraph}
//                 </p>
//               ))}
//             {section.subsections &&
//               section.subsections.map((subsection, subIndex) => (
//                 <div
//                   key={subIndex}
//                   itemScope
//                   itemType="https://schema.org/Question"
//                 >
//                   <h3 className="subheading-style" itemProp="name">
//                     {subsection.title}
//                   </h3>
//                   {subsection.content &&
//                     subsection.content.map((paragraph, pIndex) => (
//                       <p
//                         key={pIndex}
//                         className="subcontent-style"
//                         itemProp="acceptedAnswer text"
//                       >
//                         {paragraph}
//                       </p>
//                     ))}
//                 </div>
//               ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TermsAndConditions;
