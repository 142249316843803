import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import Skeleton from "@mui/material/Skeleton";
import UserAvatar from "./UserAvatar";
import {
  FaHome,
  FaSearch,
  FaFileAlt,
  FaMoneyBillWave,
  FaBriefcase,
  FaLightbulb,
  FaEye,
  FaUser,
} from "react-icons/fa";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "tailwindcss/tailwind.css";
import "./NavLink.css";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const history = useHistory();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLogoutSuccess, setIsLogoutSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const dashboardDropdownRef = useRef(null);
  const avatarButtonRef = useRef(null);
  const avatarContainerSize = isMobileView ? 60 : 50;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dashboardDropdownRef.current &&
        !dashboardDropdownRef.current.contains(event.target) &&
        avatarButtonRef.current &&
        !avatarButtonRef.current.contains(event.target)
      ) {
        setIsOpenUser(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Simulate loading data
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  // const handleLogout = () => {
  //   auth.logout();
  //   handleLinkClick();
  //   history.push("/auth");
  //   setIsOpenUser(false);
  // };

  const handleLinkClick = () => {
    if (props.closeDrawer) {
      props.closeDrawer();
    }
  };

  const handleOpenLogoutModal = () => setIsLogoutModalOpen(true);
  const handleCloseLogoutModal = () => setIsLogoutModalOpen(false);

  const handleConfirmLogout = () => {
    auth.logout();
    setIsLogoutSuccess(true);
    setIsLogoutModalOpen(false);
    setTimeout(() => {
      setIsLogoutSuccess(false);
      history.push("/auth");
    }, 3000); // Show success message for 3 seconds
  };

  if (auth.isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width={210}
        height={50}
        style={{ marginLeft: "20px" }}
      />
    );
  }

  const avatarStyle = isMobileView
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "200px",
        height: "100px",
        transition: "all 0.3s ease-in-out",
      }
    : {};

  return (
    <div className="flex items-center justify-between space-x-4 lg:space-x-8">
      <ul className="flex items-center -space-x-2 nav-links_unordered__list">
        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          <li className="report-ss">
            <NavLink
              to="/"
              exact
              onClick={handleLinkClick}
              className="active:text-green-700 text-lg snake-animation"
              activeClassName="active-link"
            >
              <FaHome className="inline-block mr-2" /> Home
            </NavLink>
          </li>
        )}

        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          <li className="report-ss">
            <NavLink
              to="/screener"
              onClick={handleLinkClick}
              className=" text-lg active:text-green-700 snake-animation"
              activeClassName="active-link"
            >
              <FaSearch className="inline-block mr-2" /> Screener
            </NavLink>
          </li>
        )}

        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          <li className="report-ss">
            <NavLink
              to="/finview"
              onClick={handleLinkClick}
              className="snake-animation text-lg active:text-green-700"
              activeClassName="active-link"
            >
              <FaFileAlt className="inline-block mr-2" /> FinView
            </NavLink>
          </li>
        )}

        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={140}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          <li className="report-ss">
            <NavLink
              to="/pricing"
              onClick={handleLinkClick}
              className="snake-animation text-lg active:text-green-700"
              activeClassName="active-link"
            >
              <FaMoneyBillWave className="inline-block mr-2" /> Pricing
            </NavLink>
          </li>
        )}

        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          !auth.isLoggedIn && (
            <li className="report-ss">
              <NavLink
                to="/auth"
                onClick={handleLinkClick}
                className="snake-animation text-lg active:text-green-700"
                activeClassName="active-link"
              >
                <LockOpenIcon className="inline-block mr-2" /> Login
              </NavLink>
            </li>
          )
        )}

        {loading || auth.isLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={40}
            style={{ marginLeft: "20px" }}
          />
        ) : (
          auth.isLoggedIn && (
            <li className="report-ss">
              <NavLink
                to="/stock-idea"
                onClick={handleLinkClick}
                className="snake-animation text-lg active:text-green-700"
                activeClassName="active-link"
              >
                <FaLightbulb className="inline-block mr-2" /> StockIdea
              </NavLink>
            </li>
          )
        )}

        {auth.isLoggedIn && !isMobileView && (
          <li className="report-ss" ref={dashboardDropdownRef}>
            <div className="avatar-container" ref={avatarButtonRef}>
              <UserAvatar size={avatarContainerSize} style={avatarStyle} />
              <div className="dropdown-menu z-50">
                <NavLink
                  to="/user-portfolio"
                  activeClassName="active-link"
                  className="dropdown-item snake-animation"
                  onClick={() => setIsOpenUser(false)}
                >
                  <FaBriefcase className="icon" /> Portfolio
                </NavLink>
                <NavLink
                  to="/watchlist"
                  activeClassName="active-link"
                  className="dropdown-item snake-animation"
                  onClick={() => setIsOpenUser(false)}
                >
                  <FaEye className="icon" /> Watchlist
                </NavLink>
                <NavLink
                  to="/user-profile"
                  activeClassName="active-link"
                  className="dropdown-item snake-animation"
                  onClick={() => setIsOpenUser(false)}
                >
                  <FaUser className="icon" /> Account
                </NavLink>

                <NavLink
                  to="#"
                  activeClassName="active-link"
                  className=" dropdown-item snake-animation logout-btna"
                  onClick={handleOpenLogoutModal}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="icon" />{" "}
                  Logout
                </NavLink>
              </div>
            </div>
          </li>
        )}
      </ul>
      <Modal
        open={isLogoutModalOpen}
        onClose={handleCloseLogoutModal}
        aria-labelledby="logout-confirmation-title"
        aria-describedby="logout-confirmation-description"
        className="bg-opacity-50"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <Box
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 bg-slate-700 text-slate-400  rounded-lg"
          style={{ width: "20rem", height: "8rem" }} // 40rem for width and 16rem for height
        >
          <Typography
            id="logout-confirmation-title"
            variant="h6"
            component="h2"
            className="text-white font-bold mt-1"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Confirm Logout
          </Typography>

          <Typography
            id="logout-confirmation-description"
            className=" text-white p-2"
            style={{ textAlign: "center", fontSize: "14px" }}
          >
            Do you wish to log out?
          </Typography>
          <div
            className="flex mt-2 mr-5 ml-5 mb-0"
            style={{ justifyContent: "space-around" }}
          >
            <button
              onClick={handleConfirmLogout}
              className="px-3 py-1 text-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 text-sm rounded-lg"
            >
              Confirm
            </button>

            <button
              onClick={handleCloseLogoutModal}
              className="px-3 py-1 border bg-gradient-to-r from-green-400 to-green-600 text-white hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 text-sm rounded-lg"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isLogoutSuccess}
        onClose={() => setIsLogoutSuccess(false)}
        aria-labelledby="logout-success-title"
        aria-describedby="logout-success-description"
        className="bg-opacity-50"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-70 h-39 p-4  bg-slate-700 text-slate-400  rounded-lg flex flex-col items-center justify-center">
          <CheckCircleIcon style={{ fontSize: 50, color: "green" }} />
          <Typography
            id="logout-success-description"
            className="mt-2 text-white text-center p-3"
            style={{ fontSize: "14px" }}
          >
            You have been logged out successfully.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default NavLinks;
