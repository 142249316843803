import React, { useState, useEffect, useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import "./MarketView.css";
import { AuthContext } from "../../../Shared/context/auth-context";
import { Skeleton } from "@mui/material";
import { FaBoxOpen, FaClock, FaInfoCircle } from "react-icons/fa";
import axios from "axios";

function MarketView() {
  const { user } = useContext(AuthContext); // Use AuthContext to get user information
  const [articles, setArticles] = useState([]); // State to store articles
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const [fetchTime, setFetchTime] = useState(new Date()); // State to store fetch time
  const SERVER_URL = process.env.REACT_APP_SERVER_URL; // Server URL from environment variables

  const { url } = useRouteMatch(); // Get the current match URL

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []); // Empty dependency array to run effect only once

  // useEffect(() => {
  //   setIsLoading(true); // Start loading

  //   fetch(`${SERVER_URL}/finview/articles`, {
  //     credentials: "include", // Include credentials with the request
  //   })
  //     .then((response) => response.json()) // Parse JSON response
  //     .then((data) => {
  //       const sortedArticles = data.sort(
  //         (a, b) => new Date(b.date) - new Date(a.date) // Sort articles by date, newest first
  //       );
  //       const now = new Date(); // Get current date and time
  //       setFetchTime(now); // Set fetch time
  //       setArticles(
  //         sortedArticles.map((article) => ({
  //           ...article,
  //           isNew: now - new Date(article.date) <= 2 * 60 * 1000, // 2 minutes
  //         }))
  //       );
  //       setIsLoading(false); // Stop loading after data is set
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error); // Log error
  //       setIsLoading(false); // Stop loading on error
  //     });
  // }, []); // Empty dependency array to run effect only once after initial render

  // replace fetch with axios
  useEffect(() => {
    setIsLoading(true); // Start loading

    axios
      .get(`${SERVER_URL}/finview/articles`, {
        withCredentials: true, // Include credentials with the request
      })
      .then((response) => {
        const sortedArticles = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date) // Sort articles by date, newest first
        );
        const now = new Date(); // Get current date and time
        setFetchTime(now); // Set fetch time
        setArticles(
          sortedArticles.map((article) => ({
            ...article,
            isNew: now - new Date(article.date) <= 2 * 60 * 1000, // 2 minutes
          }))
        );
        setIsLoading(false); // Stop loading after data is set
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Log error
        setIsLoading(false); // Stop loading on error
      });
  }, []); // Empty dependency array to run effect only once after initial render

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date(); // Get current date and time
      setArticles(
        articles.map((article) => ({
          ...article,
          isNew: now - new Date(article.date) <= 7 * 24 * 60 * 60 * 1000, // 7 days
        }))
      );
    }, 1000); // Update every second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [articles]); // Dependency array with articles to run effect when articles change

  const truncateSummary = (summary, wordLimit) => {
    return summary.split(" ").slice(0, wordLimit).join(" ") + "..."; // Truncate summary to word limit
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert date string to Date object
    if (isNaN(date)) {
      return "Invalid Date"; // Return invalid date if date conversion fails
    }
    const formattedDate = date.toLocaleDateString("en-GB"); // Format date
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }); // Format time
    return `${formattedDate} ${formattedTime}`; // Return formatted date and time
  };

  function convertToNoCookieURL(url) {
    return url.replace("www.youtube.com", "www.youtube-nocookie.com"); // Convert YouTube URL to no-cookie version
  }

  function formatTitleForURL(title) {
    return title.replace(/\s+/g, "-"); // Replace spaces with dashes for URL
  }

  return (
    <div className="videos-blogs-container">
      <div className="scrollable-items-container">
        {isLoading ? ( // If loading, display skeleton placeholders
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="article-wrapper">
              <Skeleton height={300} width={`100%`} />
              <Skeleton variant="text" height={20} width={`100%`} />
              <Skeleton variant="text" height={20} width={`100%`} />
              <Skeleton variant="text" height={20} width={`50%`} />
              <Skeleton
                variant="rectangular"
                height={40}
                width={`30%`}
                style={{ borderRadius: "10px" }}
              />
              <div
                style={{ position: "absolute", bottom: "10px", right: "10px" }}
              >
                <Skeleton variant="text" height={20} width={200} />
              </div>
            </div>
          ))
        ) : articles.length > 0 ? ( // If articles are available, display them
          articles.map((item) => (
            <div key={item._id} className="article-wrapper">
              <div className={`article-container`}>
                {item.isNew && (
                  <div className="new-ribbon">
                    <span>New</span> {/* Display new ribbon for new articles */}
                  </div>
                )}
                {item.videoUrl ? (
                  <iframe
                    src={convertToNoCookieURL(item.videoUrl)}
                    allowFullScreen
                    title={`Video for article ${item.title}`}
                    className="custom-article-video"
                  />
                ) : item.imageUrl ? (
                  <img
                    src={item.imageUrl}
                    alt={`Image for article ${item.title}`}
                    className="w-full h-40 rounded-lg shadow-lg border border-gray-300"
                  />
                ) : null}
                <Link
                  to={`/market/${formatTitleForURL(item.title)}`}
                  className="article-link"
                >
                  <h4 className="article_title_text">{item.title}</h4>
                  <p className="article_content_text">
                    {truncateSummary(item.summary, 30)}
                  </p>
                  <p className="ReadMore_text">Read More....</p>
                  <p className="date_of_article_marketView">
                    {formatDate(item.date)}
                  </p>
                </Link>
              </div>
            </div>
          ))
        ) : (
          // If no articles, display no articles message
          <div className="no-articles-container">
            <div className="no-articles-message">
              <FaInfoCircle className="no-articles-info-icon" />
              <p>
                We are currently working on Market View updates. As team is
                working hard to bring you the latest and most accurate
                information.
              </p>
              <p>Please check back later for updates.</p>
              <p>
                In the meantime, you can explore other sections or follow us on
                social media for the latest news.
              </p>
            </div>
            <div className="no-articles-icon">
              <FaBoxOpen className="no-articles-box-icon" />
            </div>
            <div className="no-articles-suggestion">
              <FaClock className="no-articles-clock-icon" />
              <p>We update our reports regularly. Stay tuned!</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MarketView; // Export MarketView component
