import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const SearchAndUpdateIpo = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIpo, setSelectedIpo] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [open, setOpen] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm) {
        try {
          const response = await axios.get(
            `${SERVER_URL}/admin/search-ipo-name`,
            { params: { companyName: searchTerm } }
          );
          setSuggestions(response.data);
        } catch (error) {
          console.error("Failed to fetch suggestions", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedIpo(null); // Reset the selected IPO when typing
  };

  const handleSuggestionClick = (ipo) => {
    setSelectedIpo(ipo);
    setSearchTerm(ipo.companyName);
    setUpdatedData({
      ...ipo,
      businessModel: ipo.businessModel || {
        businessModel1: "",
        businessModel2: "",
        businessModel3: "",
      }, // Initialize businessModel if it's undefined
    });
    setSuggestions([]);
  };

  const handleOpen = (message) => {
    setModalMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    const [field, subField] = name.split("."); // Split the name to handle nested fields

    if (subField) {
      setUpdatedData({
        ...updatedData,
        [field]: {
          ...updatedData[field],
          [subField]: value,
        },
      });
    } else {
      setUpdatedData({
        ...updatedData,
        [name]: value,
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!selectedIpo) {
      handleOpen("Please select an IPO to update.");
      return;
    }
    try {
      await axios.put(
        `${SERVER_URL}/admin/ipo/${selectedIpo._id}`,
        updatedData
      );
      handleOpen("IPO updated successfully.");

      // Clear the form and reset the search bar
      setSearchTerm("");
      setSelectedIpo(null);
      setUpdatedData({});
    } catch (error) {
      console.error("Failed to update IPO", error);
      handleOpen("Failed to update IPO.");
    }
  };

  const handleDelete = async () => {
    if (!selectedIpo) {
      handleOpen("Please select an IPO to delete.");
      return;
    }
    try {
      await axios.delete(`${SERVER_URL}/admin/ipo/${selectedIpo._id}`);
      handleOpen("IPO deleted successfully.");

      // Clear the form and reset the search bar
      setSearchTerm("");
      setSelectedIpo(null);
      setUpdatedData({});
    } catch (error) {
      console.error("Failed to delete IPO", error);
      handleOpen("Failed to delete IPO.");
    }
  };

  return (
    <div className="p-6 max-w-full mx-auto bg-gray-900 text-white">
      <h2 className="text-2xl font-semibold mb-6">Search and Update IPO</h2>

      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-2 rounded bg-gray-700 text-white"
          placeholder="Search by company name"
        />
        {suggestions.length > 0 && (
          <ul className="absolute bg-gray-700 border border-gray-600 w-full mt-1 rounded z-10">
            {suggestions.map((ipo) => (
              <li
                key={ipo._id}
                onClick={() => handleSuggestionClick(ipo)}
                className="p-2 hover:bg-gray-600 cursor-pointer"
              >
                {ipo.companyName}
              </li>
            ))}
          </ul>
        )}
      </div>

      {selectedIpo && (
        <form
          onSubmit={handleUpdate}
          className="grid grid-cols-1 md:grid-cols-2 gap-4"
        >
          <div>
            <label className="block text-lg">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={updatedData.companyName || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Symbol</label>
            <input
              type="text"
              name="symbol"
              value={updatedData.symbol || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Opening Date</label>
            <input
              type="date"
              name="openingDate"
              value={
                updatedData.openingDate
                  ? new Date(updatedData.openingDate)
                      .toISOString()
                      .substring(0, 10)
                  : ""
              }
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Closing Date</label>
            <input
              type="date"
              name="closeDate"
              value={
                updatedData.closeDate
                  ? new Date(updatedData.closeDate)
                      .toISOString()
                      .substring(0, 10)
                  : ""
              }
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Listing Date</label>
            <input
              type="date"
              name="listingDate"
              value={
                updatedData.listingDate
                  ? new Date(updatedData.listingDate)
                      .toISOString()
                      .substring(0, 10)
                  : ""
              }
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Issue Size</label>
            <input
              type="text"
              name="issueSize"
              value={updatedData.issueSize || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Lot Size</label>
            <input
              type="number"
              name="lotSize"
              value={updatedData.lotSize || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Minimum Retail Investment</label>
            <input
              type="number"
              name="minimumRetailInvestment"
              value={updatedData.minimumRetailInvestment || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Issue Price Max</label>
            <input
              type="number"
              name="issuePriceMax"
              value={updatedData.issuePriceMax || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Exchange</label>
            <select
              name="exchange"
              value={updatedData.exchange || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            >
              <option value="NSE">NSE</option>
              <option value="BSE">BSE</option>
              <option value="NSE&BSE">NSE&BSE</option>
              <option value="SME-NSE">SME-NSE</option>
              <option value="SME-BSE">SME-BSE</option>
              <option value="SME-NSE&BSE">SME-NSE&BSE</option>
            </select>
          </div>
          <div>
            <label className="block text-lg">Price</label>
            <input
              type="text"
              name="price"
              value={updatedData.price || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Expected Listing Profit</label>
            <input
              type="text"
              name="expectedListingProfit"
              value={updatedData.expectedListingProfit || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Industry</label>
            <input
              type="text"
              name="industry"
              value={updatedData.industry || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Rating</label>
            <input
              type="number"
              name="rating"
              value={updatedData.rating || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Business Model 1</label>
            <input
              type="text"
              name="businessModel.businessModel1"
              value={updatedData.businessModel?.businessModel1 || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Business Model 2</label>
            <input
              type="text"
              name="businessModel.businessModel2"
              value={updatedData.businessModel?.businessModel2 || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Business Model 3</label>
            <input
              type="text"
              name="businessModel.businessModel3"
              value={updatedData.businessModel?.businessModel3 || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Investment Prospective</label>
            <input
              type="text"
              name="investmentProspective"
              value={updatedData.investmentProspective || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Shariah Status</label>
            <select
              name="shariahStatus"
              value={updatedData.shariahStatus || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            >
              <option value="Compliant">Compliant</option>
              <option value="Non-Compliant">Non-Compliant</option>
            </select>
          </div>
          <div>
            <label className="block text-lg">
              Business Permissibility Status
            </label>
            <select
              name="shariahCompliance.businessPermissibilityStatus"
              value={
                updatedData.shariahCompliance?.businessPermissibilityStatus ||
                ""
              }
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div>
            <label className="block text-lg">Interest to Revenue</label>
            <input
              type="number"
              name="shariahCompliance.interestToRevenue"
              value={updatedData.shariahCompliance?.interestToRevenue || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Loan to Market Cap</label>
            <input
              type="number"
              name="shariahCompliance.loanToMarketCap"
              value={updatedData.shariahCompliance?.loanToMarketCap || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div>
            <label className="block text-lg">Investment to Market Cap</label>
            <input
              type="number"
              name="shariahCompliance.investmentToMarketCap"
              value={updatedData.shariahCompliance?.investmentToMarketCap || ""}
              onChange={handleUpdateChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
            />
          </div>
          <div className="col-span-2 flex justify-end space-x-4">
            <button
              type="submit"
              className="bg-green-500 py-2 px-4 rounded hover:bg-green-600 transition"
            >
              Update IPO
            </button>
            <button
              type="button"
              className="bg-red-500 py-2 px-4 rounded hover:bg-red-600 transition"
              onClick={handleDelete}
            >
              Delete IPO
            </button>
          </div>
        </form>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-sm mx-auto mt-24 text-center">
          <h2 id="modal-title" className="text-2xl mb-4 font-semibold">
            Message
          </h2>
          <p id="modal-description" className="mb-4">
            {modalMessage}
          </p>
          <button
            onClick={handleClose}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full transition"
          >
            Close
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchAndUpdateIpo;
