import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./Disclaimer.css";

const Disclaimer = () => {
  const [data, setData] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // Cache key names
      const dataKey = "disclaimerData";
      const cacheTimeKey = "disclaimerCacheTime";
      // Attempt to retrieve the cached data
      const cachedData = localStorage.getItem(dataKey);
      const cachedTime = localStorage.getItem(cacheTimeKey);
      const now = new Date();

      // Check if cached data exists and is less than 5 minutes old
      if (
        cachedData &&
        cachedTime &&
        now.getTime() - cachedTime < 5 * 60 * 1000
      ) {
        setData(JSON.parse(cachedData));
      } else {
        try {
          const response = await axios.get(`${SERVER_URL}/finsha/disclaimer`, {
            withCredentials: true,
          });
          setData(response.data);
          // Cache the fetched data
          localStorage.setItem(dataKey, JSON.stringify(response.data));
          localStorage.setItem(cacheTimeKey, now.getTime().toString());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-[90px] font-roboto fade-in">
      <Helmet>
        <title>Disclaimer</title>
        <meta
          name="description"
          content="Learn about the disclaimer for Finsha's shariah-Compliant investment and other services."
        />
        <link rel="canonical" href="https://finsha.in/disclaimer" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Disclaimer - Finsha",
              "url": "https://finsha.in/disclaimer",
              "description": "Learn about the disclaimer for Finsha's shariah-Compliant investment and other services.",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://finsha.in/disclaimer"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="disclaimer-container max-w-[1200px] mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h1 className="disclaimerpage__title text-4xl font-bold text-center text-gray-800 mb-10 slide-in-left">
          Our Disclaimer
        </h1>
        {data && (
          <>
            <h2 className="text-2xl font-semibold text-blue-700 mb-6 slide-in-right">
              {data.title}
            </h2>
            {data.content.map((item, index) => (
              <div
                key={index}
                className={`disclaimer-section mb-8 fade-in-${
                  index % 2 === 0 ? "left" : "right"
                }`}
              >
                <p className="text-gray-700 leading-relaxed">
                  {item.paragraph}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Disclaimer;
