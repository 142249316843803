import React, { useState, useEffect } from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./indexchart.css"; // Ensure this file is updated with the new CSS
import { Skeleton } from "@mui/material";

const IndexChart = () => {
  const [indexData, setIndexData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/screener/index-data`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setIndexData(data);
        } else {
          setError("Invalid data received");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch data");
        console.error("Error:", err);
        setLoading(false);
      });

    // Optional: Implement automatic scrolling
    const scrollContainer = document.querySelector(".indexchart-container");
    let currentPosition = 0;
    const scrollTicker = () => {
      if (scrollContainer) {
        currentPosition += 1; // Adjust scroll speed if needed
        scrollContainer.scrollLeft = currentPosition;
      }
    };
    const interval = setInterval(scrollTicker, 20); // Adjust timing as needed
    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  if (loading) {
    return (
      <div className="indexchart-container">
        <div className="ticker-wrap">
          <div
            className="ticker"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* Display skeletons in a row */}
            {[...Array(5)].map((_, idx) => (
              <Skeleton
                key={idx}
                variant="rectangular"
                width={150}
                height={20}
                style={{ marginRight: "10px", borderRadius: "4px" }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error loading index data.</div>;
  }

  return (
    <div className="indexchart-container">
      <div className="ticker-wrap">
        <div className="ticker">
          {indexData.map((item, idx) => (
            <div key={idx} className="index-item">
              <span className="item-name">{item.name}</span>
              <span className="item-value">₹ {item.price}</span>
              <span
                className={`item-change ${
                  item.changesPercentage < 0 ? "negative" : "positive"
                }`}
              >
                {item.changesPercentage < 0 ? (
                  <ArrowDropDownIcon className="icon negative" />
                ) : (
                  <ArrowDropUpIcon className="icon positive" />
                )}
                {item.changesPercentage
                  ? item.changesPercentage.toFixed(2)
                  : "N/A"}
                %
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndexChart;
