import React from 'react';
import Skeleton from '@mui/material/Skeleton';


function Portfolioskelton() {
  const columns = ["Sl No.", "Stock name", "Status", "Current price", "Buy-Price", "Quantity", "Profit/Loss", "Purgable Income", "Target Price (%)", "Stop Loss (%)", "Actions"];


  return (
    <div className="portfolio-container flex flex-col items-center">
      <Skeleton variant="text" width="40%" height={100} className="Portfolio-title-skeleton" />

      <div className="allportfolio__container w-full flex flex-col items-center">
      <Skeleton variant="text" width="30%" height={40} className="allportfolio-title-skeleton mb-4 self-start" />

        <div className='Allportfolio_notexpand_div w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          <div className='allportfolio-data-group'>
            <Skeleton variant="text" width="80%" height={30} className="mb-2" />
            <Skeleton variant="text" width="60%" height={30} />
          </div>

          <div className='allportfolio-data-group'>
            <Skeleton variant="text" width="80%" height={30} className="mb-2" />
            <Skeleton variant="text" width="60%" height={30} />
          </div>

          <div className='allportfolio-data-group'>
            <Skeleton variant="text" width="80%" height={30} className="mb-2" />
            <Skeleton variant="text" width="60%" height={30} />
          </div>

          <div className='allportfolio-data-group'>
            <Skeleton variant="text" width="80%" height={30} className="mb-2" />
            <Skeleton variant="text" width="60%" height={30} />
          </div>
        </div>

        <div className='w-full flex justify-end mt-4'>
          <Skeleton variant="rectangular" width={120} height={40} />
        </div>
      </div>

      <div className="flex justify-start items-center gap-4 p-4 rounded-lg bg-gray-50 shadow-md self-start">
  <Skeleton variant="rectangular" width={120} height={40} className="rounded-full" />
  <Skeleton variant="rectangular" width={120} height={40} className="rounded-full" />
</div>

<div className="holding_portfolio p-4 bg-gray-100 rounded-lg shadow-md">
      <Skeleton variant="text" width="30%" height={40} className="mb-4" />

      <div className="skeleton-table">
        {/* Table Header */}
        <div className="skeleton-table-header flex justify-between mb-2">
          {columns.map((header, index) => (
            <Skeleton key={index} variant="text" width={100} height={30} />
          ))}
        </div>
        
        {/* Table Rows */}
        {[...Array(5)].map((_, rowIndex) => (
          <div key={rowIndex} className="skeleton-table-row flex justify-between mb-2">
            {columns.map((_, colIndex) => (
              <Skeleton key={colIndex} variant="text" width={100} height={30} />
            ))}
          </div>
        ))}
      </div>

      {/* Add New Stock Button */}
      <Skeleton variant="rectangular" width={150} height={40} className="mt-4" />
    </div>

    </div>
  );
}

export default Portfolioskelton;
