import React, { useState, useEffect } from "react";
import axios from "axios";
import StockSearch from "../component/StockSearch";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AddStockIdeaForm = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [formData, setFormData] = useState({
    Visibility: "QuickWin Starter",
    "Company Name": "",
    Symbol: "",
    Sector: "",
    recommendedPercentage: 0,
    "Target Price": 0,
    "Stop Loss": 0,
    "Holding Duration": "",
    Message: "",
    "Shariah Status": "Compliant",
    Recommendation: "Buy",
    "Buy Option": "",
    portfolioStrategy: "BTSM",
    IdeaReportLink: "",
  });

  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [existingIdeas, setExistingIdeas] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchExistingIdeas = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/finsha/stock-ideas`);
        setExistingIdeas(response.data);
      } catch (error) {
        console.error("Failed to fetch existing stock ideas:", error);
      }
    };

    fetchExistingIdeas();
  }, [SERVER_URL]);

  const handleSelectResult = (selectedCompany) => {
    setFormData({
      ...formData,
      "Company Name": selectedCompany["Company Name"],
      Symbol: selectedCompany["NSE_Symbol_NS"] || "",
      Sector: selectedCompany["Business Sector"] || "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Check if the company with the same name and visibility already exists
    const isDuplicate = existingIdeas.some(
      (idea) =>
        idea["Company Name"] === formData["Company Name"] &&
        idea.Visibility === formData.Visibility
    );

    if (isDuplicate) {
      setResponseMessage("This company already exists.");
      setOpen(true); // Show error modal
      setLoading(false); // End loading
      return;
    }

    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/add-stock-idea`,
        formData
      );
      setResponseMessage(`Stock idea posted successfully`);
      setOpen(true); // Show success modal
      setFormData({
        // Clear form fields
        Visibility: "QuickWin Starter",
        "Company Name": "",
        Symbol: "",
        Sector: "",
        recommendedPercentage: 0,
        "Target Price": 0,
        "Stop Loss": 0,
        "Holding Duration": "",
        Message: "",
        "Shariah Status": "Compliant",
        Recommendation: "Buy",
        "Buy Option": "",
        portfolioStrategy: "BTSM",
        IdeaReportLink: "",
      });
    } catch (error) {
      setResponseMessage("Failed to post stock idea");
      setOpen(true); // Show error modal
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="p-2 font-bold">
        <h1 className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
          Add New Stock
        </h1>
      </div>

      <form
        onSubmit={handleSubmit}
        className="max-w-4xl mx-auto p-4 shadow-lg rounded"
      >
        <div className="flex flex-wrap -mx-2 mb-4">
          {/* Visibility Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Visibility
            </label>
            <select
              name="Visibility"
              value={formData.Visibility}
              onChange={handleChange}
              className="w-full p-2 border rounded text-black"
              required
            >
              <option value="QuickWin Starter">QuickWin Starter</option>
              <option value="SmartHold Starter">SmartHold Starter</option>
              <option value="SmartElite Starter">SmartElite Starter</option>
            </select>
          </div>

          {/* Company Name Field */}
          <div className="w-full md:w-1/2 px-2 mb-4 relative">
            <label className="block text-white text-sm font-bold mb-2">
              Company Name
            </label>
            <StockSearch
              handleSelectResult={handleSelectResult}
              initialValue={formData["Company Name"]}
              className="text-black"
            />
          </div>

          {/* Symbol Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Symbol
            </label>
            <input
              type="text"
              name="Symbol"
              value={formData.Symbol}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Symbol"
              required
            />
          </div>

          {/* Sector Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Sector
            </label>
            <input
              type="text"
              name="Sector"
              value={formData.Sector}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Sector"
              required
            />
          </div>

          {/* Recommended Percentage Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Recommended Percentage
            </label>
            <input
              type="number"
              name="recommendedPercentage"
              value={formData.recommendedPercentage}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              min="0"
              max="100" // Ensures the value cannot exceed 100
              step="0.01" // Allows for decimal values
              required
            />
          </div>

          {/* Target Price Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Target Price
            </label>
            <input
              type="number"
              name="Target Price"
              value={formData["Target Price"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Stop Loss Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Stop Loss
            </label>
            <input
              type="number"
              name="Stop Loss"
              value={formData["Stop Loss"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Holding Duration Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Holding Duration
            </label>
            <input
              type="text"
              name="Holding Duration"
              value={formData["Holding Duration"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Duration of Holding"
              required
            />
          </div>

          {/* Message Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Message
            </label>
            <textarea
              name="Message"
              value={formData["Message"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Message about the Stock"
              required
            ></textarea>
          </div>

          {/* Shariah Status Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Shariah Status
            </label>
            <select
              name="Shariah Status"
              value={formData["Shariah Status"]}
              onChange={handleChange}
              className="w-full p-2 border rounded text-black"
            >
              <option value="Compliant">Compliant</option>
              <option value="Non-Compliant">Non-Compliant</option>
            </select>
          </div>

          {/* Recommendation Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Recommendation
            </label>
            <select
              name="Recommendation"
              value={formData["Recommendation"]}
              onChange={handleChange}
              className="w-full p-2 border rounded text-black"
            >
              <option value="Buy">Buy</option>
              <option value="Sell">Sell</option>
              <option value="Hold">Hold</option>
            </select>
          </div>

          {/* Buy Option Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Buy Range
            </label>
            <input
              type="text"
              name="Buy Option"
              value={formData["Buy Option"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="e.g. 300-400"
              required
            />
          </div>

          {/* Portfolio Strategy Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Portfolio Strategy
            </label>
            <select
              name="portfolioStrategy"
              value={formData.portfolioStrategy}
              onChange={handleChange}
              className="w-full p-2 border rounded text-black"
            >
              <option value="BTSM">BTSM</option>
              <option value="BTSF">BTSF</option>
              <option value="BTSY">BTSY</option>
              <option value="BTSFY">BTSFY</option>
            </select>
          </div>

          {/* Idea Report Link Field */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Report Link
            </label>
            <input
              type="text"
              name="IdeaReportLink"
              value={formData["IdeaReportLink"]}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="E.g. https://www.princexml.com/samples/invoice/invoicesample.pdf"
              required
            />
          </div>
        </div>

        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-gradient-to-r from-green-400 to-teal-500 hover:from-green-500 hover:to-teal-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transform hover:scale-105 transition-transform focus:outline-none focus:shadow-outline"
            disabled={loading}
            style={{
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Post Stock Idea"
            )}
          </button>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "#1a1a1a",
              color: "#ffffff",
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              {responseMessage.includes("Failed") ||
              responseMessage.includes("already exists") ? (
                <ErrorOutlineIcon sx={{ fontSize: 40, color: "#e53935" }} />
              ) : (
                <CheckCircleOutlineIcon
                  sx={{ fontSize: 40, color: "#43a047" }}
                />
              )}
            </Box>
            <Typography
              id="modal-title"
              variant="h5"
              component="h2"
              gutterBottom
            >
              {responseMessage.includes("Failed") ||
              responseMessage.includes("already exists")
                ? "Error"
                : "Success"}
            </Typography>
            <Typography id="modal-description" sx={{ mt: 1, mb: 3 }}>
              {responseMessage}
            </Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                bgcolor: "#43a047",
                "&:hover": { bgcolor: "#388e3c" },
                borderRadius: 2,
                px: 4,
                py: 1.5,
              }}
            >
              OK
            </Button>
          </Box>
        </Modal>
      </form>
    </>
  );
};

export default AddStockIdeaForm;
