import React, { useState, useEffect } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";
// import "./PEratio.css";

function PriceToEarning({ symbol, earningPerShare, className }) {
  const [peRatio, setPeRatio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const cacheKey = `peRatio-${symbol}`;
    const fetchData = async () => {
      const now = new Date();
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const { expiry, value } = JSON.parse(cachedData);
        if (now.getTime() < expiry) {
          // Cache is valid, use it
          setPeRatio(value);
          setLoading(false);
          return;
        }
      }

      try {
        const response = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          {
            withCredentials: true,
          }
        );
        const currentPrice = response.data.price;
        if (currentPrice && earningPerShare) {
          const calculatedPeRatio = (currentPrice / earningPerShare).toFixed(2);
          setPeRatio(calculatedPeRatio);
          setLoading(false);

          // Set new expiry for 10 minutes from now
          const expiry = now.getTime() + 1000 * 60 * 10; // 10 minutes in milliseconds
          localStorage.setItem(
            cacheKey,
            JSON.stringify({ value: calculatedPeRatio, expiry })
          );
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol, earningPerShare]);

  if (loading) {
    return <Skeleton variant="text" width={200} height={40} />;
  }

  if (error) {
    return <div>---</div>; // Display error message
  }

  return (
    <>
      <h2 className={className}>{peRatio} Times</h2>
    </>
  );
}

export default PriceToEarning;
