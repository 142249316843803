import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 px-4">
      <h1 className="text-7xl font-extrabold text-red-600 mb-4" aria-label="404 Error">
        404
      </h1>
      <h2 className="text-4xl font-semibold mb-2 text-gray-800">Page Not Found</h2>
      <p className="text-md mb-4 text-center text-gray-600">
        Sorry, the page you are looking for does not exist.
      </p>
      <Link
        to="/"
        className="bg-blue-600 text-white py-2 px-4 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300 transform hover:scale-105"
        aria-label="Go to Home"
      >
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
