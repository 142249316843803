import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";

const StockSearch = ({
  handleSelectResult,
  initialValue,
  autoFocus,
  isOpen,
}) => {
  const [query, setQuery] = useState(initialValue || "");
  const [results, setResults] = useState([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchResults = debounce(async (searchQuery) => {
    if (searchQuery.length === 0 || !showDropdown) {
      setResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${SERVER_URL}/search?query=${searchQuery}`,
        {
          withCredentials: true,
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300);

  useEffect(() => {
    fetchResults(query);
  }, [query]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (isOpen) {
      setQuery("");
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setQuery(item["Company Name"]);
    setResults([]);
    setShowDropdown(false);
    handleSelectResult(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveResultIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setActiveResultIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter") {
      if (activeResultIndex !== -1) {
        handleSelect(results[activeResultIndex]);
      } else if (results.length > 0) {
        handleSelect(results[0]);
      }
    }
  };

  useEffect(() => {
    setActiveResultIndex(-1);
  }, [query]);

  useEffect(() => {
    if (initialValue) {
      setQuery(initialValue);
      setShowDropdown(false);
    }
  }, [initialValue]);

  return (
    <div className="relative w-full max-w-xl mx-auto">
      <div className="relative flex items-center">
        <SearchIcon className="absolute left-3 text-blue-500" />

        <input
          ref={inputRef}
          type="text"
          value={query}
          onFocus={() => setShowDropdown(true)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search for a company..."
          className="w-full py-2 pl-10 pr-4 border-2 rounded border-blue-500  text-gray-700 bg-gray-100 focus:outline-none focus:border-blue-700 focus:ring-2 focus:ring-blue-200 transition-all"
        />
      </div>
      {query && results.length > 0 && showDropdown && (
        <ul className="absolute bg-white border border-gray-300 rounded-md shadow-lg w-full mt-2 z-10 max-h-40 overflow-y-auto">
          {results.map((item, index) => (
            <li
              key={index}
              onClick={() => handleSelect(item)}
              className={`p-2 cursor-pointer flex justify-between items-center ${
                index === activeResultIndex ? "bg-blue-100" : ""
              } transition-all`}
            >
              <span className="text-gray-900">{item["Company Name"]}</span>
              <span className="text-sm text-gray-500">
                {item["NSE_Symbol_NS"]}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StockSearch;
