import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import "./SearchBar.css";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({
  handleSelectResult,
  initialValue,
  autoFocus,
  isOpen,
  inputStyle,
}) => {
  const [query, setQuery] = useState(initialValue || "");
  const [results, setResults] = useState([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchResults = debounce(async (searchQuery) => {
    if (searchQuery.length === 0 || !showDropdown) {
      setResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `${SERVER_URL}/search?query=${searchQuery}`,
        {
          withCredentials: true,
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300);

  useEffect(() => {
    fetchResults(query);
  }, [query]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  // Add this useEffect to clear the query when the modal is opened
  useEffect(() => {
    if (isOpen) {
      setQuery("");
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setQuery(item["Company Name"]);
    setResults([]);
    setShowDropdown(false);
    handleSelectResult(item);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setActiveResultIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setActiveResultIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter") {
      if (activeResultIndex !== -1) {
        handleSelect(results[activeResultIndex]);
      } else if (results.length > 0) {
        handleSelect(results[0]);
      }
    }
  };

  useEffect(() => {
    setActiveResultIndex(-1);
  }, [query]);

  useEffect(() => {
    if (initialValue) {
      setQuery(initialValue);
      setShowDropdown(false);
    }
  }, [initialValue]);

  return (
    <div className="search-bar-container">
      <div className="input-icon-container">
        <SearchIcon className="input-icon" />

        <input
          ref={inputRef}
          className="rounded-search-bar"
          type="text"
          value={query}
          onFocus={() => setShowDropdown(true)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={inputStyle}
        />
        {query === "" && (
          <span className="animated-placeholder">Search for a company...</span>
        )}
      </div>
      {query && results.length > 0 && (
        <ul className="suggestions-dropdown">
          {results.map((item, index) => (
            <li
              key={index}
              className={`suggestion-item ${
                index === activeResultIndex ? "selected" : ""
              }`}
              onClick={() => handleSelect(item)}
            >
              <span>{item["Company Name"]}</span>
              <span>{item["NSE_Symbol_NS"]}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
