
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "./index.css";
import axios from 'axios'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'roboto',
      textTransform: 'none',
      fontSize: 16,
    },
  },
});

axios.defaults.withCredentials = true

const root = createRoot(
  document.getElementById('root')
);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictMode>
);