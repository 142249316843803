

import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../Shared/context/auth-context'; // Adjust the import path as necessary

/**
 * Higher-order component to protect admin and sub-admin routes.
 * Checks if the user is an admin or has a specific role, and either renders the component or redirects to the login page.
 */
const withAdminAuth = (Component, requiredRole = null) => {
  return (props) => {
    // Access user and isLoading state from AuthContext
    const { user, isLoading } = useContext(AuthContext);

    // If the app is still loading, you might want to return a loading indicator
    if (isLoading) {
      return <div>Loading...</div>;
    }

    // If the user is an admin or has the required role, render the component
    if (user && (user.isAdmin || (user.isSubAdmin && (!requiredRole || user.role === requiredRole)))) {
      return <Component {...props} />;
    } else {
      // If the user is not authorized, redirect to the login page
      return <Redirect to="/auth" />;
    }
  };
};

export default withAdminAuth;


