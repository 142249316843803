import React, { useState } from "react";
import axios from "axios";
import { Modal, Box, Typography, Button } from "@mui/material";

const SearchAndEditReport = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formData, setFormData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchSuggestions = async (searchQuery) => {
    if (!searchQuery) return;
    try {
      const response = await axios.get(
        `${SERVER_URL}/admin/reports/search?query=${searchQuery}`
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const fetchReportDetails = async (companyName) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/admin/reports/search?query=${companyName}`
      );
      if (response.data.length === 1) {
        // console.log("Report Data:", response.data[0]); // Log the data being set
        setFormData(response.data[0]); // Setting the entire report data
        setSelectedCompany(companyName);
      } else if (response.data.length > 1) {
        console.error(
          "Multiple reports found, unable to determine which one to select."
        );
      } else {
        console.error("No report found for the selected company.");
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    fetchSuggestions(e.target.value);
  };

  const handleCompanySelect = (companyName) => {
    setQuery(companyName);
    fetchReportDetails(companyName);
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    try {
      await axios.put(`${SERVER_URL}/admin/reports/${formData._id}`, formData);
      setSuccessMessage("Report updated successfully!");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error updating report:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${SERVER_URL}/admin/reports/${formData._id}`);
      setSuccessMessage("Report deleted successfully!");
      setShowConfirmation(false); // Close the confirmation modal
      setShowSuccessModal(true); // Open the success modal
      setFormData({});
      setSelectedCompany(null);
      setQuery("");
    } catch (error) {
      console.error("Error deleting report:", error);
    }
  };

  const handleDeleteConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="max-w-full mx-auto p-6 bg-gray-900 text-white shadow-md rounded-lg">
      <label className="block text-sm font-medium mb-2">Search Company</label>
      <input
        type="text"
        value={query}
        onChange={handleQueryChange}
        className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
        placeholder="Start typing to search..."
      />
      {suggestions.length > 0 && (
        <div className="bg-gray-800 rounded-md shadow-lg mt-2">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion._id}
              onClick={() => handleCompanySelect(suggestion.companyName)}
              className="p-2 cursor-pointer hover:bg-gray-700 transition duration-200"
            >
              {suggestion.companyName}
            </div>
          ))}
        </div>
      )}
      {selectedCompany && (
        <form
          className="mt-6"
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdate();
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Sector</label>
              <input
                type="text"
                name="Sector"
                value={formData.Sector || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Symbol</label>
              <input
                type="text"
                name="symbol"
                value={formData.symbol || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Recommendation
              </label>
              <select
                name="recommendation"
                value={formData.recommendation || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              >
                <option value="BUY">BUY</option>
                <option value="HOLD">HOLD</option>
                <option value="SELL">SELL</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Strategy</label>
              <select
                name="Strategy"
                value={formData.Strategy || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              >
                <option value="BTSM">BTSM</option>
                <option value="BTSF">BTSF</option>
                <option value="BTSY">BTSY</option>
                <option value="BTSFY">BTSFY</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Shariah Status
              </label>
              <select
                name="ShariahStatus"
                value={formData.ShariahStatus || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              >
                <option value="Compliant">Compliant</option>
                <option value="Non-Compliant">Non-Compliant</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Buy Range
              </label>
              <input
                type="text"
                name="buyRange"
                value={formData.buyRange || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Target Price
              </label>
              <input
                type="text"
                name="targetPrice"
                value={formData.targetPrice || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Stop Loss
              </label>
              <input
                type="text"
                name="stopLoss"
                value={formData.stopLoss || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Duration</label>
              <input
                type="text"
                name="Duration"
                value={formData.Duration || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Capital Investment
              </label>
              <input
                type="number"
                name="capitalInvestment"
                value={formData.capitalInvestment || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium mb-2">Summary</label>
              <textarea
                name="Summary"
                value={formData.Summary || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
                rows="4"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Rating</label>
              <input
                type="text"
                name="rating"
                value={formData.rating || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Report Link
              </label>
              <input
                type="text"
                name="reportLink"
                value={formData.reportLink || ""}
                onChange={handleFormChange}
                className="mt-1 block w-full p-3 bg-gray-800 border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 w-full mr-2 md:w-auto bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-full shadow-lg transform transition-transform hover:scale-105 ${
                isSubmitting ? "opacity-50" : ""
              }`}
            >
              {isSubmitting ? "Submitting..." : "Update Report"}
            </button>
            <button
              type="button"
              onClick={handleDeleteConfirmation}
              className="px-4 py-2 bg-red-500 text-white rounded-full shadow-lg transform transition-transform hover:scale-105"
            >
              Delete Report
            </button>
          </div>
        </form>
      )}
      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="success-modal-title" variant="h6" component="h2">
            Success
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => setShowSuccessModal(false)}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2"
          >
            Confirm Deletion
          </Typography>
          <Typography id="delete-confirmation-description" sx={{ mt: 2 }}>
            Are you sure you want to delete the report for{" "}
            {formData.companyName}?
          </Typography>
          <Box mt={4} display="flex" justifyContent="flex-end" gap={2}>
            <Button
              onClick={handleCloseConfirmation}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              color="secondary"
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchAndEditReport;
