import React, { useState } from 'react';
import AddIpo from "./AddManualipo";
import UploadIpoFromExcel from './UploadIpoFromExcel';

function PostIpo() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleComponentDisplay = (component) => {
        setSelectedComponent(component);
    };

    const handleBack = () => {
        setSelectedComponent(null);
    };

    return (
        <div className="p-6 max-w-full mx-auto">
            <h1 className="text-3xl font-bold mb-8 text-center text-white">Admin IPO Management</h1>
            
            {!selectedComponent ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Add IPO Card */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 text-center">
                        <h2 className="text-2xl font-semibold text-white mb-4">Add IPO Manually</h2>
                        <p className="text-gray-300 mb-6">Fill out the form to manually add a new IPO entry.</p>
                        <button 
                            onClick={() => handleComponentDisplay('AddIpo')} 
                            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition"
                        >
                            Add IPO
                        </button>
                    </div>

                    {/* Upload IPO from Excel Card */}
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 text-center">
                        <h2 className="text-2xl font-semibold text-white mb-4">Upload IPOs from Excel</h2>
                        <p className="text-gray-300 mb-6">Upload an Excel file to add multiple IPO entries at once.</p>
                        <button 
                            onClick={() => handleComponentDisplay('UploadIpoFromExcel')} 
                            className="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition"
                        >
                            Upload IPOs
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <button 
                        onClick={handleBack} 
                        className="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition mb-6"
                    >
                        Back
                    </button>
                    
                    {selectedComponent === 'AddIpo' && <AddIpo />}
                    {selectedComponent === 'UploadIpoFromExcel' && <UploadIpoFromExcel />}
                </div>
            )}
        </div>
    );
}

export default PostIpo;

