import React, { useState, useEffect } from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Skeleton from "@mui/material/Skeleton";

function NetGain({ symbol, className }) {
  const [realTimePrice, setRealTimePrice] = useState(null);
  const [netGain, setNetGain] = useState(null);
  const [error, setError] = useState(null);
  const [actualNetGain, setActualNetGain] = useState(null);
  const [loading, setLoading] = useState(true);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    let isMounted = true;

    const fetchRealTimePriceAndNetGain = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/finsha/net-gain/${symbol}`,
          { withCredentials: true }
        );

        if (isMounted) {
          const { price, netGain, actualNetGain } = response.data;
          setRealTimePrice(price);
          setNetGain(netGain);
          setActualNetGain(actualNetGain);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchRealTimePriceAndNetGain();

    return () => {
      isMounted = false;
    };
  }, [symbol, SERVER_URL]);

  return (
    <div className={className}>
      {loading && <Skeleton variant="rectangular" width={400} height={30} />}
      {error && <p>Error: {error}</p>}
      {!loading && !error && netGain !== null && (
        <>
          <h4 className={actualNetGain >= 0 ? "positive" : "negative"}>
            ₹{actualNetGain}
          </h4>
          <span>|</span>
          <h4 className={netGain >= 0 ? "positive" : "negative"}>
            {netGain >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}(
            {netGain}%)
          </h4>
        </>
      )}
    </div>
  );
}

export default NetGain;
