import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const SearchArticleComponent = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [searchTitle, setSearchTitle] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [article, setArticle] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    summary: "",
    content: "",
    videoUrl: "",
    imageUrl: "",
  });

  const handleTitleChange = async (e) => {
    const value = e.target.value;
    setSearchTitle(value);

    if (value.length > 0) {
      try {
        const response = await axios.get(
          `${SERVER_URL}/admin/market-view/search?title=${value}`
        );

        // If the response is an array, it's a list of suggestions
        if (Array.isArray(response.data)) {
          setSuggestions(response.data);
        } else {
          // If the response is a single object, it's the exact article
          setArticle(response.data);
          setFormData(response.data); // Pre-fill the form with article data for editing
          setEditMode(false); // Ensure edit mode is off initially
          setSuggestions([]); // Clear suggestions
        }
      } catch (error) {
        console.error("Error fetching suggestions or article", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSearch = async (title) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/admin/market-view/search?title=${title || searchTitle}`
      );

      // Handle the response as before
      setArticle(response.data);
      setFormData(response.data); // Pre-fill the form with article data for editing
      setEditMode(false); // Ensure edit mode is off initially
      setSuggestions([]); // Clear suggestions
    } catch (error) {
      console.error("Article not found", error);
      alert("Article not found");
      setArticle(null);
      setSuggestions([]); // Clear suggestions
    }
  };

  // const handleSearch = async () => {
  //     try {
  //         const response = await axios.get(`${SERVER_URL}/admin/market-view/search?title=${searchTitle}`);
  //         setArticle(response.data);
  //         setFormData(response.data); // Pre-fill the form with article data for editing
  //         setEditMode(false); // Ensure edit mode is off initially
  //     } catch (error) {
  //         console.error('Article not found', error);
  //         alert('Article not found');
  //         setArticle(null);
  //     }
  // };

  const handleDelete = () => {
    setConfirmDialogOpen(true); // Open the confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${SERVER_URL}/admin/market-view/${article._id}`);
      setDialogTitle("Success");
      setDialogContent("Article deleted successfully");
      setDialogOpen(true);
      setArticle(null); // Clear the article data after deletion
      setSearchTitle(""); // Clear the search input
    } catch (error) {
      console.error("Error deleting the article", error);
      setDialogTitle("Error");
      setDialogContent("Failed to delete article");
      setDialogOpen(true);
    } finally {
      setConfirmDialogOpen(false); // Close the confirmation dialog
    }
  };

  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
  };

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    try {
      const response = await axios.put(
        `${SERVER_URL}/admin/market-view/${article._id}`,
        formData
      );
      if (response.status === 200) {
        setDialogTitle("Success");
        setDialogContent("Article updated successfully");
        setDialogOpen(true);
        setArticle(null); // Clear the article data after updating
        setSearchTitle(""); // Clear the search input
        setEditMode(false); // Close the form
      } else {
        setDialogTitle("Error");
        setDialogContent("Failed to update article");
        setDialogOpen(true);
      }
    } catch (error) {
      console.error("Error updating the article", error);
      setDialogTitle("Error");
      setDialogContent("Failed to update article");
      setDialogOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="search-article-component max-w-3xl mx-auto mt-10 p-8 bg-gray-900 text-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-6 text-center text-indigo-400">
        Search for an Article
      </h1>
      {/* <div className="mb-6">
                <input
                    type="text"
                    placeholder="Enter article title"
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                    className="w-full p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
                />
                <button
                    onClick={handleSearch}
                    className="w-full mt-4 py-2 px-4 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
                >
                    Search Article
                </button>
            </div> */}

      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Enter article title"
          value={searchTitle}
          onChange={handleTitleChange}
          className="w-full p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
        />
        {suggestions.length > 0 && (
          <ul className="absolute left-0 right-0 mt-2 bg-gray-800 border border-gray-700 rounded-lg max-h-48 overflow-y-auto z-10">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSearch(suggestion.title)}
                className="p-2 cursor-pointer hover:bg-indigo-500 hover:text-white"
              >
                {suggestion.title}
              </li>
            ))}
          </ul>
        )}
        <button
          onClick={() => handleSearch()}
          className="w-full mt-4 py-2 px-4 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
        >
          Search Article
        </button>
      </div>

      {article && !editMode && (
        <div className="article-details bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4 text-white">{article.title}</h2>
          <p className="mb-4 text-gray-300">
            <strong>Summary:</strong> {article.summary}
          </p>
          <p className="mb-4 text-gray-300">
            <strong>Content:</strong> {article.content}
          </p>
          {article.videoUrl && (
            <p className="mb-4 text-gray-300">
              <strong>Video URL:</strong> {article.videoUrl}
            </p>
          )}
          {article.imageUrl && (
            <p className="mb-4 text-gray-300">
              <strong>Image URL:</strong> {article.imageUrl}
            </p>
          )}
          <div className="flex justify-between mt-6">
            <button
              onClick={handleEdit}
              className="py-2 px-4 bg-blue-600 hover:bg-blue-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
            >
              Edit
            </button>
            <button
              onClick={handleDelete}
              className="py-2 px-4 bg-red-600 hover:bg-red-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      )}

      {editMode && (
        <div className="edit-article-form mt-6">
          <h2 className="text-xl font-bold mb-4 text-white">Edit Article</h2>
          <form onSubmit={handleUpdate} className="space-y-6">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-300"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="summary"
                className="block text-sm font-medium text-gray-300"
              >
                Summary
              </label>
              <input
                type="text"
                id="summary"
                name="summary"
                value={formData.summary}
                onChange={handleChange}
                className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="content"
                className="block text-sm font-medium text-gray-300"
              >
                Content
              </label>
              <textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleChange}
                className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500 h-40"
                required
              />
            </div>
            <div>
              <label
                htmlFor="videoUrl"
                className="block text-sm font-medium text-gray-300"
              >
                Video URL
              </label>
              <input
                type="text"
                id="videoUrl"
                name="videoUrl"
                value={formData.videoUrl}
                onChange={handleChange}
                className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label
                htmlFor="imageUrl"
                className="block text-sm font-medium text-gray-300"
              >
                Image URL
              </label>
              <input
                type="text"
                id="imageUrl"
                name="imageUrl"
                value={formData.imageUrl}
                onChange={handleChange}
                className="w-full mt-1 p-2 bg-gray-800 border border-gray-700 rounded focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-green-600 hover:bg-green-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300"
            >
              Update Article
            </button>
          </form>
        </div>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <p>{dialogContent}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this article?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchArticleComponent;
