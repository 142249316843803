import React, { useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import FreeSubscribeMessage from "./FreeSubscribeMessage";

import "./UnsubscribedGrid.css";

function UnsubscribedStockIdeaGrid({ data = [], isUserSubscribed }) {
  const [selectedStock, setSelectedStock] = useState(null);

  const handleStockClick = (stock) => {
    setSelectedStock(selectedStock === stock ? null : stock);
  };

  return (
    <div className="unsubscibedStockIdea__conatiner">
      <div className="unsubscibed-stockidea_title_div">
        <h1 className="unsubscibed-title">STOCK IDEAS</h1>
      </div>

      <div className="unsubscibed_stockidea_card_container">
        {data.map((stock, index) => (
          <div
            className="unsubscibed__stockidea__stock-card"
            key={index}
            onClick={() => handleStockClick(stock)}
          >
            <div className="unsubscribed_stock_content">
              <div className="unsubscribed_heaidng_content w-full">
                <div className="border border-gray-300 rounded-md p-1 flex items-center w-full">
                  <span className="font-semibold text-gray-700 mr-2">
                    Company Name:
                  </span>
                  <h5
                    className={`stock-company-name ${
                      !isUserSubscribed ? "blurred__unsubscribe" : ""
                    }`}
                    style={{
                      userSelect: "none",
                      WebkitUserSelect: "none" /* For Safari and Chrome */,
                      MozUserSelect: "none" /* For Firefox */,
                      MsUserSelect: "none" /* For IE/Edge */,
                    }}
                  >
                    {stock["Company Name"]}
                  </h5>
                </div>
              </div>

              <div className="unsubscribed_stock-details mt-0 flex justify-between">
                <div className="w-1/2 pr-2">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Price:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      123 12(18%)
                    </span>
                  </div>
                </div>
                <div className="w-1/2 pl-2 text-left">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Strategy:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      ({stock["portfolioStrategy"]})
                    </span>
                  </div>
                </div>
              </div>

              <div className="unsubscribed_stock-details mt-0 flex justify-between">
                <div className="w-1/2 pr-2">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Buy Price:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      {stock["Buy Option"]}
                    </span>
                  </div>
                </div>
                <div className="w-1/2 pl-2 text-left mr-2">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Term:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      {stock["Holding Duration"]}
                    </span>
                  </div>
                </div>
              </div>

              <div className="unsubscribed_stock-details flex justify-between">
                <div className="w-1/2 pr-2">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Target Price:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      {stock["Target Price"]}
                    </span>
                  </div>
                </div>
                <div className="w-1/2 pl-2 text-left">
                  <div className="flex items-center border border-gray-300 rounded-md p-2">
                    <span className="font-semibold mr-4">Stop Loss:</span>
                    <span
                      className="blur-sm"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none" /* For Safari and Chrome */,
                        MozUserSelect: "none" /* For Firefox */,
                        MsUserSelect: "none" /* For IE/Edge */,
                      }}
                    >
                      {stock["Stop Loss"]}
                    </span>
                  </div>
                </div>
              </div>
              
              <div className="mb-2 w-full text-center text-lg text-gray-800">
                <FreeSubscribeMessage />
              </div>

              <div className="flex items-center justify-center">
                <button
                  onClick={() => (window.location.href = "/pricing")}
                  className="bg-yellow-400 text-black py-1 px-3 rounded-full text-center shadow-md hover:bg-yellow-500 hover:text-white transition ease-in-out duration-300 flex items-center justify-center"
                >
                  Subscribe Now{" "}
                  <LockIcon
                    className="ml-2 text-lg"
                    style={{ fontSize: "15px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UnsubscribedStockIdeaGrid;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Grid from "@material-ui/core/Grid";
// import LockIcon from "@material-ui/icons/Lock";
// import CurrentPrice from "../../../Screener/components/stock-price"; // Ensure this component is correctly defined
// import PricePercentage from "../../../Screener/components/price-percentage"; // Ensure this component is correctly defined
// import FreeSubscribeMessage from "./FreeSubscribeMessage";
// import "./UnsubscribedGrid.css";

// function DateCard({ date, onClick }) {
//   return (
//     <div className="date-card" onClick={() => onClick(date)}>
//       <div className="date-card-title">Stock Idea for</div>
//       <div className="date-card-date">{date}</div>
//     </div>
//   );
// }

// function UnsubscribedStockIdeaGrid({ groupedData, isUserSubscribed }) {
//   const [selectedDate, setSelectedDate] = useState(null);

//   const handleDateClick = (date) => {
//     setSelectedDate(selectedDate === date ? null : date);  // Toggle functionality
//   };

//   const handleClose = () => {
//     setSelectedDate(null); // Reset selected date to null to show all dates again
//   };

//   return (
//     <>
//       <div className="stock-ideas-container__Unsubscribed">
//         <h1 className="stock-ideas-title__unsubscribe">
//           <i className="fas fa-lightbulb"></i> STOCK IDEAS
//         </h1>
//         <div className="date-cards-container">
//           {Object.entries(groupedData).sort((a, b) => new Date(b[0].split("-").reverse().join("-")) - new Date(a[0].split("-").reverse().join("-"))).map(([date, stocks]) => (
//             <React.Fragment key={date}>
//               {selectedDate === null && <DateCard date={date} onClick={handleDateClick} />}
//               {selectedDate === date && (
//                 <>
//                   <Grid container spacing={3}>
//                     {stocks.map((stock, index) => (
//                       <Grid item xs={12} sm={6} md={4} key={index}>
//                       <button onClick={handleClose} className="unsubscribed__stockIdea__closeButton">Close</button>
//                         <div className="stock-card__unsubscribe">
//                           <div className="stock-card-content__unsubscribe">
//                             <div className="ribbon__unsubscribe">{stock["Visibility"]}</div>
//                             <div className="stock-header__unsubscribe">
//                               <h5 className={`stock-company__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`} style={{ userSelect: "none" }}>
//                                 {stock["Company Name"]}
//                               </h5>
//                               <span className={`stock-sector__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`} style={{ backgroundColor: "grey", userSelect: "none" }}>
//                                 {stock["Sector"]}
//                               </span>
//                             </div>
//                             <div className={`shariah-div__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`}>
//                               <span className={`stock-shariah-status__unsubscribe ${stock["Shariah Status"] ? stock["Shariah Status"].toLowerCase() + "__unsubscribe" : ""}`} style={{ backgroundColor: "grey", userSelect: "none" }}>
//                                 {stock["Shariah Status"]}
//                               </span>
//                             </div>
//                             <div className={`stock-current-price__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`}>
//                               <CurrentPrice className="current-price-component__unsubscribe" symbol={stock["Symbol"]} />
//                               <PricePercentage className={`price-change__unsubscribe ${stock["Price Change"] > 0 ? "positive__unsubscribe" : "negative__unsubscribe"}`} symbol={stock["Symbol"]} />
//                             </div>
//                             <div className="stock-recommendation-buy-title__unsubscribe">
//                               <span className="recommendation-label__unsubscribe">Recommendation:</span>
//                               <span className="buy-option-label__unsubscribe">Buy Option:</span>
//                             </div>
//                             <div className={`stock-recommendation-buy-data__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`}>
//                               <span className={`recommendation-data__unsubscribe ${stock["Recommendation"] ? stock["Recommendation"].toLowerCase() + "__unsubscribe" : ""}`} style={{ backgroundColor: "grey", userSelect: "none" }}>
//                                 {stock["Recommendation"]}
//                               </span>
//                               <span className="buy-option-data__unsubscribe" style={{ backgroundColor: "grey", userSelect: "none" }}>
//                                 {stock["Buy Option"]}
//                               </span>
//                             </div>
//                             <div className="freeSubscribe-msg__unsubscribe">
//                               <FreeSubscribeMessage />
//                             </div>
//                             <div className="second-row__unsubscribe">
//                               <div className="stock-details__unsubscribe">
//                                 <span className="stock-target-price-label__unsubscribe">Target Price:</span>
//                                 <span className="stock-stop-loss-label__unsubscribe">Stop Loss:</span>
//                                 <span className="stock-holding-duration-label__unsubscribe">Duration:</span>
//                               </div>
//                               <div className={`stock-details-data__unsubscribe ${!isUserSubscribed ? "blurred__unsubscribe" : ""}`}>
//                                 <span className="stock-target-price__unsubscribe" style={{ backgroundColor: "grey", userSelect: "none" }}>{stock["Target Price"]}</span>
//                                 <span className="stock-stop-loss__unsubscribe" style={{ backgroundColor: "grey", userSelect: "none" }}>{stock["Stop Loss"]}</span>
//                                 <span className="stock-holding-duration__unsubscribe" style={{ backgroundColor: "grey", userSelect: "none" }}>{stock["Holding Duration"]}</span>
//                               </div>
//                             </div>
//                             <p className="stock-message__unsubscribe">
//                               <Link to="/pricing">
//                                 Subscribe <LockIcon style={{ color: "black" }} />
//                               </Link>
//                             </p>
//                           </div>
//                         </div>
//                       </Grid>
//                     ))}
//                   </Grid>
//                 </>
//               )}
//             </React.Fragment>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default UnsubscribedStockIdeaGrid;
