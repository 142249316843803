import React, { useState, useEffect, useContext } from "react";
import "./Portfolio.css";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { AuthContext } from "../../Shared/context/auth-context";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Shared/UIElements/LoadingSpinner";
import Portfolioskelton from "../components/Portfolio Component/portfolioskelton";
import AllPortfolioValue from "../components/Portfolio Component/AllPortfolioValue";
import HoldingPortfolio from "../components/Portfolio Component/HoldingPortfolio";
import SoldPortfolioTable from "../components/Portfolio Component/SoldPortfolioTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PortfolioSearchBar from "../../Shared/SearchBar/PortfolioSearchBar";
import { GiEmptyHourglass } from "react-icons/gi";

const useStyles = makeStyles({
  clearIndicator: {
    position: "absolute !important",
    right: "5px",
    top: "5px",
    height: "20px",
    width: "20px",
  },
  popupIndicator: {
    position: "absolute !important",
    right: "30px",
    top: "5px",
    height: "20px",
    width: "20px",
  },
});

function Portfolio() {
  const user = useContext(AuthContext).user;
  const [portfolio, setPortfolio] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [sold, setSold] = useState([]);
  const [sellFormIndex, setSellFormIndex] = useState(null);
  const [sellPrice, setSellPrice] = useState("");
  const [, setCompanies] = useState([]);
  const [, setFilteredCompanies] = useState([]);
  const [myOptions, setMyOptions] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [companyPrice, setCompanyPrice] = useState(0);
  const [, setInvestment] = useState(0);
  const [, setMarketValue] = useState(0);
  const [, setProfitOrLoss] = useState(0);
  const [, setpurgableIncome] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalMarketValue, setTotalMarketValue] = useState(0);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [totalPurgableHolding, setTotalPurgableHolding] = useState(0);
  const [totalPurgableSold, setTotalPurgableSold] = useState(0);
  const [previousSymbol, setPreviousSymbol] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelling, setIsSelling] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [, setIsEdited] = useState(false);
  const [stopLoss, setStopLoss] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const classes = useStyles();
  const [totalSoldProfitLoss, setTotalSoldProfitLoss] = useState(0);
  const [quantityToSell, setQuantityToSell] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [activeTab, setActiveTab] = useState("Holding");
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const switchTab = (tabName) => {
    setActiveTab(tabName);
  };

  // const fetchCompanyDetails = async (companyName) => {
  //   try {
  //     const response = await fetch(
  //       `${SERVER_URL}/screener/company?name=${encodeURIComponent(
  //         companyName
  //       )}`,
  //       {
  //         credentials: "include", // If needed, for example, for session cookies
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const companyDetails = await response.json();
  //     setSelectedCompanyDetails(companyDetails); // Save the fetched details in the state
  //   } catch (error) {
  //     console.error("Failed to fetch company details:", error);
  //   }
  // };

  // replace fetch with axios
  const fetchCompanyDetails = async (companyName) => {
    try {
      const response = await axios.get(`${SERVER_URL}/screener/company`, {
        params: { name: companyName },
        withCredentials: true, // If needed, for example, for session cookies
      });

      const companyDetails = response.data;
      setSelectedCompanyDetails(companyDetails); // Save the fetched details in the state
    } catch (error) {
      console.error("Failed to fetch company details:", error);
    }
  };

  // useEffect(() => {
  //   let intervalId = null;
  //   const fetchPrice = async () => {
  //     setLoading(true); // Start loading
  //     try {
  //       const response = await fetch(
  //         `${SERVER_URL}/screener/current-price/${symbol}`,
  //         {
  //           credentials: "include",
  //         }
  //       );
  //       const data = await response.json();
  //       if (data && data.price) {
  //         setCurrentPrice(data.price);
  //         if (symbol !== previousSymbol) {
  //           setCompanyPrice(data.price);
  //           setPreviousSymbol(symbol);
  //         }
  //       }
  //     } catch (error) {
  //       // console.error(error);
  //     }
  //     setLoading(false); // End loading
  //   };
  //   if (symbol) {
  //     fetchPrice();
  //     intervalId = setInterval(fetchPrice, 1000 * 60 * 60);
  //   }
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [symbol, previousSymbol]);

  // replace fetch with axios
  useEffect(() => {
    let intervalId = null;
    const fetchPrice = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          {
            withCredentials: true,
          }
        );
        const data = response.data;
        if (data && data.price) {
          setCurrentPrice(data.price);
          if (symbol !== previousSymbol) {
            setCompanyPrice(data.price);
            setPreviousSymbol(symbol);
          }
        }
      } catch (error) {
        // console.error(error);
      }
      setLoading(false); // End loading
    };

    if (symbol) {
      fetchPrice();
      intervalId = setInterval(fetchPrice, 1000 * 60 * 60);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [symbol, previousSymbol]);

  useEffect(() => {
    fetchPortfolio();
  }, [user]);

  const fetchPortfolio = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${SERVER_URL}/finsha/portfolio`, {
        withCredentials: true,
      });
      setPortfolio(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const calculatePortfolioValues = () => {
      let totalInvestment = 0;
      let totalMarketValue = 0;
      let totalProfitLoss = 0;
      let totalPurgableHolding = 0;
      let totalPurgableSold = 0;
      let totalSoldProfitLoss = 0;

      portfolio.forEach((entry) => {
        totalInvestment += entry.investment;
        totalMarketValue += entry.marketValue;
        totalProfitLoss += entry.profitOrLoss;
        totalPurgableHolding += entry.purgableIncome;
      });
      // Calculate total purgable value for sold stocks
      totalPurgableSold = sold.reduce(
        (total, entry) => total + entry.purgableIncome,
        0
      );
      totalSoldProfitLoss = sold.reduce(
        (total, entry) => total + entry.profitLoss,
        0
      ); // Calculate total profit/loss for sold stocks
      setTotalInvestment(totalInvestment);
      setTotalMarketValue(totalMarketValue);
      setTotalProfitLoss(totalProfitLoss);
      setTotalPurgableHolding(totalPurgableHolding);
      setTotalPurgableSold(totalPurgableSold);
      setTotalSoldProfitLoss(totalSoldProfitLoss); // Set sta
    };
    calculatePortfolioValues();
  }, [portfolio, sold]);

  useEffect(() => {
    setLoading(true);
    // const userId = user._id;
    async function fetchSold() {
      const response = await axios.get(`${SERVER_URL}/finsha/sell`, {
        withCredentials: true,
      });
      setSold(response.data);
      setLoading(false);
    }
    fetchSold();
  }, [user, refresh]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSubmitting(true);
    let interestToRevenue = 0;
    let loanToMarketCap = 0;
    let investmentToMarketCap = 0;
    let permissibilityStatus = "No"; // Default value is set here

    if (selectedCompanyDetails) {
      // Now, simply update the variables if selectedCompanyDetails is available
      interestToRevenue = selectedCompanyDetails["Interest to Revenue"] || 0;
      loanToMarketCap = selectedCompanyDetails["Loan to Market Cap"] || 0;
      investmentToMarketCap =
        selectedCompanyDetails["Investment to Market Cap"] || 0;
      permissibilityStatus =
        selectedCompanyDetails["Business Permissiblity Status"] || "No";
    }
    // Parse the values to their appropriate types.
    const parsedCompanyPrice = parseFloat(companyPrice);
    const parsedQuantity = parseInt(quantity);
    // Set default values if not provided by the user
    const finalTargetPrice = targetPrice ? parseFloat(targetPrice) : 0;
    const finalStopLoss = stopLoss ? parseFloat(stopLoss) : 0;
    // Calculate the fields as per your formulas.
    const calculatedInvestment = parsedCompanyPrice * parsedQuantity;
    const calculatedMarketValue = currentPrice * parsedQuantity;
    const calculatedProfitOrLoss = calculatedMarketValue - calculatedInvestment;
    // If calculatedProfitOrLoss is greater than 0, then calculatedPurgableIncome is the product of interestToRevenue and calculatedProfitOrLoss.
    // If calculatedProfitOrLoss is less than or equal to 0, then calculatedPurgableIncome is 0.
    const calculatedPurgableIncome =
      calculatedProfitOrLoss > 0
        ? interestToRevenue * calculatedProfitOrLoss
        : 0;

    // if statement so then when a user doesnt add any target price or stop loss to set the value as zero
    let absoluteTargetPrice, absoluteStopLoss;

    if (finalTargetPrice === 0) {
      absoluteTargetPrice = 0;
    } else {
      absoluteTargetPrice =
        parseFloat(companyPrice) +
        parseFloat(companyPrice) * (finalTargetPrice / 100);
    }

    if (finalStopLoss === 0) {
      absoluteStopLoss = 0;
    } else {
      absoluteStopLoss =
        parseFloat(companyPrice) -
        parseFloat(companyPrice) * (finalStopLoss / 100);
    }
    // Format the purgable income to have only 2 decimal places
    const formattedPurgableIncome = parseFloat(
      calculatedPurgableIncome.toFixed(2)
    );

    let businessStatus = "Non-Compliant"; // Default to "Non-Halal"

    if (permissibilityStatus === "Yes") {
      // Check all conditions for being "Halal"
      if (
        interestToRevenue < 0.05 &&
        loanToMarketCap < 0.33 &&
        investmentToMarketCap < 0.33
      ) {
        businessStatus = "Compliant";
      } // No need for an else, as "Non-Halal" is the default
    }

    const newEntry = {
      companyName,
      symbol,
      companyPrice: parsedCompanyPrice,
      quantity: parsedQuantity,
      investment: calculatedInvestment,
      profitOrLoss: calculatedProfitOrLoss,
      purgableIncome: formattedPurgableIncome,
      marketValue: calculatedMarketValue,
      targetPrice: absoluteTargetPrice,
      targetPricePercentage: targetPrice, // Save the original percentage
      stopLoss: absoluteStopLoss,
      stopLossPercentage: stopLoss,
      interestToRevenue,
      businessStatus,
      interestToRevenue,
    };

    if (editingIndex !== null) {
      const entry = portfolio[editingIndex];
      await axios.put(`${SERVER_URL}/finsha/portfolio/${entry._id}`, newEntry);
      const updatedPortfolio = [...portfolio];
      updatedPortfolio[editingIndex] = { ...newEntry, _id: entry._id };
      setPortfolio(updatedPortfolio);
      setEditingIndex(null);
      window.location.reload();
    } else {
      const response = await axios.post(
        `${SERVER_URL}/finsha/portfolio`,
        newEntry
      );
      setPortfolio([...portfolio, response.data]);
      // remove company from available options
      const remainingOptions = myOptions.filter(
        (option) => option.label !== companyName
      );
      setMyOptions(remainingOptions);
      setLoading(false);
      setIsSubmitting(false);
      fetchPortfolio();
    }

    setCompanyName("");
    setCompanyPrice("");
    setQuantity("");
    setInvestment(""); // New
    setMarketValue(""); // New
    setProfitOrLoss(""); // New
    setpurgableIncome(""); // New
    setTargetPrice("");
    setStopLoss("");
    setShowForm(false);
  };

  const editEntry = (index) => {
    const entry = portfolio[index];
    setCompanyName(entry.companyName);
    setSymbol(entry.symbol);
    setCompanyPrice(entry.companyPrice);
    setQuantity(entry.quantity);
    setTargetPrice(entry.targetPricePercentage); // Set from the percentage value
    setStopLoss(entry.stopLossPercentage); // Set from the percentage value
    setEditingIndex(index);
    setShowForm(true);
  };

  const handleAddNew = () => {
    setCompanyName(""); // reset the companyName
    setCurrentPrice(0);
    setCompanyPrice(0); // similarly for price and quantity
    setQuantity(0);
    setTargetPrice(""); // Clear targetPrice state
    setStopLoss(""); // Clear stopLoss state
    setEditingIndex(null);
    setShowForm(true);
    setIsEdited(false);
  };

  const handleSell = (index) => {
    const entry = portfolio[index];
    setQuantityToSell(entry.quantity);
    setSelectedCompanyName(entry.companyName);
    setSellPrice(entry.marketValue / entry.quantity);
    setSellFormIndex(index);
  };
  const handleDelete = async (index) => {
    setLoading(true);
    const entry = portfolio[index];
    try {
      // Send a DELETE request to remove the entry from the portfolio
      await axios.delete(`${SERVER_URL}/finsha/portfolio/${entry._id}`);

      // Update the state to reflect the changes in the UI
      const updatedPortfolio = [...portfolio];
      updatedPortfolio.splice(index, 1);
      setPortfolio(updatedPortfolio);

      setLoading(false);
    } catch (error) {
      console.error("Error deleting portfolio entry:", error);
      setLoading(false);
    }
  };

  const sellEntry = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSelling(true); // start loading state
    // const userId = user._id;
    const entry = portfolio[sellFormIndex];
    const quantityToSellFloat = parseFloat(quantityToSell);
    // Get the interestToRevenue from the entry directly
    const interestToRevenue = entry.interestToRevenue || 0;
    // Calculate the purgableIncome.
    const calculatedProfitOrLoss =
      parseFloat(sellPrice) * quantityToSellFloat -
      entry.companyPrice * quantityToSellFloat;
    const calculatedPurgableIncome =
      calculatedProfitOrLoss > 0
        ? interestToRevenue * calculatedProfitOrLoss
        : 0;
    // Format the purgable income to have only 2 decimal places
    const formattedPurgableIncome = parseFloat(
      calculatedPurgableIncome.toFixed(2)
    );
    const soldEntry = {
      ...entry,
      sellPrice: parseFloat(sellPrice),
      quantity: quantityToSellFloat,
      purgableIncome: formattedPurgableIncome, // Add purgableIncome to the soldEntry
      _id: undefined,
    };
    // Calculate profit or loss
    const profitLoss =
      (soldEntry.sellPrice - soldEntry.companyPrice) * quantityToSellFloat;
    soldEntry.profitLoss = profitLoss;
    // Add the new sell entry to the sold array in the frontend
    const response = await axios.post(`${SERVER_URL}/finsha/sell`, soldEntry);
    const newSoldEntry = response.data;
    setSold((oldSold) => [...oldSold, newSoldEntry]);
    // Here, using setSold function with callback for correct state update
    setSold((oldSold) => {
      const updatedSoldEntries = oldSold.map((entry) => {
        if (entry.companyName === newSoldEntry.companyName) {
          return {
            ...entry,
            quantity: entry.quantity + newSoldEntry.quantity,
            investment: entry.investment + newSoldEntry.investment,
            profitLoss: entry.profitLoss + newSoldEntry.profitLoss,
          };
        } else {
          return entry;
        }
      });
      const mergedSoldEntries = [];
      const seenCompanyNames = new Set();
      updatedSoldEntries.forEach((entry) => {
        if (!seenCompanyNames.has(entry.companyName)) {
          mergedSoldEntries.push(entry);
          seenCompanyNames.add(entry.companyName);
        }
      });
      return mergedSoldEntries;
    });

    // Update the quantity in the original portfolio entry
    // Updating portfolio state correctly with callback
    setPortfolio((oldPortfolio) => {
      const updatedPortfolio = oldPortfolio
        .map((oldEntry, index) => {
          if (index === sellFormIndex) {
            return {
              ...oldEntry,
              quantity: oldEntry.quantity - quantityToSellFloat,
            };
          } else {
            return oldEntry;
          }
        })
        .filter((oldEntry) => oldEntry.quantity > 0);

      return updatedPortfolio;
    });
    // If all stocks of this entry have been sold, remove it from the portfolio
    const updatedEntry = {
      ...entry,
      quantity: entry.quantity - quantityToSellFloat,
    };
    if (updatedEntry.quantity <= 0) {
      await axios.delete(`${SERVER_URL}/finsha/portfolio/${entry._id}`);
    } else {
      // Otherwise, update the entry in the database (Assuming you have an endpoint for this)
      await axios.put(
        `${SERVER_URL}/finsha/portfolio/${entry._id}`,
        updatedEntry
      );
    }

    setQuantityToSell("");
    setSellPrice("");
    setSellFormIndex(null);
    setIsSelling(false);
    setLoading(false);
    // At the end, update refresh state to trigger a re-fetch of the portfolio
    setRefresh(refresh + 1);
  };

  const deleteSoldEntry = async (index) => {
    setLoading(true);
    const entry = sold[index];
    await axios.delete(`${SERVER_URL}/finsha/sell/${entry._id}`);
    const updatedSold = [...sold];
    updatedSold.splice(index, 1);
    setSold(updatedSold);
    setLoading(false);
  };
  const hasPortfolioValue =
    totalInvestment ||
    totalMarketValue ||
    totalProfitLoss ||
    totalSoldProfitLoss ||
    totalPurgableHolding ||
    totalPurgableSold;

  const handleCompanySelect = async (selectedCompany) => {
    if (!selectedCompany || !selectedCompany["NSE_Symbol_NS"]) {
      console.error("Company or symbol is undefined.");
      return;
    }

    setCompanyName(selectedCompany["Company Name"]);
    setSymbol(selectedCompany["NSE_Symbol_NS"]);
    await fetchCompanyDetails(selectedCompany["Company Name"]);
  };

  return (
    <>
      {loading && <Portfolioskelton />}

      {/*-------------------------------------------------- SEO description of this page ------------------------------------------ */}
      <Helmet>
        <title>Track & Manage Your Compliant Stock Portfolio | Finsha</title>
        <meta
          name="description"
          content="Efficiently manage and track your investments.  Automatically update your portfolio with real-time profit/loss updates, purging income, and Compliant status."
        />
        <meta
          name="tags"
          content="Portfolio Management, Compliant Investment, Profit Loss, Investment Tracking"
        />
        <meta
          name="keywords"
          content="Portfolio, Investment Tracking, Compliant Investment, Purging Income"
        />
      </Helmet>

      <div className="portfolio-container">
        <h1 className="Portfolio-title">My Portfolio</h1>{" "}
        {/* this is the main title page of the portflio */}
        <>
          {/* -----------------------All portfolio is a component which contains the combined value of holdinfd and sold portfolio ---------------------------------- */}
          <AllPortfolioValue
            totalInvestment={totalInvestment}
            totalMarketValue={totalMarketValue}
            totalProfitLoss={totalProfitLoss}
            totalSoldProfitLoss={totalSoldProfitLoss}
            totalPurgableHolding={totalPurgableHolding}
            totalPurgableSold={totalPurgableSold}
          />

          {/* ------------------------------------------------------------Buttons to naviagte and see the Holdingportfolio and sold portfolio -------------------------------------- */}
          <div className="flex justify-start items-center gap-2 mt-1 p-2 rounded-lg bg-gray-50 shadow-md">
            <button
              className={`px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300 ease-in-out transform ${
                activeTab === "Holding"
                  ? "bg-gradient-to-r from-green-500 to-green-700 text-white shadow-lg scale-105"
                  : "bg-gradient-to-r  from-gray-700 to-gray-600 text-white hover:bg-gradient-to-r hover:from-gray-400 hover:to-gray-500 hover:text-black"
              }`}
              onClick={() => setActiveTab("Holding")}
            >
              Holding Portfolio
            </button>
            <button
              className={`px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300 ease-in-out transform ${
                activeTab === "Sold"
                  ? "bg-gradient-to-r from-green-500 to-green-700 text-white shadow-lg scale-105"
                  : "bg-gradient-to-r from-gray-700 to-gray-600 text-white hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-500 hover:text-black"
              }`}
              onClick={() => setActiveTab("Sold")}
            >
              Sold Portfolio
            </button>
          </div>
          {/* -----------------------------------------------------If the Holding Button is active it will show the holding portfolio ------------------------ */}
          {activeTab === "Holding" && (
            <div className="Holding_portfolio_div">
              {portfolio && portfolio.length > 0 ? (
                <HoldingPortfolio
                  portfolio={portfolio}
                  editEntry={editEntry}
                  handleDelete={handleDelete}
                  handleSell={handleSell}
                  currentPrice={currentPrice}
                  companyName={companyName}
                  handleAddNew={handleAddNew}
                />
              ) : (
                <div className="welcome-container">
                  {/* if there will be noo added portfolio in the holding section then this message will eb shown with a button to add portfolio */}
                  <h4 className="welcome-title">
                    {" "}
                    Kickstart Your Financial Journey!
                  </h4>
                  <p className="welcome-description">
                    Dive into the world of stocks and watch your investments
                    grow. Why wait? Add your first stock now.
                  </p>
                  <button
                    className="initial-button px-4 py-2 text-sm font-semibold rounded-full transition-colors duration-300 ease-in-out transform bg-gradient-to-r from-green-500 to-green-700 text-white shadow-lg hover:scale-105"
                    onClick={handleAddNew}
                  >
                    📈 Add Your First Stock
                  </button>
                </div>
              )}
            </div>
          )}
          {/* -----------------------------------------------------funtion if the active button is sold then it will show th e sold portfolio table ----------- */}
          {activeTab === "Sold" && (
            <div className="portfolio_sold_div">
              {sold.length > 0 ? (
                <SoldPortfolioTable
                  sold={sold}
                  loading={loading}
                  deleteSoldEntry={deleteSoldEntry}
                />
              ) : (
                <div className="empty-soldoutstock-message">
                  {/* if there will be no sold portfolio then this messsage will be shown to the user  */}
                  <div className="empty-soldmessage-icon-div">
                    <GiEmptyHourglass className="icons_empty_soldportfolio" />
                  </div>
                  <div className="message-content">
                    Currently, there are no sold stocks to display in this
                    section. This area is specifically designed to showcase your
                    sold stock transactions. Once you sell any stocks, the
                    details of those transactions, including sale date,
                    quantity, and revenue, will be prominently displayed here.
                    This feature helps you track your past stock sales, analyze
                    your investment outcomes, and inform your future investment
                    strategies. Keep an eye on this space for updates on your
                    sold stocks, and ensure your portfolio is aligned with your
                    investment goals.
                  </div>
                </div>
              )}
            </div>
          )}
        </>
        {/* ------------------------------------------------------------------DILAOGUE TO ADD AND UPDATE PORTFOLIO ----------------------------------------------*/}
        <Dialog
          open={showForm}
          onClose={() => setShowForm(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
          className="font-sans"
        >
          <DialogTitle
            id="form-dialog-title"
            className="text-5xl font-bold text-center py-3 bg-gradient-to-r from-green-500 to-green-700 text-white shadow-md rounded-t-lg"
          >
            {editingIndex !== null ? "Update" : "Add to Portfolio"}
          </DialogTitle>

          <DialogContent className="p-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <label className="block text-lg font-semibold mb-0 text-gray-700">
                Stock Name:
                <PortfolioSearchBar
                  handleSelectResult={handleCompanySelect}
                  initialValue={editingIndex !== null ? companyName : ""}
                  containerClassName="mt-0.5"
                  inputClassName="w-full p-2 border border-gray-300 rounded-md"
                  placeholderClassName="text-gray-500"
                  searchIconClassName="text-gray-400"
                  searchIconContainerClassName="absolute inset-y-0 right-0 flex items-center pr-2 mt-0"
                  portfolio={portfolio}
                  showIcon={false}
                  autoFocus={true}
                />
              </label>
              {loading ? (
                <span>
                  <LoadingSpinner asOverlay />
                </span>
              ) : (
                <label className="block text-lg font-semibold text-gray-700">
                  Buy Price:
                  <input
                    type="number"
                    className="w-full p-2 border border-gray-300 rounded-md mt-1"
                    step="0.01"
                    value={companyPrice}
                    onChange={(e) => {
                      setCompanyPrice(e.target.value);
                    }}
                  />
                </label>
              )}
              <label className="block text-lg font-semibold text-gray-700">
                Quantity:
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </label>
              <label className="block text-lg font-semibold text-gray-700">
                Target Price (%):
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  step="0.01"
                  value={targetPrice}
                  onChange={(e) => setTargetPrice(e.target.value)}
                />
              </label>
              <label className="block text-lg font-semibold text-gray-700">
                Stop Loss (%):
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  step="0.01"
                  value={stopLoss}
                  onChange={(e) => setStopLoss(e.target.value)}
                />
              </label>
              {/* --------------------------------------------------Delted button inside the update funtion  for holding portfolio -------------------------------------- */}
              {editingIndex !== null && (
                <div className="flex justify-center items-center p-2 w-full">
                  <button
                    type="button"
                    className="w-full max-w-sm bg-gradient-to-r from-red-500 to-red-700 text-white py-2 rounded-lg font-semibold shadow-md transform transition duration-300 hover:bg-gradient-to-r hover:from-red-600 hover:to-red-800 flex items-center justify-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmDialog(true); // Open the confirmation dialog
                    }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faTrash} />
                    Delete
                  </button>
                </div>
              )}
            </form>
          </DialogContent>
          <DialogActions className="flex justify-between gap-2 p-3 bg-gray-200">
            <button
              className="w-full bg-gradient-to-r from-red-600 to-red-700 text-white py-2 rounded-lg font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-red-700 hover:to-red-800 hover:scale-105 flex items-center justify-center"
              onClick={() => setShowForm(false)}
              disabled={isSubmitting}
            >
              <AiOutlineCloseCircle className="mr-2 text-2xl" />
              Cancel
            </button>

            <button
              className="w-full bg-gradient-to-r from-green-600 to-green-700 text-white py-2 rounded-lg font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-green-700 hover:to-green-800 hover:scale-105 flex items-center justify-center"
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>Loading...</>
              ) : editingIndex !== null ? (
                <>Update</>
              ) : (
                <>+ Add to Portfolio</>
              )}
            </button>
          </DialogActions>
        </Dialog>
        {/* -----------------------------------------------------------the dialouge for the portfolio when user  click on selll --------------------------------------------------------- */}
        <Dialog
          open={sellFormIndex !== null}
          onClose={() => setSellFormIndex(null)}
          aria-labelledby="sell-form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            id="sell-form-dialog-title"
            className="text-4xl font-bold text-center py-3 bg-gradient-to-r from-red-600 to-red-800 text-white shadow-md rounded-t-lg"
          >
            Sell Your Stock
          </DialogTitle>
          <DialogContent className="p-4">
            <form onSubmit={sellEntry} className="space-y-4">
              <h3 className="text-center text-lg font-semibold text-gray-900">
                {selectedCompanyName}
              </h3>
              <label className="block text-lg font-semibold text-gray-700">
                Sold Price:
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  step="0.01"
                  value={sellPrice}
                  onChange={(e) => setSellPrice(e.target.value)}
                  autoFocus
                />
              </label>
              <label className="block text-lg font-semibold text-gray-700">
                Quantity to Sell:
                <input
                  type="number"
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  value={quantityToSell}
                  onChange={(e) => setQuantityToSell(e.target.value)}
                />
              </label>
            </form>
          </DialogContent>
          <DialogActions className="flex justify-between gap-2 p-3 bg-gray-100">
            <button
              onClick={() => setSellFormIndex(null)}
              disabled={isSelling}
              className="w-full bg-gradient-to-r from-red-600 to-red-700 text-white py-2 rounded-lg font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-red-700 hover:to-red-800 hover:scale-105 flex items-center justify-center"
            >
              <AiOutlineCloseCircle className="mr-2 text-2xl" />
              Cancel
            </button>

            <button
              type="submit"
              onClick={sellEntry}
              disabled={isSelling}
              className="w-full bg-gradient-to-r from-green-600 to-green-700 text-white py-2 rounded-lg font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-green-700 hover:to-green-800 hover:scale-105 flex items-center justify-center"
            >
              {isSelling ? <>Selling...</> : "Sell"}
            </button>
          </DialogActions>
        </Dialog>
      </div>
      {/* -----------------------------------------Dilog funtion wehn user click on delte button in the dilog of update portfolio funtion ----------------- */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        className="font-sans"
      >
        <DialogTitle className="text-2xl font-bold text-center py-2 bg-gradient-to-r from-red-600 to-red-800 text-white shadow-md rounded-t-lg">
          Confirm Deletion
        </DialogTitle>
        <DialogContent className="p-4 text-lg text-gray-800">
          Are you sure you want to delete this portfolio?
        </DialogContent>
        <DialogActions className="flex justify-between gap-4 p-4 bg-gray-100">
          <button
            onClick={() => setShowConfirmDialog(false)}
            className="w-full bg-gradient-to-r from-gray-600 to-gray-700 text-white py-2 rounded-full font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-gray-700 hover:to-gray-800 hover:scale-105 flex items-center justify-center"
          >
            <AiOutlineCloseCircle className="mr-2 text-2xl" />
            Cancel
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(editingIndex);
              setShowForm(false); // Close the form after deleting
              setShowConfirmDialog(false); // Close the confirmation dialog
            }}
            className="w-full bg-gradient-to-r from-red-600 to-red-700 text-white py-2 rounded-full font-semibold shadow-lg transform transition duration-300 hover:bg-gradient-to-r hover:from-red-700 hover:to-red-800 hover:scale-105 flex items-center justify-center"
          >
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Portfolio;
