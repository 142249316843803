import React, { useState } from "react";
import axios from "axios";
import SearchStockIdeas from "./SearchStockIdeas";
import { Modal, Box, Typography, Button, Backdrop, Fade } from "@mui/material";

const UpdateStockIdeaForm = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [formData, setFormData] = useState({
    Visibility: "",
    "Company Name": "",
    Symbol: "",
    Sector: "",
    "Target Price": 0,
    "Stop Loss": 0,
    "Holding Duration": "",
    CurrentPrice: 0,
    Message: "",
    "Shariah Status": "",
    Recommendation: "",
    recommendedPercentage: 0,
    "Buy Option": "",
    portfolioStrategy: "",
    IdeaReportLink: "",
    Date: "",
    "Recommendation Date": "",
  });

  const [selectedIdea, setSelectedIdea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null); // To store initial data
  const [isFormChanged, setIsFormChanged] = useState(false); // To track if the form has been edited

  const handleSelectIdea = (idea) => {
    const initialData = {
      Visibility: idea.Visibility,
      "Company Name": idea["Company Name"],
      Symbol: idea.Symbol,
      Sector: idea.Sector,
      "Target Price": idea["Target Price"],
      "Stop Loss": idea["Stop Loss"],
      "Holding Duration": idea["Holding Duration"],
      CurrentPrice: idea.CurrentPrice,
      Message: idea.Message,
      "Shariah Status": idea["Shariah Status"],
      Recommendation: idea.Recommendation,
      recommendedPercentage: idea.recommendedPercentage,
      "Buy Option": idea["Buy Option"],
      portfolioStrategy: idea.portfolioStrategy,
      IdeaReportLink: idea.IdeaReportLink,
      Date: idea.Date ? new Date(idea.Date).toISOString().split("T")[0] : "",
      "Recommendation Date": idea["Recommendation Date"]
        ? new Date(idea["Recommendation Date"]).toISOString().split("T")[0]
        : "",
    };

    setSelectedIdea(idea);
    setFormData(initialData);
    setInitialFormData(initialData); // Store initial form data
    setIsFormChanged(false); // Reset the form changed state
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };

      // Compare updated form data with initial data to detect changes
      const isChanged =
        JSON.stringify(updatedFormData) !== JSON.stringify(initialFormData);
      setIsFormChanged(isChanged);

      return updatedFormData;
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if the recommendation has been changed
    if (selectedIdea.Recommendation !== formData.Recommendation) {
      const todayDate = new Date().toISOString().split("T")[0];

      // Allow the recommendation date to remain the same if it's today's date
      if (
        !formData["Recommendation Date"] ||
        (formData["Recommendation Date"] !== todayDate &&
          formData["Recommendation Date"] ===
            new Date(selectedIdea["Recommendation Date"])
              .toISOString()
              .split("T")[0])
      ) {
        setResponseMessage(
          "Please Update the Recommendation Date as you have changed the Recommendation"
        );
        setIsModalOpen(true);
        setLoading(false);
        return; // Exit the function to prevent submission
      }
    }

    try {
      await axios.put(
        `${SERVER_URL}/admin/update-stock-idea/${selectedIdea._id}`,
        formData
      );
      setResponseMessage("Stock idea updated successfully");
      setIsModalOpen(true);
      handleClearSearch();
    } catch (error) {
      setResponseMessage("Failed to update stock idea");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClearSearch = () => {
    setSelectedIdea(null); // Close the form when search is cleared
  };

  return (
    <>
      <div className="p-2 font-bold">
        <h1 className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
          Update Recommendation
        </h1>
      </div>
      <div className="max-w-4xl mx-auto p-4 shadow-lg rounded">
        <SearchStockIdeas
          onSelectIdea={handleSelectIdea}
          onClearSearch={handleClearSearch}
        />
        <hr style={{ borderTop: "2px solid white" }}></hr>

        {selectedIdea && (
          <form onSubmit={handleUpdate} className="mt-4">
            <hr
              style={{ borderTop: "2px solid white", marginBottom: "20px" }}
            />
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Visibility
                </label>
                <select
                  name="Visibility"
                  value={formData.Visibility}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                  required
                >
                  <option value="QuickWin Starter">QuickWin Starter</option>
                  <option value="SmartHold Starter">SmartHold Starter</option>
                  <option value="SmartElite Starter">SmartElite Starter</option>
                </select>
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Company Name
                </label>
                <input
                  type="text"
                  name="Company Name"
                  value={formData["Company Name"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Symbol
                </label>
                <input
                  type="text"
                  name="Symbol"
                  value={formData.Symbol}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Sector
                </label>
                <input
                  type="text"
                  name="Sector"
                  value={formData.Sector}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Target Price
                </label>
                <input
                  type="number"
                  name="Target Price"
                  value={formData["Target Price"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Stop Loss
                </label>
                <input
                  type="number"
                  name="Stop Loss"
                  value={formData["Stop Loss"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Holding Duration
                </label>
                <input
                  type="text"
                  name="Holding Duration"
                  value={formData["Holding Duration"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Current Price
                </label>
                <input
                  type="number"
                  name="CurrentPrice"
                  value={formData.CurrentPrice}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Message
                </label>
                <textarea
                  name="Message"
                  value={formData.Message}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                ></textarea>
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Shariah Status
                </label>
                <select
                  name="Shariah Status"
                  value={formData["Shariah Status"]}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                  required
                >
                  <option value="Compliant">Compliant</option>
                  <option value="Non-Compliant">Non-Compliant</option>
                </select>
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Recommendation
                </label>
                <select
                  name="Recommendation"
                  value={formData.Recommendation}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black font-semibold"
                  required
                >
                  <option value="Buy" className="font-semibold">
                    Buy
                  </option>
                  <option value="Hold" className="font-semibold">
                    Hold
                  </option>
                  <option value="Sell" className="font-semibold">
                    Sell
                  </option>
                </select>
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Recommended Percentage
                </label>
                <input
                  type="number"
                  name="recommendedPercentage"
                  value={formData.recommendedPercentage}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  min="0"
                  max="100"
                  step="0.01" // Allows for decimal values
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Buy Option
                </label>
                <input
                  type="text"
                  name="Buy Option"
                  value={formData["Buy Option"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Portfolio Strategy
                </label>
                <select
                  name="portfolioStrategy"
                  value={formData.portfolioStrategy}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                  required
                >
                  <option value="BTSM">BTSM</option>
                  <option value="BTSF">BTSF</option>
                  <option value="BTSY">BTSY</option>
                  <option value="BTSFY">BTSFY</option>
                </select>
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Idea Report Link
                </label>
                <input
                  type="text"
                  name="IdeaReportLink"
                  value={formData.IdeaReportLink}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Stock Idea Post Date
                </label>
                <input
                  type="date"
                  name="Date"
                  value={formData.Date}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>

              <div className="w-full md:w-1/2 px-2 mb-4">
                <label className="block text-white text-sm font-bold mb-2">
                  Recommendation Date
                </label>
                <input
                  type="date"
                  name="Recommendation Date"
                  value={formData["Recommendation Date"]}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className={`bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold py-2 px-4 rounded-full shadow-lg transform transition-transform focus:outline-none focus:shadow-outline ${
                loading || !isFormChanged
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:from-green-500 hover:to-teal-600 hover:scale-105"
              }`}
              disabled={loading || !isFormChanged} // Disable button if no change
              style={{
                pointerEvents: loading || !isFormChanged ? "none" : "auto",
              }} // Disable pointer events when button is disabled
            >
              {loading ? "Updating..." : "Update Stock Idea"}
            </button>
          </form>
        )}

        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isModalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.default",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Typography
                id="modal-title"
                variant="h5"
                component="h2"
                sx={{ mb: 2 }}
              >
                {responseMessage.includes("successfully")
                  ? "🎉 Success!"
                  : "⚠️ Error"}
              </Typography>
              <Typography
                id="modal-description"
                sx={{ mt: 1, mb: 3, color: "text.secondary" }}
              >
                {responseMessage}
              </Typography>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                color={
                  responseMessage.includes("successfully") ? "success" : "error"
                }
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                OK
              </Button>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default UpdateStockIdeaForm;
