import React, { useState, useContext, useEffect } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { AuthContext } from "../../../Shared/context/auth-context";
import "./MarketViewEmailSwitch.css";
import IOSSwitch from '../../../Shared/UIElements/Switch/IOSSwitch';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

const MarketviewEmailSwitch = ({ user, SERVER_URL }) => {
  const auth = useContext(AuthContext);
  const [isSubscribedMarketview, setIsSubscribedMarketview] = useState(user.isSubscribedMarketview);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingUnsubscribe, setPendingUnsubscribe] = useState(false);

  // Effect to sync state with user prop changes
  useEffect(() => {
    setIsSubscribedMarketview(user.isSubscribedMarketview);
  }, [user.isSubscribedMarketview]);

  const handleToggleMarketviewSubscription = async () => {
    if (isSubscribedMarketview) {
      setPendingUnsubscribe(true);
      setOpenDialog(true);
      return;
    }

    updateSubscription(!isSubscribedMarketview);
  };

  const updateSubscription = async (updatedSubscriptionStatus) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.put(
        `${SERVER_URL}/auth/user/update`,
        {
          userId: user._id,
          isSubscribedMarketview: updatedSubscriptionStatus,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setIsSubscribedMarketview(updatedSubscriptionStatus);
        auth.login({ ...user, isSubscribedMarketview: updatedSubscriptionStatus }); // Update the user context
      }
    } catch (error) {
      console.error(error);
      setError('Failed to update subscription status. Please try again.');
    } finally {
      setIsLoading(false);
      setPendingUnsubscribe(false);
    }
  };

  const handleConfirmUnsubscribe = () => {
    setOpenDialog(false);
    updateSubscription(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="subscription-status-container__marketview">
      <h2 className="subscription-status-header__marketview">
        MarketView
      </h2>
      <div className="subscription-status-toggle__marketview">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={isSubscribedMarketview}
              onChange={handleToggleMarketviewSubscription}
              disabled={isLoading}
              sx={{ m: 1 }}
            />
          }
          label={isSubscribedMarketview ? 'ON' : 'OFF'}
        />
        {error && <Typography color="error">{error}</Typography>}
      </div>

      <Dialog
  open={openDialog}
  onClose={handleCloseDialog}
  className="bg-opacity-50"
  style={{ backdropFilter: "blur(10px)" }}
>
  <div className="bg-gradient-to-br from-gray-600 to-black text-white rounded-lg shadow-lg p-2">
  <DialogTitle className="text-white text-center py-1" style={{fontSize: "18px"}}>
  <NotificationImportantIcon style={{color:"yellow", fontSize: "22px"}}/>  Disable Subscription
</DialogTitle>

    <DialogContent className="text-white py-2">
      <Typography className="text-white py-2 " style={{fontSize: "14px"}}>
      Stop receiving email notification for MarketView ?
      </Typography>
    </DialogContent>
    <DialogActions className="py-2 flex justify-between rounded-b-lg">
    <button
  onClick={handleConfirmUnsubscribe}
  className="border border-red-500 text-white hover:bg-red-600 px-4 py-1 rounded-md transition duration-300"
  style={{fontSize: "14px"}}
  autoFocus
>
  Confirm
</button>
      <button
        onClick={handleCloseDialog}
        className="bg-green-600 text-white hover:bg-green-600 px-4 py-1 rounded-md transition duration-300"
        style={{fontSize: "14px"}}
      >
        Cancel
      </button>
    </DialogActions>
  </div>
</Dialog>



    </div>
  );
};

export default MarketviewEmailSwitch;
