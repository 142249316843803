import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PBratio.css";
import { Skeleton } from "@mui/material";

function PriceToBook({ symbol, bookValuePerShare, className }) {
  const [pbRatio, setPbRatio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const cacheKey = `pbRatio-${symbol}`;
    const fetchData = async () => {
      const now = new Date();
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const { expiry, value } = JSON.parse(cachedData);
        if (now.getTime() < expiry) {
          // Cache is valid
          setPbRatio(value);
          setLoading(false);
          return;
        }
      }

      try {
        const response = await axios.get(
          `${SERVER_URL}/screener/current-price/${symbol}`,
          {
            withCredentials: true,
          }
        );
        const currentPrice = response.data.price;
        if (currentPrice && bookValuePerShare) {
          const calculatedPbRatio = (currentPrice / bookValuePerShare).toFixed(
            2
          );
          setPbRatio(calculatedPbRatio);
          const expiry = now.getTime() + 1000 * 60 * 10; // 10 minutes from now
          localStorage.setItem(
            cacheKey,
            JSON.stringify({ value: calculatedPbRatio, expiry })
          );
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol, bookValuePerShare]);

  if (loading) {
    return <Skeleton variant="text" width={200} height={40} />;
  }

  if (error) {
    return <div>---</div>; // Display the error message
  }

  return (
    <>
      <h2 className={className}>{pbRatio} Times</h2>
    </>
  );
}

export default PriceToBook;
