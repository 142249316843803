import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaUser,
  FaTachometerAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaLightbulb,
} from "react-icons/fa";
import BusinessIcon from '@mui/icons-material/Business';
import UserList from "../Components/Users/UserList";
import AdminDashboard from "../Components/AminDashboard/AdminDashboard";
import MainStockIdea from "../Components/StockIdea/Pages/MainStockIdea";
import MainCompanyPage from "../Components/Company/pages/MainCompanyPage";
import MainAdminIpo from "../Components/Ipo/pages/MainAdminIpo";
import AdminMarketView from "../Components/MarketView/pages/AdminMarketView";
import MainResearchPage from "../Components/Researchreport/page/MainResearchPage";
import IpoLogo from "../../images/ipo.jpg"
import ArticleIcon from '@mui/icons-material/Article';
import MarkettrendLogo from "../../images/market-trend.webp"
import { AuthContext } from "../../Shared/context/auth-context";
import "./AdminPanel.css";

const AdminPanel = () => {
  const { user, isLoading } = useContext(AuthContext);
  const [activeComponent, setActiveComponent] = useState("AdminDashboard");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!isLoading && (!user || (!user.isAdmin && !user.isSubAdmin))) {
      setShowError(true);
    } else if (!isLoading) {
      // Set the default active component based on the user's role
      if (user.isAdmin) {
        setActiveComponent("AdminDashboard");
      } else if (user.isSubAdmin) {
        switch (user.role) {
          case "Users":
            setActiveComponent("UserList");
            break;
          case "StockIdeas":
            setActiveComponent("StockIdea");
            break;
          case "Companies":
            setActiveComponent("Company");
            break;
            case "Ipo":
              setActiveComponent("Ipo");
              break;   
               case "MarketView":
              setActiveComponent("MarketView"); 
                case "ResearchView":
              setActiveComponent("ResearchView");
              break;
          default:
            setActiveComponent("AdminDashboard");
            break;
        }
      }
    }
  }, [user, isLoading]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 px-4">
        <h2 className="text-4xl font-semibold mb-2 text-gray-800">
          Loading...
        </h2>
      </div>
    );
  }

  if (showError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 px-4">
        <h1 className="text-7xl font-extrabold text-red-600 mb-4" aria-label="404 Error">
          404
        </h1>
        <h2 className="text-4xl font-semibold mb-2 text-gray-800">Page Not Found</h2>
        <p className="text-md mb-4 text-center text-gray-600">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="bg-blue-600 text-white py-2 px-4 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300 transform hover:scale-105"
          aria-label="Go to Home"
        >
          Go to Home
        </Link>
      </div>
    );
  }

  const renderComponent = () => {
    switch (activeComponent) {
      case "UserList":
        return <UserList />;
      case "AdminDashboard":
        return <AdminDashboard />;
      case "StockIdea":
        return <MainStockIdea />;
      case "Company":
        return <MainCompanyPage />;   
           case "Ipo":
        return <MainAdminIpo />; 
             case "MarketView":
        return <AdminMarketView />;  
             case "ResearchView":
        return <MainResearchPage />;
      default:
        return <div>Please select an option from the left menu</div>;
    }
  };

  return (
    <div className="adminpanel__container">
      {/* Side Drawer */}
      <div className={`adminpanel__sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <div className="adminpanel__sidebar-header">
          <h2 className={`${isCollapsed ? "hidden" : "inline"}`}>
            Admin Panel
          </h2>
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="toggle-button"
          >
            {isCollapsed ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
          </button>
        </div>

        {/* Show the options based on the role */}
        {user.isAdmin || user.role === "Users" ? (
          <button
            onClick={() => setActiveComponent("UserList")}
            className={`adminpanel__button ${
              activeComponent === "UserList" ? "active" : ""
            }`}
          >
            <FaUser className="mr-2" />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Users
            </span>
          </button>
        ) : null}

        {user.isAdmin || user.role === "StockIdeas" ? (
          <button
            onClick={() => setActiveComponent("StockIdea")}
            className={`adminpanel__button ${
              activeComponent === "StockIdea" ? "active" : ""
            }`}
          >
            <FaLightbulb className="mr-2" />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Stock Idea
            </span>
          </button>
        ) : null}

        {user.isAdmin || user.role === "Companies" ? (
          <button
            onClick={() => setActiveComponent("Company")}
            className={`adminpanel__button ${
              activeComponent === "Company" ? "active" : ""
            }`}
          >
            <BusinessIcon className="mr-2" />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Company
            </span>
          </button>
        ) : null} 
        
               {user.isAdmin || user.role === "Ipo" ? (
          <button
            onClick={() => setActiveComponent("Ipo")}
            className={`adminpanel__button ${
              activeComponent === "Ipo" ? "active" : ""
            }`}
          >
           <img src={IpoLogo} alt="IPO Logo" className="mr-2" style={{ width: '24px', height: '24px' }} />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Ipo
            </span>
          </button>
        ) : null} 
        
              {user.isAdmin || user.role === "MarketView" ? (
          <button
            onClick={() => setActiveComponent("MarketView")}
            className={`adminpanel__button ${
              activeComponent === "MarketView" ? "active" : ""
            }`}
          >
            <img src={MarkettrendLogo} alt="Market trend Logo" className="mr-2" style={{ width: '24px', height: '24px' }} />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Market View
            </span>
          </button>
        ) : null}  
        
                {user.isAdmin || user.role === "ResearchView" ? (
          <button
            onClick={() => setActiveComponent("ResearchView")}
            className={`adminpanel__button ${
              activeComponent === "ResearchView" ? "active" : ""
            }`}
          >
            <ArticleIcon className="mr-2" />
            <span className={`${isCollapsed ? "hidden" : "inline"}`}>
              Research Report
            </span>
          </button>
        ) : null}
      </div>

      {/* Content Dashboard */}
      <div className="adminpanel__main">{renderComponent()}</div>
    </div>
  );
};

export default AdminPanel;

