import React from 'react';
import GaugeChart from 'react-gauge-chart';
import "./TotalLoanIpo.css";

function TotalLoanIpo({ loanToMarketCap }) {
  const totalLoanPercentage = loanToMarketCap * 100;


  return (
    <div className="flex flex-col md:flex-row items-center p-2 rounded-lg border border-gray-200">
      <div className="flex flex-col items-start w-full md:w-1/2 p-4 md:p-9">
        <div className='text-center p-2 w-full'>
          <h3 className='text-lg md:text-xl font-semibold text-gray-800 mb-4 md:mb-6'>TOTAL LOAN TO AVERAGE MARKET CAPITALISATION</h3>
        </div>
        <div className="flex flex-col items-center border border-gray-200 p-3 w-full rounded-lg">
          <h3 className='text-md md:text-xl font-semibold text-gray-800 text-center'>Total Loan</h3>
          <p className='text-sm md:text-lg text-red-600 text-center'>{totalLoanPercentage.toFixed(2)}%</p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full md:w-1/2 p-4">
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={3}
          colors={["green", "orange", "red"]}
          arcWidth={0.3}
          percent={loanToMarketCap}
          textColor={'black'}
          // hideText={true} // Uncomment if you want to hide the text
        />
      </div>
    </div>
  );
}

export default TotalLoanIpo;
