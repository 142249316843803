import React, { useState, useEffect, useContext } from "react";
import "./MainNavigation.css";
import MainHeader from "./MainHeader";
import NavLinks from "./NavLink";
import SideDrawer from "./SideDrawer";
import Modal from "../UIElements/Modal/Modal";
import logo from "../../images/only logo.png";
import name from "../../images/name.png";
import { AuthContext } from "../context/auth-context";
import { NavLink, useHistory, Link } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import { FaBriefcase } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FaHome, FaSearch, FaFileAlt,FaMoneyBillWave } from "react-icons/fa";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MenuIcon from "@mui/icons-material/Menu";
import "tailwindcss/tailwind.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const MainNavigation = () => {
  const { user } = useContext(AuthContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [isLogoutSuccess, setIsLogoutSuccess] = useState(false);

  const openDrawerHandler = () => setDrawerIsOpen(true);
  const closeDrawerHandler = () => setDrawerIsOpen(false);
  const handleOpenLogoutDialog = () => setIsLogoutDialogOpen(true);
const handleCloseLogoutDialog = () => setIsLogoutDialogOpen(false);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Add resize event listener
    return () => window.removeEventListener("resize", handleResize); // Cleanup on component unmount
  }, []);

  // const handleLogout = () => {
  //   auth.logout();
  //   history.push("/auth");
  // };
  const handleConfirmLogout = () => {
    auth.logout();
    setIsLogoutSuccess(true);
    setIsLogoutDialogOpen(false);
    setTimeout(() => {
      setIsLogoutSuccess(false);
      history.push("/auth");
    }, 3000); // Show success message for 3 seconds
  };

  return (
    <>
      {drawerIsOpen && <Modal onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClose={closeDrawerHandler}>
        <nav className=" z-40 main-navigation__drawer-nav">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt="Logo"
              className="logo-image"
              style={{ marginRight: "10px" }}
            />
            <h6 className="" style={{ margin: 0 }}>
              FINSHA
            </h6>
          </div>

          <ul>
            {auth.isLoggedIn && isMobileView ? (
              <div className="sidedrawer_loggedin_container">
                <div className="flex flex-col">
                <div className="avatar__sidedrawer ">
                  <UserAvatar size={70} />
                  </div>
                  <div className="drawer_user_name">
                    <h6 className="user__fname_and_lname">
                      {/* {user.fname} {user.lname} */}
                      {user && user.fname
                        ? `${user.fname} ${user.lname}`
                        : "User"}
                    </h6>
                  </div>
                </div>
                <hr className="horizontalline_under_avatar" />

                <div className="LoggedIn_sidedrawerNavlinks flex flex-col">
                  <li className="drawer_loggedin__list">
                    <NavLink
                    exact
                      className=""
                      to="/"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler} 
                    >
                      <FaHome className="mr-2 inline-flex" /> <span>Home</span> 
                    </NavLink>
                  </li>

                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/screener"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaSearch className="mr-2 inline-flex" /> Screener
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/finview"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaFileAlt className="mr-2 inline-flex" /> FinView
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/pricing"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaMoneyBillWave className="mr-2 inline-flex" /> Pricing
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/user-portfolio"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaBriefcase className="mr-2 inline-flex" /> Portfolio
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/stock-idea"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaLightbulb className="mr-2 inline-flex" /> StockIdea
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/watchlist"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaEye className="mr-2 inline-flex" /> WatchList
                    </NavLink>
                  </li>
                  <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/user-profile"
                      activeClassName="active-link"
                      onClick={closeDrawerHandler}
                    >
                      <FaUser className="mr-2 inline-flex" /> Accounts
                    </NavLink>
                  </li>
                  {/* <li className="drawer_loggedin__list">
                    <NavLink
                      exact
                      to="/auth"
                      activeClassName="active-link"
                      onClick={handleLogout}
                      style={{ color: "red" }}
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        style={{ marginRight: "0.5rem" }}
                      />{" "}
                      Logout
                    </NavLink>
                  </li> */}
                  <li className="drawer_loggedin__list">
  <button
    onClick={handleOpenLogoutDialog}
    style={{ color: "red", background: "none", border: "none", cursor: "pointer" }}
  >
    <FontAwesomeIcon
      icon={faSignOutAlt}
      style={{ marginRight: "0.5rem" }}
    />{" "}
    Logout
  </button>
</li>

                </div>
              </div>
            ) : isMobileView ? (
              <div className="sidedrawer_notloggedin_container">
                <li className="drawer_notloggedin__list">
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active-link"
                    onClick={closeDrawerHandler}
                  >
                    <FaHome  className="mr-2 inline-flex" /> Home
                  </NavLink>
                </li>
                <li className="drawer_notloggedin__list">
                  <NavLink
                    exact
                    to="/screener"
                    activeClassName="active-link"
                    onClick={closeDrawerHandler}
                  >
                    <FaSearch className="mr-2 inline-flex" /> Screener
                  </NavLink>
                </li>
                <li className="drawer_notloggedin__list">
                  <NavLink
                    exact
                    to="/finview"
                    activeClassName="active-link"
                    onClick={closeDrawerHandler}
                  >
                    <FaFileAlt className="mr-2 inline-flex" /> FinView
                  </NavLink>
                </li>
                <li className="drawer_notloggedin__list">
                  <NavLink
                    exact
                    to="/pricing"
                    activeClassName="active-link"
                    onClick={closeDrawerHandler}
                  >
                    <FaMoneyBillWave className="mr-2 inline-flex" /> Pricing
                  </NavLink>
                </li>
                <li className="drawer_notloggedin__list">
                  <NavLink
                    exact
                    to="/auth"
                    activeClassName="active-link"
                    onClick={closeDrawerHandler}
                  >
                    <LockOpenIcon
                      style={{ marginRight: 10, color: "white", fontSize: 22 }}
                    />{" "}
                    Login
                  </NavLink>
                </li>
              </div>
            ) : null}
          </ul>

          {/* <NavLinks closeDrawer={closeDrawerHandler}> */}

          {/* </NavLinks> */}
        </nav>
      </SideDrawer>

      <MainHeader>
        {isMobileView && (
          <button
            className="main-navigation__menu-btn"
            onClick={openDrawerHandler}
          >
            <MenuIcon style={{ fontSize: 50 }} />
          </button>
        )}
        <div className="main-navigation__title md:flex">
          <Link to="/"  className="flex items-center">
            <img src={logo} alt="Logo" className="h-12 w-auto mr-2 inline"  />
            <img src={name} alt="Name" className="h-8 w-auto inline md:hidden lg:inline" />
          </Link>
        </div>
        {!isMobileView && (
          <div className="right-section">
            <nav className="main-navigation__header-nav">
              <NavLinks />
            </nav>
          </div>
        )}
      </MainHeader>
      <Dialog
  open={isLogoutDialogOpen}
  onClose={handleCloseLogoutDialog}
  aria-labelledby="logout-confirmation-title"
  aria-describedby="logout-confirmation-description"
  PaperProps={{
    className: "bg-gradient-to-b from-slate-700 to-slate-700 rounded-lg bg-opacity-50",
    style: { width: '20rem', height: '7em', backdropFilter: "blur(10px)" }
  }}

>
  <DialogTitle
    id="logout-confirmation-title"
    className="text-white font-bold p-1 mt-1"
    style={{ textAlign: "center", fontSize: "16px" }}
  >
    Confirm Logout
  </DialogTitle>
  <div>
    <DialogContentText
      id="logout-confirmation-description"
      className=" text-white w-full p-2 m-0"
      style={{ textAlign: "center", fontSize:"14px" }}
    >
      Do you want to log out?
    </DialogContentText>
  </div>
  <div className="flex mr-10 ml-10 mt-1" style={{justifyContent:"space-around"}}>
  <button
      onClick={handleConfirmLogout}
      style={{
        marginRight: "0.5rem",
        borderColor: "white",
        color: "white",
        background: "none",
        borderRadius: "7px",
       padding: "2px 10px",
        cursor: "pointer",
        fontSize:"14px"
      }}
    >
      Confirm
    </button>
    <button
      onClick={handleCloseLogoutDialog}
      style={{
        background: "linear-gradient(to right, #38a169, #2f855a)",
        color: "white",
        fontSize:"14px",
        borderRadius: "7px",
        padding: "2px 10px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        cursor: "pointer"
      }}
    >
      Cancel
    </button>
  </div>
</Dialog>

<Dialog
  open={isLogoutSuccess}
  onClose={() => setIsLogoutSuccess(false)}
  aria-labelledby="logout-success-title"
  aria-describedby="logout-success-description"
  PaperProps={{
    className: "bg-gradient-to-b from-slate-700 to-slate-700 rounded-lg",
    style: { Width: '100px', Height: '250px' }
  }}
>
  <DialogContent
    className="flex flex-col items-center justify-center"
  >
    <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
    <DialogContentText
      id="logout-success-description"
      className="mt-2 text-white text-center p-2" style={{fontSize:"12px"}}
    >
      You have been logged out successfully.
    </DialogContentText>
  </DialogContent>
</Dialog>

    </>
  );
};

export default MainNavigation;
