import React from 'react'
import { Skeleton } from "@mui/material";
import Grid from '@mui/material/Grid';
import "./stockIdeaSkeleton.css"

function StockIdeaSkeleton() {
  return (
    <>
          <div className="stock-ideas-container">
          <h1 className="stock-ideas-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Skeleton variant="text" width="40%" height="4rem" />
</h1>

<Skeleton variant="text" width="100%" height="4rem" />

            {[...Array(6)].map((_, idx1) => (
                <div key={idx1}>
                    <Grid container spacing={3}>
                        {[...Array(3)].map((_, idx2) => (
                            <Grid item xs={12} sm={6} md={4} key={idx2}>
                                <div className="stock-card">
                                    <div className="stock-card-content">
                                        <div className="stock-header">
                                            <Skeleton variant="text" width="60%" height="1.5rem" />
                                            <Skeleton variant="text" width="40%" height="1.2rem" style={{ marginTop: '0.5rem' }} />
                                        </div>
                                        <div className="shariah-div">
                                            <Skeleton variant="text" width="30%" height="1.2rem" />
                                        </div>
                                        <div className="stock-current-price">
                                            <Skeleton variant="text" width="40%" height="1.5rem" style={{ marginRight: '0.5rem' }} />
                                            <Skeleton variant="text" width="30%" height="1.2rem" />
                                        </div>
                                        <div className="stock-recomendation-buy-title">
                                            <Skeleton variant="text" width="40%" height="1.2rem" style={{ marginRight: '1rem' }} />
                                            <Skeleton variant="text" width="30%" height="1.2rem" />
                                        </div>
                                        <div className="stock-recomendation-buy-data">
                                            <Skeleton variant="text" width="40%" height="1.2rem" style={{ marginRight: '1rem' }} />
                                            <Skeleton variant="text" width="30%" height="1.2rem" />
                                        </div>
                                        <div className="second-row">
                                            <div className="stock-details">
                                                <Skeleton variant="text" width="30%" height="1.2rem" style={{ marginRight: '0.5rem' }} />
                                                <Skeleton variant="text" width="30%" height="1.2rem" style={{ marginRight: '0.5rem' }} />
                                                <Skeleton variant="text" width="30%" height="1.2rem" />
                                            </div>
                                            <div className="stock-details-data">
                                                <Skeleton variant="text" width="30%" height="1.2rem" style={{ marginRight: '0.5rem' }} />
                                                <Skeleton variant="text" width="30%" height="1.2rem" style={{ marginRight: '0.5rem' }} />
                                                <Skeleton variant="text" width="30%" height="1.2rem" />
                                            </div>
                                        </div>
                                        <p className="stock-message">
                                            <Skeleton variant="text" width="90%" height="1.5rem" />
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </div>
    </>
  )
}

export default StockIdeaSkeleton