// eslint-disable-next-line

import React from "react";
import { Link } from "react-router-dom";
import name from "../images/name.png";

function Footer() {
  return (
    <footer
      itemScope
      itemType="https://schema.org/WPFooter"
      className="font-roboto w-full bg-slate-700 text-slate-400 py-10 px-1 box-border pb-1"
    >
      <div className=" max-lg:max-w-screen-md max-w-screen-lg mx-auto grid grid-cols-2 gap-10 max-md:gap-8 sm:grid-cols-2 lg:grid-cols-4 max-sm:pl-[5px] max-lg:pl-[12px]">
        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-bold text-slate-200"><img src={name} alt="name" className="h-7 bg-slate-100 rounded-2xl shadow-2xl shadow-slate-600-100"></img></h3>
          <ul className="space-y-2 cursor-pointer text-green-200">
            <li className="font-semibold ">SEBI Registered RA</li>
            <li>FINSHA RESEARCH SERVICES</li>
            <li>Registration No: INH000014270</li>
          </ul>
          <ul className="flex space-x-4 text-xl  max-sm:pl-4">
            <li>
              <a
                href="https://www.facebook.com/help.finsha"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook text-white"></i>
              </a>
            </li>
            <li className="">
              <a
                href="https://twitter.com/finsha_rs"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 248 204"
                  fill="currentColor"
                  className="w-6 h-6 text-white "
                >
                  <path d="M203.2 0L124 90.3 44.8 0H0l123.7 104.9L1.2 204h47.4l75.3-80.4L199.3 204h47.5L124.4 105.1 248 0z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/in/finsha"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin text-white "></i>
              </a>
            </li>
            <li>
              <a
                href="https://t.me/+a0WustyIyls4OTE1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-telegram text-white"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@finsha_r"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube text-white "></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-bold text-slate-200">Contact</h3>
          <ul className="space-y-2">
            <li>
              <a href="tel:+916002511778" className="hover:text-green-200">
                +91-6002511778
              </a>
            </li>
            <li>
              <a
                href="mailto:support@finsha.in"
                className="hover:text-green-200"
              >
                support@finsha.in
              </a>
            </li>
            <li className="cursor-pointer">
              Sarbanandapur, <br />
              Assam-788163
            </li>
          </ul>
        </div>

        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-bold text-slate-200">About Finsha</h3>
          <ul className="space-y-2">
            <li>
              <Link className="hover:text-green-200" to="/about">
                About Us
              </Link>
            </li>
            <li>
              <Link className="hover:text-green-200" to="/disclaimer">
                Disclaimer
              </Link>
            </li>
            <li>
              <Link className="hover:text-green-200" to="/methodology">
                Our Methodology
              </Link>
            </li>
            <li>
              <Link className="hover:text-green-200" to="/QandA">
                Q&A
              </Link>
            </li>
          </ul>
        </div>

     

        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-bold text-slate-200">Legal Info</h3>
          <ul className="space-y-2">
            <li>
              <Link className="hover:text-green-200" to="/investorsportal">
                Investors Grievances Portal
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-green-200"
                to="/policy-to-address-conflict"
              >
                Policies to address conflicts
              </Link>
            </li>
            <li>
              <Link className="hover:text-green-200" to="/terms&condition">
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link className="hover:text-green-200" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-6">
        <p className="text-center text-gray-500">
          &copy; Finsha {new Date().getFullYear()} - All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
