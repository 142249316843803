import React from 'react';
import GaugeChart from 'react-gauge-chart';
import "./TotalLoanChart.css"

function TotalLoanChart({ data }) {
  const percentage = data.datasets[0].data[0]; 
  const chartProps = {
    nrOfLevels: 30, // Adjust this as needed
    colors: ["green", "orange", "red"], // Adjust the colors as needed
    arcWidth: 0.3, // Adjust the arc width as needed
    percent: percentage/100, // Ensure this is a value between 0 and 1
    textColor: "black",
    fontSize:"24px",
  };
  
  return (

      <div className="totalloan_chart-and-data-container">
        <div className="total_loan_chart">
          <GaugeChart 
            id="gauge-chart"
            {...chartProps} 
          />
        </div>
        <div className="total_loan_data-section">
          <h6 className='total_loan_percentage_title'>Total Loan To Avg. Market Cap.</h6>
          <p className='totalLoan_percentage_data'>{percentage.toFixed(2)}%</p>
        </div>
      </div>
  );
}

export default TotalLoanChart;
