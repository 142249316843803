import React, { useState, useCallback } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
import { Modal, Box, Typography, Button } from "@mui/material";

const TargetStopLossUpdate = ({ onClearSearch }) => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [editableIdea, setEditableIdea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value.length > 2) {
        try {
          const response = await axios.get(
            `${SERVER_URL}/admin/search-stock-ideas`,
            {
              params: { companyName: value },
            }
          );
          setSearchResults(response.data);
        } catch (error) {}
      } else {
        setSearchResults([]);
        onClearSearch(); // Clear the form if the search bar is cleared
      }
    }, 300),
    [] // Only create the debounce function once
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value); // Pass the value to the debounced search function
  };

  const handleSelect = (idea) => {
    setSelectedIdea(idea);
    setEditableIdea({ ...idea });
    setSearchTerm(`${idea["Company Name"]} (${idea.Visibility})`);
    setSearchResults([]); // Clear the dropdown after selection
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditableIdea((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (loading) {
      return; // Prevent multiple saves while loading
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${SERVER_URL}/admin/target-stoploss-update`,
        {
          ideaId: editableIdea._id,
          updatedIdeaData: editableIdea,
        }
      );

      // Close the editable section
      setSelectedIdea(null);
      setEditableIdea(null);

      // Show success modal
      setSuccessModalOpen(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setSuccessModalOpen(false);
  };

  return (
    <>
      <div className="p-2 font-bold">
        <h1 className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
          Trailing Stock Update
        </h1>
      </div>
      <div className="relative p-2 bg-gray-900 text-white rounded-lg shadow-lg mt-4">
        <input
          type="text"
          placeholder="Search for the available stock idea to update"
          value={searchTerm}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-700 rounded-lg bg-gray-800 text-white focus:outline-none focus:border-gray-600"
        />
        {searchResults.length > 0 && (
          <ul className="absolute z-10 w-full mt-2 bg-gray-800 border border-gray-700 rounded-lg shadow-lg text-white">
            {searchResults.map((idea) => (
              <li
                key={idea._id}
                className="p-3 cursor-pointer hover:bg-gray-700 flex justify-between"
                onClick={() => handleSelect(idea)}
              >
                <span>{idea["Company Name"]}</span>
                <span className="text-gray-400">{idea.Visibility}</span>
              </li>
            ))}
          </ul>
        )}

        {editableIdea && (
          <div className="mt-6 p-3 border border-gray-700 rounded-lg bg-gray-800">
            <h3 className="text-xl font-bold mb-4">Edit Stock Idea Details</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Company Name
              </label>
              <input
                type="text"
                name="Company Name"
                value={editableIdea["Company Name"]}
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
                disabled
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Target Price
              </label>
              <input
                type="number"
                name="Target Price"
                value={editableIdea["Target Price"]}
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Stop Loss
              </label>
              <input
                type="number"
                name="Stop Loss"
                value={editableIdea["Stop Loss"]}
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Message
              </label>
              <textarea
                name="Message"
                value={editableIdea["Message"]}
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Holding Duration
              </label>
              <input
                type="text"
                name="Holding Duration"
                value={editableIdea["Holding Duration"]}
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Recommendation Date
              </label>
              <input
                type="date"
                name="Recommendation Date"
                value={
                  new Date(editableIdea["Recommendation Date"])
                    .toISOString()
                    .split("T")[0]
                }
                onChange={handleFieldChange}
                className="w-full p-2 border border-gray-700 rounded-lg bg-gray-900 text-white focus:outline-none focus:border-gray-600"
              />
            </div>
            <button
              onClick={handleSave}
              className={`w-full py-3 rounded-lg bg-green-500 hover:bg-green-600 text-white focus:outline-none`}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        )}

        <Modal
          open={successModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#1e1e1e",
              color: "white",
              border: "2px solid #333",
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
            }}
          >
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "#4caf50" }}
            >
              Success
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Stock idea updated successfully!
            </Typography>
            <Button
              onClick={handleModalClose}
              sx={{
                mt: 3,
                bgcolor: "#4caf50",
                "&:hover": {
                  bgcolor: "#388e3c",
                },
                color: "white",
                width: "100%",
              }}
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default TargetStopLossUpdate;
