import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from "react-transition-group";
import "./SideDrawer.css";

const SideDrawer = props => {
  const drawerClasses = 'side-drawer';

  useEffect(() => {
    const closeDrawer = event => {
      if (props.show && !event.target.closest('.side-drawer')) {
        props.onClose();
      }
    };

    document.addEventListener('mousedown', closeDrawer);
    return () => document.removeEventListener('mousedown', closeDrawer);
  }, [props.show]);

  const content = (
    <CSSTransition 
      in={props.show} 
      timeout={200} 
      classNames="slide-in-left" 
      mountOnEnter 
      unmountOnExit
    >
      <aside className={drawerClasses} onClick={e => e.stopPropagation()}>
        <nav onClick={props.onClose}>
        {props.children}
        </nav>
      </aside>
    </CSSTransition>
  );

  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;



