import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "./UnSubscribeEmail.css";
import axios from "axios";

function UnSubscribeResearchViewEmail() {
  const { token } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState("processing");
  const [message, setMessage] = useState("Processing your request...");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // useEffect(() => {
  //   fetch(`${SERVER_URL}/finview/unsubscribe/report/${token}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         setStatus("success");
  //         setMessage(
  //           `You have been successfully unsubscribed from Research View emails notification \n\nNote: To enable the Notification again, Click on the 'Manage Subscription' under 'Account' section of your profile and Switch on the Notification button for the ResearchView.`
  //         );
  //       } else {
  //         setStatus("error");
  //         setMessage("Unsubscription failed. Please try again later.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error during unsubscription:", error);
  //       setStatus("error");
  //       setMessage("An error occurred. Please try again later.");
  //     });
  // }, [token]);

  //replace fetch with axios
  useEffect(() => {
    axios
      .get(`${SERVER_URL}/finview/unsubscribe/report/${token}`)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setStatus("success");
          setMessage(
            `You have been successfully unsubscribed from Research View email notifications.\n\nNote: To enable the Notification again, Click on the 'Manage Subscription' under 'Account' section of your profile and Switch on the Notification button for the ResearchView.`
          );
        } else {
          setStatus("error");
          setMessage("Unsubscription failed. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error during unsubscription:", error);
        setStatus("error");
        setMessage("An error occurred. Please try again later.");
      });
  }, [token]);

  const renderIcon = () => {
    if (status === "processing") {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "success") {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "error") {
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size="4x"
          className="icon-red"
        />
      );
    }
  };

  const renderTitle = () => {
    if (status === "success") {
      return "Successfully Unsubscribed";
    } else if (status === "error") {
      return "Failed to Unsubscribe";
    } else {
      return "Unsubscribe";
    }
  };

  const handleButtonClick = () => {
    history.push("/");
  };

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-box">
        {renderIcon()}
        <h1 className="unsubscibe__title">{renderTitle()}</h1>
        <div className="message-container">
          {message.split("\n").map((line, index) => (
            <p
              key={index}
              style={{ textAlign: index >= 2 ? "left" : "center" }}
            >
              {line}
            </p>
          ))}
        </div>
        <div>
          <button className="home-button" onClick={handleButtonClick}>
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnSubscribeResearchViewEmail;
