import React from 'react';

import './FinancialCard.css';

const FinancialCard = props => {
  return (
    <div className={`Financialcard ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default FinancialCard;
