import React, { useState } from "react";
import axios from "axios";
import EditUserModal from "./EditUserModal";
import { Modal, Box, Typography } from "@mui/material";
import "./UserList.css";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); // Track the search results
  const [noResults, setNoResults] = useState(false); // Track if there are no results

  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSearch = () => {
    axios
      .post(`${SERVER_URL}/admin/users/search`, { query: searchQuery })
      .then((response) => {
        setSearchResults(response.data);
        setNoResults(response.data.length === 0); // Check if no results
      })
      .catch((error) => {
        console.error("There was an error searching the users!", error);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${SERVER_URL}/admin/users`, {
        data: { userId: selectedUser._id },
      })
      .then(() => {
        setUsers(users.filter((user) => user._id !== selectedUser._id));
        setIsDeleteConfirm(false);
        setIsDeleteSuccess(true); // Show success message
        setTimeout(() => {
          setIsDeleteSuccess(false); // Hide success message after 3 seconds
        }, 3000);
      })
      .catch((error) => {
        console.error("There was an error deleting the user!", error);
      });
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setIsEditModalOpen(false);
    setIsDeleteConfirm(false); // Close delete confirmation if open
  };

  const handleUpdateUser = (updatedUser) => {
    setUsers(
      users.map((user) => (user._id === updatedUser._id ? updatedUser : user))
    );
  };

  const handleCardClick = (user) => {
    setSelectedUser(user);
    setSearchQuery("");
    setSearchResults([]);
  };

  return (
    <div className="flex flex-col p-6 bg-gradient-to-b from-gray-900 via-gray-800 to-black min-h-screen">
      <h2 className="text-left mb-4 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 tracking-wider drop-shadow-lg">
        User's Dashboard
      </h2>

      <div style={{ position: "relative", width: "100%" }}>
        <input
          type="text"
          placeholder="Search Users"
          style={{
            width: "90%" /* Slightly smaller width */,
            padding: "0.75rem" /* Increased height */,
            border: "1px solid #4b5563" /* Darker border color */,
            borderRadius: "50px" /* More rounded corners */,
            backgroundColor: "#1f2937" /* Dark background for input */,
            color: "#ffffff" /* White text color */,
            fontSize: "1rem",
            outline: "none",
            transition: "border-color 0.3s ease",
            display: "block",
            margin: "0 auto" /* Center the input field */,
          }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            handleSearch(); // Trigger search on input change
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        {searchQuery && searchResults.length > 0 && (
          <div
            style={{
              position: "absolute",
              width: "90%" /* Matches the input width */,
              backgroundColor: "#2d3748" /* Dark background for dropdown */,
              border: "1px solid #4b5563" /* Matching border for dropdown */,
              borderRadius: "12px" /* Slightly rounded dropdown */,
              marginTop: "0.25rem",
              maxHeight: "12rem",
              overflowY: "auto",
              zIndex: 10,
              left: "50%",
              transform: "translateX(-50%)" /* Center align dropdown */,
            }}
          >
            {searchResults.map((user) => (
              <div
                key={user._id}
                style={{
                  padding: "0.75rem",
                  cursor: "pointer",
                  color: "#ffffff" /* White text color */,
                  backgroundColor:
                    "#2d3748" /* Dark background for dropdown items */,
                  transition: "background-color 0.3s ease, color 0.3s ease",
                }}
                onClick={() => {
                  handleCardClick(user);
                  setSearchQuery(""); // Clear the search after selecting a user
                  setSearchResults([]); // Clear the suggestions
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "#38a169"; /* Hover background color */
                  e.currentTarget.style.color = "white"; /* Hover text color */
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "#2d3748"; /* Revert background color */
                  e.currentTarget.style.color =
                    "#ffffff"; /* Revert text color */
                }}
              >
                {user.fname} {user.lname}
              </div>
            ))}
          </div>
        )}
        {searchQuery && noResults && (
          <div
            style={{
              position: "absolute",
              width: "90%" /* Matches the input width */,
              backgroundColor: "#2d3748" /* Dark background */,
              border: "1px solid #4b5563",
              borderRadius: "12px",
              marginTop: "0.25rem",
              padding: "0.75rem",
              textAlign: "center",
              color: "#ffffff" /* White text */,
              left: "50%",
              transform:
                "translateX(-50%)" /* Center align no results message */,
            }}
          >
            No data found
          </div>
        )}
      </div>

      <div className="user-list">
        {users.map((user) => (
          <div
            key={user._id}
            className="user-card"
            onClick={() => handleCardClick(user)}
          >
            <div className="user-details">
              <h3 className="user-name">
                <strong className="text-white">Name: </strong>
                {user.fname} {user.lname}
              </h3>
              <p className="user-info">
                <strong className="text-white">Email: </strong> {user.email}
              </p>
              <p className="user-info">
                <strong className="text-white">Phone: </strong> {user.phone}
              </p>
            </div>
          </div>
        ))}
      </div>
      {selectedUser && !isEditModalOpen && (
        <Modal
          open={Boolean(selectedUser)}
          onClose={handleCloseModal}
          aria-labelledby="user-modal-title"
          aria-describedby="user-modal-description"
        >
          <Box
            className="fixed inset-0 flex items-center justify-center z-20"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div
              className="rounded-lg p-4 w-11/12 max-w-4xl shadow-lg relative"
              style={{
                background:
                  "linear-gradient(135deg, #2c3e50, #34495e, #1c2833)" /* Dark gradient */,
                border: "1px solid #7E8EF1" /* Existing border color */,
                color: "#ffffff" /* White text color for contrast */,
                boxShadow:
                  "0px 4px 15px rgba(0, 0, 0, 0.5)" /* Enhanced shadow for depth */,
              }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "0.2rem",
                  right: "1rem",
                  color: "#EF4444", // Tailwind red-500
                  fontSize: "2.5rem", // Increased font size for a larger close icon
                  padding: "0.50rem", // Increased padding for better click area
                  backgroundColor: "transparent", // Transparent background
                  border: "none", // Remove any default borders
                  cursor: "pointer",
                  transition: "color 0.3s ease",
                  borderRadius: "25px",
                }}
                onClick={handleCloseModal}
                onMouseEnter={(e) => (e.currentTarget.style.color = "#DC2626")} // Tailwind red-600 on hover
                onMouseLeave={(e) => (e.currentTarget.style.color = "#EF4444")} // Tailwind red-500 on mouse leave
              >
                ×
              </button>

              <Typography
                id="user-modal-title"
                variant="h6"
                component="h2"
                className="text-2 font-bold mb-2 p-2 text-white"
              >
                {selectedUser.fname} {selectedUser.lname}
              </Typography>

              <div
                className="grid grid-cols-2 gap-4 text-sm"
                style={{ color: "#9BEC00" }}
              >
                <div>
                  <p className="mb-1">
                    <strong className="text-gray-400">Email:</strong>{" "}
                    {selectedUser.email}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Phone:</strong>{" "}
                    {selectedUser.phone}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Address:</strong>{" "}
                    {selectedUser.address}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Referral Code:</strong>{" "}
                    {selectedUser.referralCode}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Coins:</strong>{" "}
                    {selectedUser.coins}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Investment Value:</strong>{" "}
                    {selectedUser.investmentValue}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Remaining Investment:
                    </strong>{" "}
                    {selectedUser.remainingInvestment}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Total Profit:</strong>{" "}
                    {selectedUser.totalProfit}
                  </p>
                </div>
                <div>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscription Tier:
                    </strong>{" "}
                    {selectedUser.subscription.tier}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscription Duration:
                    </strong>{" "}
                    {selectedUser.subscription.duration}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscription Activated:
                    </strong>{" "}
                    {selectedUser.subscription.activated ? "Yes" : "No"}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Pending Subscription Tier:
                    </strong>{" "}
                    {selectedUser.pendingSubscription.tier}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Pending Subscription Duration:
                    </strong>{" "}
                    {selectedUser.pendingSubscription.duration}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscribed to IPO:
                    </strong>{" "}
                    {selectedUser.isSubscribedIpo ? "Yes" : "No"}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscribed to Marketview:
                    </strong>{" "}
                    {selectedUser.isSubscribedMarketview ? "Yes" : "No"}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscribed to Research View:
                    </strong>{" "}
                    {selectedUser.isSubscribedResearchView ? "Yes" : "No"}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">
                      Subscribed to Stock Idea:
                    </strong>{" "}
                    {selectedUser.isSubscribedStockIdea ? "Yes" : "No"}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Last Visited:</strong>{" "}
                    {new Date(selectedUser.lastVisited).toLocaleDateString()}
                  </p>
                  <p className="mb-1">
                    <strong className="text-gray-400">Source:</strong>{" "}
                    {selectedUser.source}
                  </p>
                </div>
              </div>
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  style={{
                    backgroundColor: "#EF4444", // Tailwind red-500
                    color: "white",
                    padding: "7px 15px",
                    borderRadius: "7px",
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsDeleteConfirm(true); // Open the delete confirmation modal
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#DC2626")
                  } // Tailwind red-600 on hover
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#EF4444")
                  } // Tailwind red-500 on mouse leave
                >
                  Delete
                </button>

                <button
                  className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors duration-300"
                  onClick={() => handleEdit(selectedUser)}
                  style={{ padding: "7px 15px", borderRadius: "7px" }}
                >
                  Edit
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {isEditModalOpen && selectedUser && (
        <EditUserModal
          user={selectedUser}
          onClose={handleCloseModal}
          onUpdate={handleUpdateUser}
        />
      )}

      {isDeleteSuccess && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999, // High z-index to ensure it appears on top
            backdropFilter: "blur(10px)",
          }}
        >
          <div
            style={{
              backgroundColor: "#10B981", // Tailwind green-500
              color: "white",
              padding: "1.5rem",
              borderRadius: "0.375rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                marginBottom: "0.75rem",
              }}
            >
              Success
            </h2>
            <p>User deleted successfully!</p>
            <button
              style={{
                marginTop: "1rem",
                backgroundColor: "#059669", // Tailwind green-600
                padding: "0.5rem 1rem",
                borderRadius: "0.375rem",
                color: "white",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onClick={() => setIsDeleteSuccess(false)} // Close the modal when clicking the button
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#047857")
              } // Darker green on hover
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#059669")
              } // Original color on mouse leave
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isDeleteConfirm && selectedUser && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999, // Higher z-index to ensure it appears on top

            backdropFilter: "blur(10px)",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              padding: "1.5rem",
              borderRadius: "0.5rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              maxWidth: "30rem",
              width: "100%",
              color: "white",
              border: "1px solid #EF4444", // Tailwind red-500 border color
            }}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                marginBottom: "1rem",
              }}
            >
              Confirm Delete
            </h2>
            <p
              style={{
                marginBottom: "1rem",
              }}
            >
              Are you sure you want to delete {selectedUser.fname}{" "}
              {selectedUser.lname}?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <button
                style={{
                  backgroundColor: "#EF4444", // Tailwind red-500
                  color: "white",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.375rem",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  console.log("Delete confirmed");
                  handleDelete(); // Now call handleDelete to actually delete the user
                }}
              >
                Delete
              </button>
              <button
                style={{
                  backgroundColor: "#4B5563", // Tailwind gray-600
                  color: "white",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.375rem",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => {
                  console.log("Cancel delete");
                  setIsDeleteConfirm(false); // Cancel deletion
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
