import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CreateIcon from "@mui/icons-material/Create";

const InvestmentValueInput = (props) => {
  const [investmentValue, setInvestmentValue] = useState(
    props.initialInvestmentValue || ""
  );
  const [showInput, setShowInput] = useState(false);
  const [savedValue, setSavedValue] = useState(
    props.initialInvestmentValue || null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleResetDialogOpen = () => setOpenDialog(true);
  const handleResetDialogClose = () => setOpenDialog(false);

  const handleConfirmReset = () => {
    setInvestmentValue("0");
    setOpenDialog(false);
    setShowInput(false); // Close the modal right after resetting
  };

  const handleInputChange = (event) => {
    setInvestmentValue(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const stockIdeasData = props.stockIdeasData;
      const response = await fetch(`${SERVER_URL}/finsha/investment-value`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: props.userId,
          investmentValue: investmentValue,
          stockIdeasData: stockIdeasData,
        }),
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setSavedValue(investmentValue);
        setShowInput(false);
        props.onInvestmentSet && props.onInvestmentSet();
        // Only reload if necessary
        window.location.reload();
      }
    } catch (error) {
      console.error("Error saving investment value:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => setShowInput(false);

  useEffect(() => {
    if (investmentValue === "0") {
      handleSubmit();
      // window.location.reload();
    }
  }, [investmentValue]);

  return (
    <>
      {showInput && (
        <div
          // style={{zIndex:"10000000"}}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-4 z-50"
          onClick={closeModal}
        >
          <div
            className="bg-gradient-to-r from-black to-gray-600 p-8 rounded-lg shadow-lg z-60 w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="text-xl font-bold mb-2 text-center text-white p-2">
              Total Investment Amount
            </div>
            <input
              type="number"
              className="w-full p-2 border border-gray-300 rounded mb-4 text-black"
              value={investmentValue}
              onChange={handleInputChange}
              placeholder="Enter Your Total Investment Value"
              autoFocus
            />
            <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                className="flex items-center justify-center px-3 py-1 bg-orange-500 text-white rounded-full hover:bg-orange-600"
                onClick={handleResetDialogOpen}
                style={{ borderRadius: "7px" }}
              >
                <RestartAltIcon className="mr-1" /> Reset
              </button>
              {/* <button
                className="flex items-center justify-center px-4 py-1 text-white"
                onClick={handleSubmit}
                style={{
                  borderRadius: "7px",
                  background: "linear-gradient(to right, #57b757, #369638)",
                  transition: "background 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.background =
                    "linear-gradient(to right, #57b757, #369638)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.background =
                    "linear-gradient(to right, #369638, #57b757)")
                }
              >
                + Add
              </button> */}
              <button
                className="flex items-center justify-center px-4 py-1 text-white"
                onClick={handleSubmit}
                style={{
                  borderRadius: "7px",
                  background: "linear-gradient(to right, #57b757, #369638)",
                  transition: "background 0.3s ease",
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={20} className="text-white" />
                ) : (
                  "+ Add"
                )}
              </button>

              <button
                className="flex items-center justify-center px-3 py-1 text-white"
                onClick={closeModal}
                style={{
                  borderRadius: "7px",
                  borderColor: "currentColor",
                  transition: "background 0.3s ease",
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = "red")}
                onMouseOut={(e) =>
                  (e.currentTarget.style.background = "transparent")
                }
              >
                <NotInterestedIcon className="mr-1" /> Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {props.isButton ? (
        <button
          className="lg:w-full bg-gradient-to-r from-[#369638] to-[#57b757] text-white rounded-full px-3 py-1.5 text-sm text-center cursor-pointer shadow-md border-none m-2 ml-auto transition-colors duration-300 ease-in-out lg:shadow-inner lg:shadow-green-900"
          onClick={() => setShowInput(true)}
          startIcon={
            savedValue ? (
              <FaRegMoneyBillAlt className="investmetnvalueEdit_icon inline" />
            ) : (
              <CreateIcon className="investmetnvalueEdit_icon" />
            )
          }
        >
          {savedValue ? `Investment: ₹${savedValue}` : "Set Investment Value"}
        </button>
      ) : (
        <div
          onClick={() => setShowInput(true)}
          startIcon={
            savedValue ? (
              <FaRegMoneyBillAlt className="investmetnvalueEdit_icon inline" />
            ) : (
              <CreateIcon className="investmetnvalueEdit_icon" />
            )
          }
        >
          {savedValue ? `Investment: ₹${savedValue}` : "Set Investment Value"}
        </div>
      )}

      <Dialog
        open={openDialog}
        onClose={handleResetDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="rounded-lg shadow-lg max-w-md mx-auto"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="bg-gray-800 text-center text-5 px-2 text-red-600"
        >
          Confirm Reset
        </DialogTitle>
        <DialogContent className="bg-white py-4">
          <DialogContentText
            id="alert-dialog-description"
            className="text-black text-center fw-semibold"
          >
            Are you sure you want to Reset?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="bg-gray-100 p-1 rounded-b-lg flex justify-end space-x-2">
          <button
            onClick={handleConfirmReset}
            className="text-white px-4 py-1"
            style={{
              borderRadius: "7px",
              background: "linear-gradient(to right, #369638, #57b757)",
              transition: "background 0.3s ease",
            }}
            autoFocus
          >
            Confirm
          </button>
          <button
            onClick={handleResetDialogClose}
            className="text-gray-600 px-4 py-1"
            style={{
              borderRadius: "7px",
              transition: "background 0.3s ease",
            }}
            onMouseOver={(e) => (e.currentTarget.style.background = "red")}
            onMouseOut={(e) =>
              (e.currentTarget.style.background = "transparent")
            }
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvestmentValueInput;
