// QuickFinshaFeatureList.js

import React from 'react';
import QuickFinshaFeatureItem from './QuickFinshaFeatureItem';
import shortTerm from "../images/short-term.png";
import targets from "../images/target.png";
import idealInvestment from "../images/ideal-investment.png";
import tips from "../images/Tips.png";
import recommendations from "../images/recommendation.png";
import notifications from "../images/notification.png";
import investment from "../images/cust-investment.png";
import successInvestment from "../images/success-invest.png";
import physical from "../images/physi_consult.webp"
import moneyInvestment from "../images/Money-Investment.png";
import performance from "../images/performance.png"

// Define the features array
const QuickFinshaFeature = [
  {
    image: shortTerm,
    heading: "Focused on Short- Term Trades",
    description: "Achieve quick returns with strategies designed for short-term investments.",
  },
  {
    image: targets,
    heading: "Predefined Targets & Stop Loss",
    description: "Trade confidently with clear target and stop loss prices.",
  },
  {
    image: idealInvestment,
    heading: "Ideal for above 5 Lakh Investment",
    description: "Perfect for those looking to invest above 5 lakh in the market.",
  },
  {
    image: tips,
    heading: "8-15 Active Stock Ideas",
    description: "Receive up to 15 active stock tips to help you stay ahead in the market.",
  },
  {
    image: recommendations,
    heading: "Weekly Buy Recommendations",
    description: "Get multiple recommendations every week to keep your portfolio dynamic.",
  },
  {
    image: notifications,
    heading: "Instant WhatsApp Notifications",
    description: "Stay updated with real-time notifications and never miss an investing opportunity.",
  },
  {
    image: investment,
    heading: "Customized Investment Calculations",
    description: "Enter the size of your investment on the website to get the exact number of shares to buy for each recommendation.",
  },
  {
    image: successInvestment,
    heading: "Track Your Success Under 'StockIdea'",
    description: "Navigate to 'StockIdea' on our website to monitor the detailed insights of shares to buy and the potential profit or loss.",
  },
  {
    image: physical,
    heading: "Physical Consultation & Support",
    description:"Benefit from personal visits by our experts, where we can discuss strategies in detail and assist with any investment-related concerns you may have.",
  },
  {
    image: performance,
    heading: "Monthly Performance Reports",
    description:"Stay informed with reports outlining the performance of your investments, along with recommendations for future actions.",
  },
  {
    image: moneyInvestment,
    heading: "Personalized Investment Support",
    description:"Our experts will work with you to understand your financial objectives and risk tolerance to provide investment support as per your requirement.",
  },
];

const QuickFinshaFeatureList = () => {
  return (
    <div className="max-w-full lg:w-[1200px] mx-auto mb-[65px] box-border z-10">
      <div className="flex flex-wrap gap-12 justify-center w-full lg:w-[90%] mx-auto">
        {QuickFinshaFeature.map((feature, index) => (
          <QuickFinshaFeatureItem
            key={index}
            image={feature.image}
            heading={feature.heading}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
};

export default QuickFinshaFeatureList;
