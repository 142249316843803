import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Link } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import "./HistoryCard.css"

const ArchivedIdeas = ({ archivedStockIdeas, userSubscriptionTier, isButton }) => {
    const [showArchived, setShowArchived] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedIdea, setSelectedIdea] = useState(null);
    const [selectedIdeaType, setSelectedIdeaType] = useState(null);
    const [viewMode, setViewMode] = useState('TargetAchievement');

    useEffect(() => {
    }, [archivedStockIdeas]);

    const calculateDaysDifference = (startDateString, endDateString) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    };

    const calculatePercentageGain = (buyPrice, sellPrice) => {
        const gain = ((sellPrice - buyPrice) / buyPrice) * 100;
        return gain.toFixed(2);
    };

    const calculateAnnualizedReturn = (buyPrice, sellPrice, daysHeld) => {
        const returnRatio = sellPrice / buyPrice;
        const yearsHeld = daysHeld / 365;
        const annualizedReturn = (Math.pow(returnRatio, 1 / yearsHeld) - 1) * 100;
        return annualizedReturn.toFixed(2);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options).replace(/\//g, '-');
    };

    // Group ideas by status and sort by date
    const groupByStatus = (ideas) => {
        const groupedIdeas = {
            TargetAchievement: [],
            StopLossAchievement: [],
        };

        ideas.forEach((idea) => {
            if (idea["Visibility"] === userSubscriptionTier) {
                if (idea.status === "Target Hit") {
                    groupedIdeas.TargetAchievement.push(idea);
                } else if (idea.status === "Stop Loss Hit") {
                    groupedIdeas.StopLossAchievement.push(idea);
                }
            }
        });

        groupedIdeas.TargetAchievement.sort((a, b) => new Date(b["Recommendation Date"]) - new Date(a["Recommendation Date"]));
        groupedIdeas.StopLossAchievement.sort((a, b) => new Date(b["Recommendation Date"]) - new Date(a["Recommendation Date"]));

        return groupedIdeas;
    };

    const groupedIdeas = groupByStatus(archivedStockIdeas);

    const handleIdeaClick = (idea, type) => {
        if (idea["Visibility"] === userSubscriptionTier) {
            setSelectedIdea(idea);
            setSelectedIdeaType(type);
            setShowDetails(true);
        } else {
            alert("This idea is not visible for your subscription tier.");
        }
    };

    const showTargetAchievements = () => {
        setViewMode('TargetAchievement');
    };

    const showStopLossAchievements = () => {
        setViewMode('StopLossAchievement');
    };

    return (
        <div className="archived-ideas-container">


{isButton ? (
  <button
    className="bg-gradient-to-r from-[#369638] to-[#57b757] text-white rounded-full px-3 py-1.5 text-sm text-center cursor-pointer shadow-md border-none m-2 ml-auto transition-colors duration-300 ease-in-out lg:w-full lg:shadow-inner lg:shadow-green-900"
    onClick={() => setShowArchived(true)}
    style={{ transition: 'background-color 0.3s ease' }}
    onMouseOver={(e) => {
      e.currentTarget.style.background = 'linear-gradient(to right, #57b757, #369638)';
    }}
    onMouseOut={(e) => {
      e.currentTarget.style.background = 'linear-gradient(to right, #369638, #57b757)';
    }}
  >
    <HistoryIcon style={{ color: "white", fontSize: "20px" }} /> History
  </button>
) : (
  <div className='cursor-pointer'
    onClick={() => setShowArchived(true)}
  >
    <HistoryIcon style={{fontSize: "20px" }} /> History
  </div>
)}


            <Dialog
                open={showArchived}
                onClose={() => setShowArchived(false)}
                fullWidth={true}
                maxWidth="lg"
                PaperProps={{
                    style: { height: '80vh' }
                }}
            >
                <DialogTitle className="w-full flex items-center justify-center relative p-10">
                    <span className='text-2xl font-semibold absolute left-1/2 transform -translate-x-1/2'>History of Stock Ideas</span>
                    <button
                        className="text-gray-400 hover:text-red-700 focus:outline-none absolute right-4 p-1.5 w-12 border-2 border-black hover:border-red-700 rounded-full"
                        onClick={() => setShowArchived(false)}
                    >
                        X
                    </button>
                </DialogTitle>
                <DialogContent className="w-full mt-10">
                    <div className="flex justify-center space-x-4 mb-4">
                        <button className={`px-4 py-2 rounded ${viewMode === 'TargetAchievement' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'}`} onClick={showTargetAchievements}>
                            Target Price Achievement
                        </button>
                        <button className={`px-4 py-2 rounded ${viewMode === 'StopLossAchievement' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'}`} onClick={showStopLossAchievements}>
                            Stop Loss Hitted
                        </button>
                    </div>
                    {viewMode === 'TargetAchievement' ? (
                        <div className="overflow-y-auto max-h-64">
                            {groupedIdeas.TargetAchievement.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className='bg-gradient-to-r from-black to-gray-800 text-white'>
                                            <tr>
                                                <th className='px-4 py-2 border-b'>Company Name</th>
                                                <th className='px-4 py-2 border-b'>Percentage Gain</th>
                                                <th className='px-4 py-2 border-b'>Annualized Return</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedIdeas.TargetAchievement.map((idea, index) => (
                                                <tr key={index} onClick={() => handleIdeaClick(idea, 'Target')} className="cursor-pointer hover:bg-gray-100" title={`See more details about ${idea["Company Name"]}`}>
                                                    <td className='px-4 py-2 border-b'>{idea["Company Name"]}</td>
                                                    <td className={`px-4 py-2 border-b ${calculatePercentageGain(idea["CurrentPrice"], idea["SellPrice"]) >= 0 ? "text-green-500" : "text-red-500"}`}>
                                                        {calculatePercentageGain(idea["CurrentPrice"], idea["SellPrice"])}%
                                                    </td>
                                                    <td className={`px-4 py-2 border-b ${calculateAnnualizedReturn(idea["CurrentPrice"], idea["SellPrice"], calculateDaysDifference(idea["Date"], idea["Recommendation Date"])) >= 0 ? "text-green-500" : "text-red-500"}`}>
                                                        {calculateAnnualizedReturn(idea["CurrentPrice"], idea["SellPrice"], calculateDaysDifference(idea["Date"], idea["Recommendation Date"]))}%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="text-center text-gray-500 mt-4">
                                    <p><i className="fas fa-inbox"></i> No data available </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="overflow-y-auto max-h-64">
                            {groupedIdeas.StopLossAchievement.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className='bg-gradient-to-r from-black to-gray-800 text-white'>
                                            <tr>
                                                <th className='px-4 py-2 border-b'>Company Name</th>
                                                <th className='px-4 py-2 border-b'>Percentage Loss</th>
                                                <th className='px-4 py-2 border-b'>Annualized Loss</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedIdeas.StopLossAchievement.map((idea, index) => (
                                                <tr key={index} onClick={() => handleIdeaClick(idea, 'StopLoss')} className="cursor-pointer hover:bg-gray-100" title={`See more details about ${idea["Company Name"]}`}>
                                                    <td className='px-4 py-2 border-b'>{idea["Company Name"]}</td>
                                                    <td className={`px-4 py-2 border-b ${calculatePercentageGain(idea["CurrentPrice"], idea["SellPrice"]) >= 0 ? "text-green-500" : "text-red-500"}`}>
                                                        {calculatePercentageGain(idea["CurrentPrice"], idea["SellPrice"])}%
                                                    </td>
                                                    <td className={`px-4 py-2 border-b ${calculateAnnualizedReturn(idea["CurrentPrice"], idea["SellPrice"], calculateDaysDifference(idea["Date"], idea["Recommendation Date"])) >= 0 ? "text-green-500" : "text-red-500"}`}>
                                                        {calculateAnnualizedReturn(idea["CurrentPrice"], idea["SellPrice"], calculateDaysDifference(idea["Date"], idea["Recommendation Date"]))}%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="text-center text-gray-500 mt-4">
                                    <p><i className="fas fa-inbox"></i> No data available </p>
                                </div>
                            )}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog open={showDetails} onClose={() => setShowDetails(false)} fullWidth={true} maxWidth="lg">
                <DialogTitle className="dialog-title__details">Details for {selectedIdea && selectedIdea["Company Name"]}</DialogTitle>
                <DialogContent className="dialog-content">
                    {selectedIdea && selectedIdeaType === 'Target' && (
                        <div className="details-content-target">
                            <div className='company_name_div'>
                                <strong className='strong_company_name'>Name of the Company:</strong>
                                <span className="company-name">
                                    <Link
                                        className="target_companyname"
                                        to={`/company-name/${encodeURIComponent(selectedIdea["Company Name"].replace(/\s+/g, '-'))}`}
                                    >
                                        {selectedIdea["Company Name"]}
                                    </Link>
                                </span>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Plan:</strong>
                                    <span className="item-content">{selectedIdea["Visibility"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Date of Recommendation:</strong>
                                    <span className="item-content">
                                        {new Date(selectedIdea["Date"]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Buy Price:</strong>
                                    <span className="item-content">₹ {selectedIdea["CurrentPrice"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Date of Sell:</strong>
                                    <span className="item-content">
                                        {new Date(selectedIdea["Recommendation Date"]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Sell Price:</strong>
                                    <span className="item-content">₹ {selectedIdea["SellPrice"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Achievement Summary:</strong>
                                    <span className="item-content"><strong className="item-content__profitgain">{calculatePercentageGain(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"])}%</strong> in <strong className='days'>{calculateDaysDifference(selectedIdea["Date"], selectedIdea["Recommendation Date"])}</strong> days</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Percentage Gain:</strong>
                                    <span className="item-content__profitgain">{calculatePercentageGain(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"])}%</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Annualized Return:</strong>
                                    <span className="item-content__return">{calculateAnnualizedReturn(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"], calculateDaysDifference(selectedIdea["Date"], selectedIdea["Recommendation Date"]))}%</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedIdea && selectedIdeaType === 'StopLoss' && (
                        <div className="details-content-stoploss">
                            <div className='company_name_div'>
                                <strong className='strong_company_name'>Name of the Company:</strong>
                                <span className="company-name">
                                    <Link
                                        className="target_companyname"
                                        to={`/company-name/${encodeURIComponent(selectedIdea["Company Name"].replace(/\s+/g, '-'))}`}
                                    >
                                        {selectedIdea["Company Name"]}
                                    </Link>
                                </span>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Plan:</strong>
                                    <span className="item-content">{selectedIdea["Visibility"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Date of Recommendation:</strong>
                                    <span className="item-content">
                                        {new Date(selectedIdea["Date"]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Buy Price:</strong>
                                    <span className="item-content">₹ {selectedIdea["CurrentPrice"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Date of Sell:</strong>
                                    <span className="item-content">
                                        {new Date(selectedIdea["Recommendation Date"]).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                    </span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="left-item">
                                    <strong className="item-label">Sell Price:</strong>
                                    <span className="item-content">₹ {selectedIdea["SellPrice"]}</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Outcome:</strong>
                                    <span className="item-content"><strong className='item-content__lossgain'>{calculatePercentageGain(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"])}%</strong> in <strong className='days'>{calculateDaysDifference(selectedIdea["Date"], selectedIdea["Recommendation Date"])}</strong> days</span></div>
                            </div>
                            <div className="row">
                                <div className="left-item">
                                    <strong className="item-label">Percentage Loss:</strong>
                                    <span className="item-content__lossgain">{calculatePercentageGain(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"])}%</span>
                                </div>
                                <div className="right-item">
                                    <strong className="item-label">Annualized Loss:</strong>
                                    <span className="item-content__lossreturn">{calculateAnnualizedReturn(selectedIdea["CurrentPrice"], selectedIdea["SellPrice"], calculateDaysDifference(selectedIdea["Date"], selectedIdea["Recommendation Date"]))}%</span>
                                </div>
                            </div>
                            <div className='remainder-stoploss'><strong>Remainder:</strong> Investing involves risks and our stoploss strategy is designed to protect your capital.</div>
                        </div>
                    )}
                    <button className="back-button" onClick={() => setShowDetails(false)}><ReplyAllIcon style={{ color: "white" }} /> Back</button>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ArchivedIdeas;

