import React from 'react'

const CardFeature = ({ imgSrc, altText, title, description }) => {
  return (
    <div className="flex flex-col shadow-inner rounded-lg shadow-green-400 drop-shadow-2xl  mx-auto bg-gradient-to-tr from-green-100 via-orange-100 to-green-200 min-h-60 max-w-60">
    <div className="flex gap-1 justify-center pt-4 items-center pl-2">
      <img src={imgSrc} alt={altText} className="h-10 w-10 aspect-square" />
      <h2 className="font-bold text-lg text-[#286e34]">{title}</h2>
    </div>
    <div className="px-4 py-2">
      <p className="text-lg text-gray-700 px-2 line-clamp-4 hover:line-clamp-none">{description}</p>
    </div>
  </div>
  )
}

export default CardFeature;