import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../Shared/context/auth-context";
import "./Account.css";
import "react-image-crop/dist/ReactCrop.css";
import { Skeleton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { Modal, Box, Typography } from "@mui/material"; // MUI components
import CancelSubscription from "./CancelSubscription";
import CloseIcon from "@mui/icons-material/Close";
// import IpoSubscriptionSwitch from "../components/AccountComponent/IpoEmailSwitch";
import MarketviewEmailSwitch from "../components/AccountComponent/MarketSwitchEmail";
import ResearchViewEmailSwitch from "../components/AccountComponent/ResearchEmailSwitch";
import StockIdeaEmailNotificationSwitch from "../components/AccountComponent/StockIdeaEmailNotificationSwitch";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {
  FaWhatsapp,
  FaUser,
  FaUsers,
  FaEnvelope,
  FaRegCheckCircle,
  FaClipboardList,
  FaHome,
  FaClock,
  FaCoins,
} from "react-icons/fa";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";

function Account() {
  const auth = useContext(AuthContext);
  const { user } = auth;
  const inputRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true); // New loading state
  const [imageUpdated, setImageUpdated] = useState(false); // New state for triggering refresh
  const [showToast, setShowToast] = useState(false);
  const [isSubscriptionActivated, setIsSubscriptionActivated] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [timeRemaining, setTimeRemaining] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = async (e) => {
    if (inputRef.current.files && inputRef.current.files[0]) {
      const formData = new FormData();
      formData.append("profileImage", inputRef.current.files[0]);
      formData.append("userId", user._id);

      try {
        const response = await axios.put(
          `${SERVER_URL}/auth/user/update`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        auth.login(response.data.user); // Update the context with the new user data.
        setImageUpdated(true); // set imageUpdated to true
        setShowToast(true);
      } catch (error) {
        // Handle the error appropriately.
        console.error("Error updating profile image:", error);
      }
    }
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);

      return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }
  }, [showToast]);

  useEffect(() => {
    if (user) {
      setPhone(
        user.phone && user.phone.startsWith("91")
          ? user.phone
          : user.phone
          ? `91${user.phone}`
          : ""
      );
      setAddress(user.address);
      setFirstName(user.fname);
      setLastName(user.lname);
      setLoading(false);
      setImageUpdated(false); // reset the imageUpdated state
    }
    // Update the document title for SEO.
    document.title = "Account - Shariah Compliant Investment - finsha.in";
  }, [user, imageUpdated]);

  const handleSave = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // const phoneWithPrefix = phone.startsWith("91") ? phone : `91${phone}`;
    const phoneWithPrefix =
      phone && phone.startsWith("91") ? phone : phone ? `91${phone}` : "";
    // formData.append('profileImage', inputRef.current.files[0]);
    formData.append("userId", user._id);
    // formData.append("phone", phone);
    formData.append("phone", phoneWithPrefix);
    formData.append("address", address);
    formData.append("fname", firstName);
    formData.append("lname", lastName);

    try {
      const response = await axios.put(
        `${SERVER_URL}/auth/user/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      auth.login(response.data.user);
      setIsEditing(false);
      setImageUpdated(true); // set imageUpdated to true
    } catch (error) {
      // console.error('Error updating user data:', error);
    }
  };

  useEffect(() => {
    if (user && user.subscription) {
      setIsSubscriptionActivated(user.subscription.activated);
      setSubscriptionPlan(user.subscription.tier);
    }
  }, [user]);

  // Helper function to calculate time remaining
  function calculateTimeRemaining(expiryDate) {
    const now = new Date();
    const expiry = new Date(expiryDate);
    const timeRemaining = expiry - now;

    if (timeRemaining < 0) {
      return null; // subscription has expired
    }

    let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  useEffect(() => {
    if (user && user.subscription && user.subscription.activated) {
      const interval = setInterval(() => {
        const remaining = calculateTimeRemaining(user.subscription.expiryDate);
        setTimeRemaining(remaining);
      }, 1000); // update every second

      return () => clearInterval(interval);
    }
  }, [user]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdatedComponent = () => {
    handleCloseModal();
    // window.location.reload();
  };

  const AccountSkeleton = () => {
    return (
      <div className="account-container">
        <div className="account-left">
          <Skeleton variant="circular" width={100} height={100} />
        </div>
        <div className="account-right">
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            height={30}
            width="80%"
            style={{ marginBottom: "10px" }}
          />
        </div>
      </div>
    );
  };

  const shareReferralCode = async () => {
    if (!user || !user.referralCode) {
      return;
    }

    const shareMessage = `🚀 Join Finsha - Your Gateway to Smarter Investing! 🚀
I'm inviting you to explore the world of stock market analysis with Finsha.
🌟 Sign up with my referral code and unlock exclusive benefits:
💼 Referral Code: ${user.referralCode}
🎁 You get 20 Coins for joining, and I get 30 Coins for referring!
Discover top-notch investment recommendations and enhance your trading journey with Finsha.`;

    const shareData = {
      title: "Join Finsha with my Referral Code!",
      text: shareMessage,
      url: "https://www.finsha.in/auth",
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback: Copy referral code message to clipboard
        navigator.clipboard.writeText(shareMessage).then(
          () => {
            // Update your state or notify the user that the message has been copied
          },
          (err) => {
            console.error(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {showToast && (
        <div className="toast-message">Profile image updated successfully!</div>
      )}
      {loading ? (
        <AccountSkeleton />
      ) : (
        <div className="userAccount-page__container">
          <div className="userAccount-profile-details__container">
            <div className="profile_image_container">
              {user && user.profileImageURL ? (
                <img
                  src={
                    user.profileImageURL.startsWith("https")
                      ? `${user.profileImageURL}?${imageUpdated ? "1" : "0"}`
                      : `${SERVER_URL}/${user.profileImageURL}?${
                          imageUpdated ? "1" : "0"
                        }`
                  }
                  alt="Profile"
                  className="Profile__image__user"
                />
              ) : (
                firstName && (
                  <div className="profile-placeholder">
                    {firstName[0].toUpperCase()}
                  </div>
                )
              )}
              <div
                className="account-edit-overlay"
                onClick={() => inputRef.current.click()}
              >
                <i className="fas fa-pencil-alt"></i>
              </div>
              <input
                type="file"
                ref={inputRef}
                onChange={handleImageChange}
                className="account_userProfile-image-upload"
              />
            </div>

            <div className="userAccount-details__container">
              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaUser style={{ marginRight: "8px", color: "#000" }} /> Name
                </label>
                {isEditing ? (
                  <div className="input-container">
                    <input
                      className="input-field-a"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                    />
                    <input
                      className="input-field-a"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </div>
                ) : (
                  <span className="detail-text">
                    {firstName || lastName
                      ? `${firstName} ${lastName}`.trim()
                      : "User Name"}
                  </span>
                )}
              </div>
              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaEnvelope style={{ marginRight: "8px", color: "#000" }} />{" "}
                  Email
                </label>
                <span className="detail-text">{user && user.email}</span>
              </div>
            </div>
          </div>

          <div className="user_moredetails_container">
            <div className="details-left">
              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaWhatsapp
                    style={{ marginRight: "8px", color: "#25D366" }}
                  />{" "}
                  WhatsApp Number
                </label>
                {isEditing ? (
                  <TextField
                    className="input-field-a"
                    type="text"
                    value={
                      phone && phone.startsWith("91") ? phone.substring(2) : ""
                    } // Show the phone number without the "91" prefix if it exists
                    onChange={(e) =>
                      setPhone(e.target.value ? `91${e.target.value}` : "")
                    } // Add the "91" prefix back when updating the state, handle empty input
                    placeholder="Enter you Whatsapp Number"
                    InputProps={{
                      startAdornment: phone && phone.startsWith("91") && (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <span className="detail-text">
                    {phone ? phone : "WhatsApp Number"}
                  </span>
                )}
              </div>

              {user && user.panDetails && user.panDetails.panNumber && (
                <div className="account-detail">
                  <label
                    className="account-label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <ContactEmergencyIcon
                      style={{ marginRight: "8px", color: "#000" }}
                    />{" "}
                    PAN Card Details
                  </label>
                  <span className="detail-text">
                    {user.panDetails.panNumber}
                  </span>
                </div>
              )}

              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaRegCheckCircle
                    style={{ marginRight: "8px", color: "#000" }}
                  />{" "}
                  Subscription Status
                </label>
                <span
                  className={
                    isSubscriptionActivated
                      ? "subscription-status"
                      : "subscription-status inactive"
                  }
                  style={{ padding: "2px" }}
                >
                  {isSubscriptionActivated ? "Active" : "Inactive"}
                </span>
              </div>
              {subscriptionPlan && (
                <div className="account-detail">
                  <label
                    className="account-label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FaClipboardList
                      style={{ marginRight: "8px", color: "#000" }}
                    />{" "}
                    Plan Name
                  </label>
                  <span className="detail-text">
                    {subscriptionPlan === "QuickWin Starter"
                      ? "SmartHold Pro"
                      : subscriptionPlan}
                  </span>
                </div>
              )}

              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaUsers style={{ marginRight: "8px", color: "#000" }} />{" "}
                  Referral Code
                </label>
                <span className="detail-text">
                  {user.referralCode}{" "}
                  <ShareIcon
                    onClick={shareReferralCode}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </span>
                {/* <ShareIcon onClick={shareReferralCode} style={{ cursor: 'pointer', marginLeft: '10px' }} /> */}
                {copySuccess && (
                  <div className="copy-success-message">{copySuccess}</div>
                )}
              </div>
            </div>
            <div className="details-right">
              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaHome style={{ marginRight: "8px", color: "#000" }} />{" "}
                  Address
                </label>
                {isEditing ? (
                  <input
                    className="input-field-a"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter your address" /* Placeholder text */
                  />
                ) : (
                  <span className="detail-text">{address || "Address"}</span>
                )}
              </div>
              {user && user.subscription && user.subscription.activated && (
                <div className="account-detail">
                  <label
                    className="account-label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FaClock style={{ marginRight: "8px", color: "#000" }} />{" "}
                    Subscription Remaining
                  </label>
                  <span className="detail-text">{timeRemaining}</span>
                </div>
              )}
              <div className="account-detail">
                <label
                  className="account-label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaCoins style={{ marginLeft: "8px", color: "#FFD700" }} />{" "}
                  Coins
                </label>
                <span className="detail-text"> {user.coins}</span>
              </div>
            </div>
          </div>
          {isEditing ? (
            <div className="editing-btn-group">
              <button
                className="btn editing-save-btn"
                type="button"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="btn editing-cancel-btn"
                type="button"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="single-btn-group">
              <button
                className="btn single-edit-btn"
                type="button"
                onClick={handleEdit}
              >
                Edit
              </button>
              <button
                className="btn single-manage-btn"
                type="button"
                onClick={handleOpenModal}
              >
                Manage Subscription
              </button>
            </div>
          )}
        </div>
      )}
      <div className="footer_accounts"></div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="manage-subscription-modal bg-opacity-50"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <Box className="manage-subscription-box">
          <div
            className="manage-subscription-close-icon"
            onClick={handleCloseModal}
          >
            <CloseIcon style={{ fontSize: "24px", color: "white" }} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            className="cancelsubscription__modeltitle"
            style={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "1rem",
            }}
          >
            Manage Subscription{" "}
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: "8px" }} // Optional: adds some spacing between text and icon
            >
              <path
                d="M7 21H17C17.55 21 18 20.55 18 20V10C18 9.45 17.55 9 17 9H15.75L14.25 7H9.75L8.25 9H7C6.45 9 6 9.45 6 10V20C6 20.55 6.45 21 7 21ZM7 10H17V20H7V10ZM12 11.5C11.17 11.5 10.5 12.17 10.5 13C10.5 13.83 11.17 14.5 12 14.5C12.83 14.5 13.5 13.83 13.5 13C13.5 12.17 12.83 11.5 12 11.5ZM9 16.75H15V18.25H9V16.75ZM19 2H5C4.45 2 4 2.45 4 3V5C4 5.55 4.45 6 5 6H19C19.55 6 20 5.55 20 5V3C20 2.45 19.55 2 19 2ZM19 4H5V3H19V4Z"
                fill="#FFFFFF"
              />
            </svg>
          </Typography>
          <CancelSubscription user={user} />
          <div className="emailnotification__maindiv">
            <h5 className="emailnotification__main__titile">
              Email Notification{" "}
              <MarkEmailUnreadIcon
                style={{ color: "white", fontSize: "18px" }}
              />
            </h5>
            <StockIdeaEmailNotificationSwitch
              user={user}
              SERVER_URL={SERVER_URL}
            />
            <MarketviewEmailSwitch user={user} SERVER_URL={SERVER_URL} />
            <ResearchViewEmailSwitch user={user} SERVER_URL={SERVER_URL} />
          </div>

          <div className="button-container__modal_compoenent">
            <button
              className="save-button__updatedcomponenet"
              onClick={handleUpdatedComponent}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Account;
