import React, { useState } from "react";
import axios from "axios";

const EditUserModal = ({ user, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({ ...user });
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${SERVER_URL}/admin/users`, formData)
      .then((response) => {
        onUpdate(response.data);
        setIsUpdateSuccess(true); // Show success message
        setTimeout(() => {
          setIsUpdateSuccess(false); // Hide success message after 3 seconds
          onClose(); // Close the modal
        }, 3000);
      })
      .catch((error) => {
        console.error("There was an error updating the user!", error);
      });
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-20 mt-6"
      style={{ backdropFilter: "blur(10px)" }}
    >
      <div
        className="bg-black p-6 rounded-lg shadow-lg w-full max-w-6xl relative max-h-[80vh] overflow-y-auto"
        style={{
          background: "linear-gradient(135deg, #2c3e50, #34495e, #1c2833)",
        }}
      >
        <button
          className="absolute top-[0.2rem] right-[1rem] text-red-500 hover:text-red-600 text-[2.5rem] p-[0.5rem] bg-transparent border-none cursor-pointer transition-colors duration-300 ease-in-out rounded-full"
          onClick={onClose}
        >
          ×
        </button>

        {isUpdateSuccess && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999, // High z-index to ensure it appears on top
              backdropFilter: "blur(10px)",
            }}
          >
            <div
              style={{
                backgroundColor: "#10B981", // Tailwind green-500
                color: "white",
                padding: "1.5rem",
                borderRadius: "0.375rem",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  marginBottom: "0.75rem",
                }}
              >
                Success
              </h2>
              <p>User updated successfully!</p>
              <button
                style={{
                  marginTop: "1rem",
                  backgroundColor: "#059669", // Tailwind green-600
                  padding: "0.5rem 1rem",
                  borderRadius: "0.375rem",
                  color: "white",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => setIsUpdateSuccess(false)} // Close the modal when clicking the button
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#047857")
                } // Darker green on hover
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#059669")
                } // Original color on mouse leave
              >
                Close
              </button>
            </div>
          </div>
        )}

        <h2 className="text-2xl font-semibold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div
            className="grid grid-cols-2 gap-4"
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div>
              {/* Basic User Information */}
              <div className="mb-4">
                <label className="block text-white">First Name</label>
                <input
                  type="text"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Last Name</label>
                <input
                  type="text"
                  name="lname"
                  value={formData.lname}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Coins</label>
                <input
                  type="number"
                  name="coins"
                  value={formData.coins}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Source</label>
                <input
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
            </div>
            <div>
              {/* Additional User Information */}
              <div className="mb-4">
                <label className="block text-white">Investment Value</label>
                <input
                  type="number"
                  name="investmentValue"
                  value={formData.investmentValue}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Remaining Investment</label>
                <input
                  type="number"
                  name="remainingInvestment"
                  value={formData.remainingInvestment}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Total Profit</label>
                <input
                  type="number"
                  name="totalProfit"
                  value={formData.totalProfit}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white">Last Visited</label>
                <input
                  type="datetime-local"
                  name="lastVisited"
                  value={new Date(formData.lastVisited)
                    .toISOString()
                    .slice(0, -1)}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                />
              </div>
            </div>
            <div className="col-span-2 grid grid-cols-2 gap-4">
              {/* Subscription Information */}
              <div>
                <h3 className="text-xl text-white mb-2">Subscription</h3>
                <div className="mb-4">
                  <label className="block text-white">Subscription ID</label>
                  <input
                    type="text"
                    name="subscription.id"
                    value={formData.subscription?.id || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Is Free</label>
                  <select
                    name="subscription.isFree"
                    value={formData.subscription?.isFree?.toString()} // Convert boolean to string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          isFree: e.target.value === "true", // Convert string back to boolean
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Tier</label>
                  <select
                    name="subscription.tier"
                    value={formData.subscription?.tier || "QuickWin Starter"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          tier: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="QuickWin Starter">QuickWin Starter</option>
                    <option value="SmartHold Starter">SmartHold Starter</option>
                    <option value="SmartElite Starter">
                      SmartElite Starter
                    </option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Duration</label>
                  <select
                    name="subscription.duration"
                    value={formData.subscription?.duration || "Quarterly"}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          duration: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="Quarterly">Quarterly</option>
                    <option value="Half-Yearly">Half-Yearly</option>
                    <option value="Annually">Annually</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Activated</label>
                  <select
                    name="subscription.activated"
                    value={formData.subscription?.activated?.toString()} // Ensure the value is string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          activated: e.target.value === "true", // Convert back to boolean
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>

                {/* <div className="mb-4">
                  <label className="block text-white">Activation Date</label>
                  <input
                    type="date"
                    name="subscription.activationDate"
                    value={
                      formData.subscription?.activationDate
                        ? new Date(formData.subscription.activationDate)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Expiry Date</label>
                  <input
                    type="date"
                    name="subscription.expiryDate"
                    value={
                      formData.subscription?.expiryDate
                        ? new Date(formData.subscription.expiryDate)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div> */}
                <div className="mb-4">
                  <label className="block text-white">Activation Date</label>
                  <input
                    type="date"
                    name="subscription.activationDate"
                    value={
                      formData.subscription?.activationDate
                        ? formData.subscription.activationDate.slice(0, 10)
                        : ""
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          activationDate: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-white">Expiry Date</label>
                  <input
                    type="date"
                    name="subscription.expiryDate"
                    value={
                      formData.subscription?.expiryDate
                        ? formData.subscription.expiryDate.slice(0, 10)
                        : ""
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        subscription: {
                          ...formData.subscription,
                          expiryDate: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">
                    Razorpay Payment ID
                  </label>
                  <input
                    type="text"
                    name="subscription.razorpay_payment_id"
                    value={formData.subscription?.razorpay_payment_id || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Razorpay Signature</label>
                  <input
                    type="text"
                    name="subscription.razorpay_signature"
                    value={formData.subscription?.razorpay_signature || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Status</label>
                  <input
                    type="text"
                    name="subscription.status"
                    value={formData.subscription?.status || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
              </div>

              {/* Pending Subscription Information */}
              <div>
                <h3 className="text-xl text-white mb-2">
                  Pending Subscription
                </h3>
                <div className="mb-4">
                  <label className="block text-white">
                    Pending Subscription ID
                  </label>
                  <input
                    type="text"
                    name="pendingSubscription.id"
                    value={formData.pendingSubscription?.id || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Code</label>
                  <input
                    type="text"
                    name="pendingSubscription.code"
                    value={formData.pendingSubscription?.code || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Is Free</label>
                  <select
                    name="pendingSubscription.isFree"
                    value={formData.pendingSubscription?.isFree?.toString()} // Ensure the value is string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pendingSubscription: {
                          ...formData.pendingSubscription,
                          isFree: e.target.value === "true", // Convert back to boolean
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Tier</label>
                  <select
                    name="pendingSubscription.tier"
                    value={
                      formData.pendingSubscription?.tier || "QuickWin Starter"
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pendingSubscription: {
                          ...formData.pendingSubscription,
                          tier: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="QuickWin Starter">QuickWin Starter</option>
                    <option value="SmartHold Starter">SmartHold Starter</option>
                    <option value="SmartElite Starter">
                      SmartElite Starter
                    </option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Duration</label>
                  <select
                    name="pendingSubscription.duration"
                    value={
                      formData.pendingSubscription?.duration || "Quarterly"
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        pendingSubscription: {
                          ...formData.pendingSubscription,
                          duration: e.target.value,
                        },
                      })
                    }
                    className="w-full p-2 border rounded text-black"
                  >
                    <option value="Quarterly">Quarterly</option>
                    <option value="Half-Yearly">Half-Yearly</option>
                    <option value="Annually">Annually</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-white">Activation Date</label>
                  <input
                    type="date"
                    name="pendingSubscription.activationDate"
                    value={
                      formData.pendingSubscription?.activationDate
                        ? new Date(formData.pendingSubscription.activationDate)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Expiry Date</label>
                  <input
                    type="date"
                    name="pendingSubscription.expiryDate"
                    value={
                      formData.pendingSubscription?.expiryDate
                        ? new Date(formData.pendingSubscription.expiryDate)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">
                    Razorpay Payment ID
                  </label>
                  <input
                    type="text"
                    name="pendingSubscription.razorpay_payment_id"
                    value={
                      formData.pendingSubscription?.razorpay_payment_id || ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Razorpay Signature</label>
                  <input
                    type="text"
                    name="pendingSubscription.razorpay_signature"
                    value={
                      formData.pendingSubscription?.razorpay_signature || ""
                    }
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white">Status</label>
                  <input
                    type="text"
                    name="pendingSubscription.status"
                    value={formData.pendingSubscription?.status || ""}
                    onChange={handleChange}
                    className="w-full p-2 border rounded text-black"
                  />
                </div>
              </div>
            </div>

            {/* Subscription Status Flags */}
            <div className="col-span-2 grid grid-cols-4 gap-4">
              <div className="mb-4">
                <label className="block text-white">Subscribed to IPO</label>
                <select
                  name="isSubscribedIpo"
                  value={formData.isSubscribedIpo}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-white">
                  Marketview Notification
                </label>
                <select
                  name="isSubscribedMarketview"
                  value={formData.isSubscribedMarketview}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-white">
                  ResearchView Notification
                </label>
                <select
                  name="isSubscribedResearchView"
                  value={formData.isSubscribedResearchView}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-white">
                  StockIdea Notification
                </label>
                <select
                  name="isSubscribedStockIdea"
                  value={formData.isSubscribedStockIdea}
                  onChange={handleChange}
                  className="w-full p-2 border rounded text-black"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-white">Is Sub-Admin</label>
              <select
                name="isSubAdmin"
                value={formData.isSubAdmin?.toString()}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    isSubAdmin: e.target.value === "true",
                  });
                }}
                className="w-full p-2 border rounded text-black"
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-white">Role</label>
              <select
                name="role"
                value={formData.role || "None"}
                onChange={(e) => {
                  handleChange(e);
                }}
                className="w-full p-2 border rounded text-black"
              >
                <option value="Users">Users</option>
                <option value="StockIdeas">StockIdeas</option>
                <option value="Companies">Companies</option>
                <option value="Ipo">Ipo</option>
                <option value="Report">Report</option>
                <option value="MarketView">MarketView</option>
                <option value="None">None</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-4 mt-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
