import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  isLoading: true,  // add this line
  user: null,
  login: () => {},
  logout: () => {},
  updateUser: () => {}
});
