import React from "react";
import { Skeleton } from "@mui/material";
import Card from "../../../Shared/UIElements/Cards/pages/Card";

function CompanySkelton() {
  return (
    <>
      <div className="companydetails-container">
        <div className="company-header">
          <Skeleton variant="text" width={400} height={70} />{" "}
          {/* Company Name */}
          <Skeleton variant="text" width={300} height={50} />{" "}
          <Skeleton variant="text" width={300} height={120} />{" "}
          <Skeleton variant="text" width={300} height={50} />
          <div className="watchlist-button-container">
            <Skeleton variant="rectangular" width={200} height={50} />{" "}
            {/* Watchlist Button */}
          </div>
        </div>
        <div className="tradingview_chart_container">
          <Skeleton variant="rectangular" width={"100%"} height={500} />{" "}
          {/* Placeholder for TvChart or NIL text */}
        </div>
        <div className="sector-container">
          <div className="sector-div">
            <Skeleton variant="text" width={850} height={80} />{" "}
            {/* Placeholder for 'Company Business Model' */}
          </div>
          <ol>
            <li className="business">
              <Skeleton variant="text" width={750} height={40} />{" "}
              {/* Placeholder for Business 1 */}
            </li>
            <li className="business">
              <Skeleton variant="text" width={750} height={40} />{" "}
              {/* Placeholder for Business 2 */}
            </li>
            <li className="business">
              <Skeleton variant="text" width={750} height={40} />{" "}
              {/* Placeholder for Business 3 */}
            </li>
          </ol>
        </div>
        <div
          className="invetspros"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            <Skeleton variant="text" width={700} height={50} />
          </div>{" "}
          {/* Placeholder for 'Investment Prospective' title */}
          <Skeleton variant="text" width={"100%"} height={40} />{" "}
          <Skeleton variant="text" width={"100%"} height={40} />{" "}
          <Skeleton variant="text" width={"100%"} height={40} />{" "}
          {/* Placeholder for the Investment Prospective details */}
        </div>

        <div className="noteforcompanydetails">
          <Skeleton variant="text" width={"100%"} height={50} />{" "}
          {/* Placeholder for the Note */}
        </div>
        <div className="CompanyDetails">
          <Card
            className="shariah-card"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Skeleton variant="text" width={280} height={50} />
            {/* Placeholder for 'Shariah Compliant Status' */}
            <Skeleton
              variant="rectangular"
              width={140}
              height={120}
              style={{ margin: "20px" }}
            />
            {/* Placeholder for status images */}
            <Skeleton variant="text" width={170} height={50} />
            {/* Placeholder for final status */}
          </Card>

          <div
            className="rating-card"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Skeleton variant="text" width={400} height={80} />
            {/* Placeholder for 'Finsha Rating' */}
            <div style={{ display: "flex" }}>
              {/* 5 Skeletons for StarRating in horizontal layout */}
              {[...Array(5)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={50}
                  height={50}
                  style={{ margin: "0 20px" }}
                />
              ))}
            </div>
          </div>

          <div style={{ marginTop: "250px" }}>
            <Skeleton
              variant="rectangular"
              width={300}
              height={40}
              style={{ borderRadius: "25px" }}
            />
            {/* Placeholder for 'Detailed Shariah Status' button */}
          </div>
        </div>
        <div className="Fundamenatal-data">
          <Skeleton variant="text" width={700} height={70} />{" "}
          {/* Placeholder for 'Company Fundamentals' title */}
          <div
            className="FinancialCardsGrid"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around", // This spreads out the cards evenly in the row
              maxWidth: "1200px", // Adjust based on your layout needs
              margin: "auto", // This centers the grid in its parent
            }}
          >
            {[...Array(15)].map((_, index) => (
              <div
                className="FinancialCard"
                key={index}
                style={{
                  margin: "10px",
                  width: "calc(33.333% - 20px)", // This calculates the width for 3 items per row, adjusting for margin
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxSizing: "border-box", // Ensures padding and borders are included in the width calculation
                }}
              >
                <Skeleton variant="rectangular" width={350} height={100} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanySkelton;
