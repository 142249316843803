import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "@mui/material"; // Import the Modal from Material-UI

const AddPanDetails = ({ open, onClose, onSaveSuccess }) => {
  const [panNumber, setPanNumber] = useState(Array(10).fill(""));
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false); // New state for message modal
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (showMessageModal) {
      const timer = setTimeout(() => {
        setShowMessageModal(false);
      }, 3000); // 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer on unmount or when modal closes
    }
  }, [showMessageModal]);

  // Handle change in each PAN input field
  const handlePanChange = (e, index) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9]?$/.test(value)) {
      const newPanNumber = [...panNumber];
      newPanNumber[index] = value;
      setPanNumber(newPanNumber);

      if (value && index < 9) {
        document.getElementById(`pan-input-${index + 1}`).focus();
      }
    }
  };

  // Combine all inputs into one PAN string and handle save
  const handleSavePanDetails = async () => {
    const panString = panNumber.join("");
    setIsLoading(true);
    try {
      const response = await axios.post(`${SERVER_URL}/auth/add-pan`, {
        panNumber: panString,
      });
      setMessage(response.data.message);
      setError("");
      setShowMessageModal(true); // Show message modal
      onSaveSuccess(); // Only call onSaveSuccess if PAN details are saved successfully
    } catch (err) {
      setError(err.response?.data?.error || "Failed to save PAN details.");
      setMessage("");
      setShowMessageModal(true); // Show message modal
    } finally {
      setIsLoading(false);
    }
  };

  const isSaveButtonEnabled = panNumber.every((char) => char !== "");

  return (
    <>
      {/* Main Modal for PAN Details */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="pan-modal-title"
        aria-describedby="pan-modal-description"
        className="flex items-center justify-center"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div className="max-w-full mx-auto my-10 p-6 bg-white shadow-md rounded-lg relative">
          {/* Close button with aria-label for accessibility */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-red-600 font-bold text-2xl hover:text-gray-800 p-2"
            aria-label="Close"
          >
            x
          </button>

          {/* Important notice */}
          <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
            PAN Card Details
          </h2>
          <div className="flex justify-center mb-4 space-x-2">
            {panNumber.map((value, index) => (
              <input
                key={index}
                id={`pan-input-${index}`}
                type="text"
                value={value}
                onChange={(e) => handlePanChange(e, index)}
                maxLength={1}
                placeholder={index < 5 ? "A" : index < 9 ? "1" : "A"}
                className="w-10 h-10 border border-gray-300 text-center text-lg font-semibold rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ))}
          </div>
          <p className="text-center text-green-800 font-semibold mb-4">
            Notice: In accordance with SEBI regulations, providing your PAN card
            details is mandatory to fulfill KYC requirements.
          </p>
          <div className="flex justify-end w-full">
            <div className="w-32">
              <button
                onClick={handleSavePanDetails}
                className={`w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 ${
                  !isSaveButtonEnabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isSaveButtonEnabled || isLoading}
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 2.042.676 3.918 1.809 5.417l2.191-1.126z"
                      ></path>
                    </svg>
                    Saving...
                  </span>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* New Modal for Messages */}
      <Modal
        open={showMessageModal}
        onClose={() => setShowMessageModal(false)}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
        className="flex items-center justify-center"
        style={{ backdropFilter: "blur(5px)" }}
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto text-center">
          {message && <p className="text-green-600 font-semibold">{message}</p>}
          {error && <p className="text-red-600 font-semibold">{error}</p>}
          <button
            onClick={() => setShowMessageModal(false)}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddPanDetails;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { Modal } from '@mui/material'; // Import the Modal from Material-UI

// const AddPanDetails = ({ open, onClose, onSaveSuccess }) => { // Accept `open`, `onClose`, and `onSaveSuccess` as props
//   const [panNumber, setPanNumber] = useState(Array(10).fill(''));
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//   // Handle change in each PAN input field
//   const handlePanChange = (e, index) => {
//     const value = e.target.value.toUpperCase();
//     if (/^[A-Z0-9]?$/.test(value)) { // Allow only single letter or digit
//       const newPanNumber = [...panNumber];
//       newPanNumber[index] = value;
//       setPanNumber(newPanNumber);

//       // Automatically focus the next input field
//       if (value && index < 9) {
//         document.getElementById(`pan-input-${index + 1}`).focus();
//       }
//     }
//   };

//   // Combine all inputs into one PAN string and handle save
//   const handleSavePanDetails = async () => {
//     const panString = panNumber.join('');
//     setIsLoading(true);
//     try {
//       const response = await axios.post(`${SERVER_URL}/auth/add-pan`, { panNumber: panString });
//       setMessage(response.data.message);
//       setError('');
//       onSaveSuccess(); // Call the onSaveSuccess function if PAN details are saved successfully
//     } catch (err) {
//       setError(err.response?.data?.error || 'Failed to save PAN details.');
//       setMessage('');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Modal
//       open={open} // Control visibility using `open` prop
//       onClose={onClose} // Close action using `onClose` prop
//       aria-labelledby="pan-modal-title"
//       aria-describedby="pan-modal-description"
//       className="flex items-center justify-center"
//       style={{ backdropFilter: "blur(10px)" }}
//     >
//       <div className="max-w-full mx-auto my-10 p-6 bg-white shadow-md rounded-lg">
//       <p className="text-center text-red-600 font-semibold mb-4">
//       Important: As per SEBI guidelines, it is mandatory to provide your PAN card details for verification.
//     </p>
//         <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Add PAN Details</h2>
//         <div className="flex justify-center mb-4 space-x-2">
//           {panNumber.map((value, index) => (
//             <input
//               key={index}
//               id={`pan-input-${index}`}
//               type="text"
//               value={value}
//               onChange={(e) => handlePanChange(e, index)}
//               maxLength={1}
//               placeholder={index < 5 ? 'A' : index < 9 ? '1' : 'A'} // Placeholder logic
//               className="w-10 h-10 border border-gray-300 text-center text-lg font-semibold rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//           ))}
//         </div>
//         <button
//           onClick={handleSavePanDetails}
//           className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300"
//           disabled={isLoading}
//         >
//           {isLoading ? (
//             <span className="flex items-center justify-center">
//               <svg
//                 className="animate-spin h-5 w-5 mr-2 text-white"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 ></circle>
//                 <path
//                   className="opacity-75"
//                   fill="currentColor"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 2.042.676 3.918 1.809 5.417l2.191-1.126z"
//                 ></path>
//               </svg>
//               Saving...
//             </span>
//           ) : (
//             'Save'
//           )}
//         </button>
//         {message && <p className="mt-4 text-green-600 text-center">{message}</p>}
//         {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
//       </div>
//     </Modal>
//   );
// };

// export default AddPanDetails;
