import React, { useState, useEffect, useContext, useRef } from "react";
import "./StockIdeas.css"; // Import your custom CSS for additional styles
import { AuthContext } from "../../Shared/context/auth-context";
import CurrentPrice from "../../Screener/components/stock-price";
import StockIdeaSkeleton from "../components/StockIdea component/StockIdea-Skeleton";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ArchivedIdeas from "../components/StockIdea component/HistoryCard";
import InvestmentValueInput from "../components/StockIdea component/InvestmentValue";
import NetGain from "../components/StockIdea component/NetGain";
import PotentialLeft from "../components/StockIdea component/Potenticalleft";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import UnsubscribedStockIdeaGrid from "../components/StockIdea component/UnsubscribedGrid";
import axios from "axios";
import Doubledown from "../../images/Double-down.png";
import UpArrows from "../../images/up-arrows.png";
import { AiOutlineDownload } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import PricePercentage from "../../Screener/components/price-percentage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PercentageGain from "../components/StockIdea component/PercentageGain";

const StockIdeas = (props) => {
  const { user, updateUser } = useContext(AuthContext);
  const [stockIdeasData, setStockIdeasData] = useState([]);
  const [isUserSubscribed] = useState(
    user.subscription && user.subscription.activated
  );
  const userSubscriptionTier = user.subscription
    ? user.subscription.tier
    : null;
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [archivedStockIdeas, setArchivedStockIdeas] = useState([]);
  const [totalRecommendedInvestment, setTotalRecommendedInvestment] =
    useState(0);
  const [unusedInvestment, setUnusedInvestment] = useState(0);
  const [remainingInvestment, setRemainingInvestment] = useState(0);
  const [showInvestmentInput] = useState(false);
  const userId = user._id;
  const [openDialog, setOpenDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const [expandedCard, setExpandedCard] = useState(null); // Track expanded card
  const expandedCardRef = useRef(null);
  const [sortAZ, setSortAZ] = useState(false);
  const [sortZA, setSortZA] = useState(false);
  const [sortLatest, setSortLatest] = useState(false);
  const [sortOldest, setSortOldest] = useState(false);
  const [sortLowToHigh, setSortLowToHigh] = useState(false);
  const [sortHighToLow, setSortHighToLow] = useState(false);
  const [sortDurationLowToHigh, setSortDurationLowToHigh] = useState(false);
  const [sortDurationHighToLow, setSortDurationHighToLow] = useState(false);
  const [sortPotentialLowToHigh, setSortPotentialLowToHigh] = useState(false);
  const [sortPotentialHighToLow, setSortPotentialHighToLow] = useState(false);
  const [filterBuy, setFilterBuy] = useState(false);
  const [filterSell, setFilterSell] = useState(false);
  const [filterHold, setFilterHold] = useState(false);
  const [filterBTST, setFilterBTST] = useState(false);
  const [filterBTSM, setFilterBTSM] = useState(false);
  const [filterBTSF, setFilterBTSF] = useState(false);
  const [filterBTSW, setFilterBTSW] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  useEffect(() => {
    if (expandedCard !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expandedCard]);

  useEffect(() => {
    if (showFilters) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilters]);

  const history = useHistory();
  const handleSelectResult = (company) => {
    const companyNameWithHyphens = company["Company Name"]
      ? company["Company Name"].replace(/ /g, "-")
      : "";
    history.push(
      `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
      {
        company: company,
      }
    );
  };

  const handleClickOutside = (event) => {
    if (
      expandedCardRef.current &&
      !expandedCardRef.current.contains(event.target)
    ) {
      setExpandedCard(null);
    }
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowFilters(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios(`${SERVER_URL}/finsha/stock-ideas`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(
          `Failed to fetch data, status code: ${response.status}`
        );
      }

      const data = response.data;

      let filteredData = data;
      if (isUserSubscribed) {
        filteredData = data.filter(
          (stock) => stock.Visibility === userSubscriptionTier
        );
      }

      // Fetch "Potential Left" data for all stocks
      const updatedStocks = await Promise.all(
        filteredData.map(async (stock) => {
          try {
            const res = await axios.get(
              `${SERVER_URL}/finsha/calculate-potential-left/${stock.Symbol}/${stock["Target Price"]}`,
              { withCredentials: true }
            );
            stock["Potential Left"] = Number(res.data.percentageDifference);
            return stock;
          } catch (error) {
            console.error(
              `Error fetching Potential Left for ${stock.Symbol}:`,
              error
            );
            stock["Potential Left"] = null; // Handle error
            return stock;
          }
        })
      );

      setTotalDataCount(updatedStocks.length);
      setStockIdeasData(updatedStocks);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArchivedIdeas();
  }, []);

  async function fetchArchivedIdeas() {
    try {
      const response = await axios.get(`${SERVER_URL}/finsha/archived-ideas`, {
        withCredentials: true,
      });

      const data = response.data;
      let filteredData = data;
      if (isUserSubscribed) {
        filteredData = data.filter(
          (stock) => stock.Visibility === userSubscriptionTier
        );
      }
      setArchivedStockIdeas(filteredData);
    } catch (error) {
      console.error("Error fetching archived stock ideas:", error);
    }
  }

  const handleDownload = (link) => {
    const a = document.createElement("a");
    a.href = link;
    a.download = "IdeaReport.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleResetProfit = async () => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/finsha/reset-total-profit`,
        { userId: user._id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.headers["content-type"].includes("application/json")) {
        const data = response.data;
        if (response.status === 200) {
          updateUser({ ...user, totalProfit: 0 });
        }
      } else {
        const text = response.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseDialog();
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInvestmentSet = () => {
    fetchData();
    window.location.reload();
  };

  const handleSortAndFilter = () => {
    let sortedData = [...stockIdeasData];

    // Function to convert duration string to days
    function durationToDays(duration) {
      const [number, unit] = duration.split(" ");
      const num = parseFloat(number);

      if (isNaN(num)) {
        return 0; // Return 0 if the input is not a valid number
      }

      const unitLowerCase = unit.toLowerCase(); // Convert unit to lowercase for consistent comparison

      switch (unitLowerCase) {
        case "week":
        case "weeks":
          return num * 7;

        case "month":
        case "months":
          return num * 30;

        case "year":
        case "years":
          return num * 365;

        default:
          console.warn(`Unknown unit: ${unit}`);
          return 0;
      }
    }

    if (sortAZ) {
      sortedData.sort((a, b) =>
        a["Company Name"].localeCompare(b["Company Name"])
      );
    } else if (sortZA) {
      sortedData.sort((a, b) =>
        b["Company Name"].localeCompare(a["Company Name"])
      );
    } else if (sortLatest) {
      sortedData.sort(
        (a, b) =>
          new Date(b["Recommendation Date"]) -
          new Date(a["Recommendation Date"])
      );
    } else if (sortOldest) {
      sortedData.sort(
        (a, b) =>
          new Date(a["Recommendation Date"]) -
          new Date(b["Recommendation Date"])
      );
    } else if (sortLowToHigh) {
      sortedData.sort((a, b) => a.CurrentPrice - b.CurrentPrice);
    } else if (sortHighToLow) {
      sortedData.sort((a, b) => b.CurrentPrice - a.CurrentPrice);
    } else if (sortDurationLowToHigh) {
      sortedData.sort(
        (a, b) =>
          durationToDays(a["Holding Duration"]) -
          durationToDays(b["Holding Duration"])
      );
    } else if (sortDurationHighToLow) {
      sortedData.sort(
        (a, b) =>
          durationToDays(b["Holding Duration"]) -
          durationToDays(a["Holding Duration"])
      );
    } else if (sortPotentialLowToHigh) {
      sortedData.sort((a, b) => a["Potential Left"] - b["Potential Left"]);
    } else if (sortPotentialHighToLow) {
      sortedData.sort((a, b) => b["Potential Left"] - a["Potential Left"]);
    }

    if (filterBuy) {
      sortedData = sortedData.filter((stock) => stock.Recommendation === "Buy");
    } else if (filterSell) {
      sortedData = sortedData.filter(
        (stock) => stock.Recommendation === "Sell"
      );
    } else if (filterHold) {
      sortedData = sortedData.filter(
        (stock) => stock.Recommendation === "Hold"
      );
    }

    if (filterBTST) {
      sortedData = sortedData.filter(
        (stock) => stock.portfolioStrategy === "BTST"
      );
    } else if (filterBTSM) {
      sortedData = sortedData.filter(
        (stock) => stock.portfolioStrategy === "BTSM"
      );
    } else if (filterBTSF) {
      sortedData = sortedData.filter(
        (stock) => stock.portfolioStrategy === "BTSF"
      );
    } else if (filterBTSW) {
      sortedData = sortedData.filter(
        (stock) => stock.portfolioStrategy === "BTSW"
      );
    }

    return sortedData;
  };

  const groupByDate = (data) => {
    return data.reduce((acc, curr) => {
      (acc[curr.Date] = acc[curr.Date] || []).push(curr);
      return acc;
    }, {});
  };

  const groupedData = groupByDate(stockIdeasData);
  const buyCount = stockIdeasData.filter(
    (stock) => stock.Recommendation === "Buy"
  ).length;
  const holdCount = stockIdeasData.filter(
    (stock) => stock.Recommendation === "Hold"
  ).length;
  const sellCount = stockIdeasData.filter(
    (stock) => stock.Recommendation === "Sell"
  ).length;

  const resetFilters = () => {
    setSortAZ(false);
    setSortZA(false);
    setSortLatest(false);
    setSortOldest(false);
    setSortLowToHigh(false);
    setSortHighToLow(false);
    setSortDurationLowToHigh(false);
    setSortDurationHighToLow(false);
    setSortPotentialLowToHigh(false);
    setSortPotentialHighToLow(false);
    setFilterBuy(false);
    setFilterSell(false);
    setFilterHold(false);
    setFilterBTST(false);
    setFilterBTSM(false);
    setFilterBTSF(false);
    setFilterBTSW(false);
  };

  const sortedStockIdeasData = handleSortAndFilter();

  return (
    <div className="font-roboto flex flex-col lg:flex-row lg:justify-between mt-16 max-lg:flex-col-reverse">
      {isUserSubscribed && (
        <div className="lg:flex">
          {/* Filters Section */}
          <div className="lg:sticky bg-gray-100 lg:w-60 lg:top-0 lg:flex lg:flex-col lg:h-screen overflow-y-auto">
            <button
              className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 my-2 lg:hidden mx-2"
              onMouseEnter={() => setShowFilters(true)}
            >
              Toggle Filters
            </button>
            <div
              ref={filterRef}
              className={`absolute top-12 left-0 w-64 p-4 bg-white rounded-lg shadow-md space-y-6 z-50 lg:static lg:w-full lg:bg-transparent lg:shadow-none lg:p-0 lg:block ${
                showFilters ? "" : "hidden lg:block"
              }`}
            >
              {/* Filter options */}
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Lexicographically
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortAZ}
                    onChange={() => {
                      setSortAZ(!sortAZ);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">A-Z</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortZA}
                    onChange={() => {
                      setSortZA(!sortZA);
                      setSortAZ(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Z-A</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Date
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortLatest}
                    onChange={() => {
                      setSortLatest(!sortLatest);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Latest First</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortOldest}
                    onChange={() => {
                      setSortOldest(!sortOldest);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Oldest First</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Price
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortLowToHigh}
                    onChange={() => {
                      setSortLowToHigh(!sortLowToHigh);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Low to High</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortHighToLow}
                    onChange={() => {
                      setSortHighToLow(!sortHighToLow);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">High to Low</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Duration
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortDurationLowToHigh}
                    onChange={() => {
                      setSortDurationLowToHigh(!sortDurationLowToHigh);
                      setSortDurationHighToLow(false);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Low to High</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortDurationHighToLow}
                    onChange={() => {
                      setSortDurationHighToLow(!sortDurationHighToLow);
                      setSortDurationLowToHigh(false);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                      setSortPotentialLowToHigh(false);
                      setSortPotentialHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">High to Low</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Potential Left
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortPotentialLowToHigh}
                    onChange={() => {
                      setSortPotentialLowToHigh(!sortPotentialLowToHigh);
                      setSortPotentialHighToLow(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Low to High</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={sortPotentialHighToLow}
                    onChange={() => {
                      setSortPotentialHighToLow(!sortPotentialHighToLow);
                      setSortPotentialLowToHigh(false);
                      setSortDurationLowToHigh(false);
                      setSortDurationHighToLow(false);
                      setSortAZ(false);
                      setSortZA(false);
                      setSortLatest(false);
                      setSortOldest(false);
                      setSortLowToHigh(false);
                      setSortHighToLow(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">High to Low</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Recommendation
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterBuy}
                    onChange={() => {
                      setFilterBuy(!filterBuy);
                      setFilterSell(false);
                      setFilterHold(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Buy</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterSell}
                    onChange={() => {
                      setFilterSell(!filterSell);
                      setFilterBuy(false);
                      setFilterHold(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Sell</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterHold}
                    onChange={() => {
                      setFilterHold(!filterHold);
                      setFilterBuy(false);
                      setFilterSell(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">Hold</span>
                </label>
              </div>
              <div className="space-y-2">
                <label className="text-lg font-semibold text-gray-700">
                  Portfolio Strategy
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterBTST}
                    onChange={() => {
                      setFilterBTST(!filterBTST);
                      setFilterBTSM(false);
                      setFilterBTSF(false);
                      setFilterBTSW(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">BTST</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterBTSM}
                    onChange={() => {
                      setFilterBTSM(!filterBTSM);
                      setFilterBTST(false);
                      setFilterBTSF(false);
                      setFilterBTSW(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">BTSM</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterBTSF}
                    onChange={() => {
                      setFilterBTSF(!filterBTSF);
                      setFilterBTST(false);
                      setFilterBTSM(false);
                      setFilterBTSW(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">BTSF</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filterBTSW}
                    onChange={() => {
                      setFilterBTSW(!filterBTSW);
                      setFilterBTST(false);
                      setFilterBTSM(false);
                      setFilterBTSF(false);
                    }}
                    className="form-checkbox text-blue-600"
                  />
                  <span className="text-gray-600">BTSW</span>
                </label>
              </div>
              <div className="space-y-2">
                <button
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  onClick={resetFilters}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Stock card Section */}
      <div className="relative w-full lg:mx-auto lg:w-[43dvw] xl:w-[54dvw] max-lg:h-[90dvh] max-lg:overflow-y-auto">
        <div className={`${expandedCard !== null ? "blur-background" : ""}`}>
          {loading && <StockIdeaSkeleton />}
          {isUserSubscribed ? (
            totalDataCount === 0 ? (
              <div className="welcome-message">
                <h2>
                  <i className="fas fa-lightbulb"></i> Stock Idea
                </h2>
                <p>Welcome aboard</p>
                <p>
                  We're currently deep diving into market data, crunching the
                  latest numbers, and forging new stock ideas perfect for your
                  subscription Plan.
                </p>
                <p>
                  We know anticipation can be tough, but rest assured, our team
                  is on it, curating the finest insights just for you. While you
                  wait, why not explore other sections of our platform?
                </p>
                <p>
                  <i className="fas fa-heart"></i> Your trust means everything
                  to us. Stay tuned for exciting insights!
                </p>
              </div>
            ) : (
              <div className="mt-4 flex justify-center items-center mb-8">
                <div className="flex flex-col gap-6 w-[97%] lg:w-[43dvw] xl:w-[54dvw] max-w-[500px] mx-auto ">
                  {sortedStockIdeasData.map((stock, index) => {
                    const isExpanded = expandedCard === index;
                    const recommendedInvestment =
                      user.investmentValue *
                      (stock["recommendedPercentage"] / 100);
                    const totalSharesToBuy = stock["recommendedShares"];
                    const investmentPercentage =
                      (stock["investedAmount"] / user.investmentValue) * 100;

                    return (
                      <div
                        className={`stockidea__stock-card max-sm:text-sm ${stock["Recommendation"]}`}
                        ref={isExpanded ? expandedCardRef : null}
                        key={index}
                        onClick={() =>
                          setExpandedCard(isExpanded ? null : index)
                        }
                      >
                        <div
                          className={`stockidea__stock-card-content ${
                            isExpanded ? "expanded" : ""
                          }`}
                        >
                          <div className="flex justify-between">
                            <h5
                              className="max-sm:text-[17px] text-[19px] rounded-md text-green-900 font-bold max-sm:max-w-[30%] max-w-[45%] line-clamp-2 hover:line-clamp-none"
                              // onClick={() => handleSelectResult(stock)}
                            >
                              {stock["Company Name"]}
                            </h5>
                            <div className="flex flex-end justify-center items-center">
                              <CurrentPrice symbol={stock["Symbol"]} />
                              <PricePercentage symbol={stock["Symbol"]} />
                            </div>
                          </div>

                          <div className="">
                            <div className="absolute bg-green-200 -inset-0 -rotate-45 opacity-40"></div>

                            <div className="flex justify-between max-sm:flex-col">
                              <div className="">
                                <span className="font-semibold text-green-950">
                                  Buy Price:
                                </span>
                                <span className="">{stock["Buy Option"]}</span>
                              </div>

                              <div className="">
                                <span className="font-semibold text-green-950">
                                  Term:
                                </span>
                                <span className="">
                                  {stock["Holding Duration"]}
                                </span>
                                <span>({stock["portfolioStrategy"]})</span>
                              </div>
                            </div>

                            <div className="flex justify-between max-sm:flex-col">
                              <div className="">
                                <span className="font-semibold text-green-950">
                                  Target Price:
                                </span>
                                <span className="">
                                  {stock["Target Price"]}
                                </span>
                              </div>

                              <div>
                                <span className="font-semibold text-green-950">
                                  Stop Loss:
                                </span>
                                <span className="">{stock["Stop Loss"]}</span>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center pt-2 absolute max-sm:right-4 max-sm:top-1/4 top-1/3 right-[42%]">
                            <span
                              className={`recommendation-data h-[56px] w-[56px] rounded-full flex justify-center items-center max-sm:h-[62px] max-sm:w-[62px] ${
                                stock["Recommendation"]
                                  ? stock["Recommendation"].toLowerCase()
                                  : ""
                              }`}
                            >
                              {stock["Recommendation"]}
                            </span>
                          </div>
                          <div className="mr-4 flex justify-between ml-1">
                            <div className="text-green-950 font-bold max-sm:text-sm">
                              <PotentialLeft
                                potentialLeft={stock["Potential Left"]}
                                className="potential-design"
                              />
                            </div>
                            <div className="">
                              <span className="text-green-950 font-bold max-sm:text-sm">
                                Invest:
                              </span>
                              <span className="text-green-950 max-sm:text-sm">
                                {stock["recommendedPercentage"]}%
                              </span>{" "}
                            </div>
                          </div>
                          {isExpanded && (
                            <>
                              {showInvestmentInput ? (
                                <div className="investment-value-input-wrapper__stockCard">
                                  <InvestmentValueInput
                                    initialInvestmentValue={
                                      user.investmentValue
                                    }
                                    userId={user._id}
                                    stockIdeasData={stockIdeasData}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={`recomended-container border-2 border-white shadow-inner shadow-slate-400 ${
                                      !user.investmentValue ? "blur" : ""
                                    }`}
                                  >
                                    <div className="stockideaCard__recommeded_investment">
                                      ₹ {recommendedInvestment.toFixed(2)}
                                    </div>
                                    <div className="w-[80%] max-sm:w-[45%] max-md:w-[40%]">
                                      <CircularProgressbar
                                        value={investmentPercentage}
                                        text={`${investmentPercentage.toFixed(
                                          2
                                        )}%`}
                                        styles={buildStyles({
                                          textColor: "green",
                                          pathColor: "green",
                                          trailColor: "#d6d6d6",
                                        })}
                                      />
                                    </div>
                                    <div className="recomended-text">
                                      INVEST: ₹{" "}
                                      {stock["investedAmount"].toFixed(2)}
                                    </div>
                                    <div className="share-text-div">
                                      You can buy:{" "}
                                      <span className="share-count-span">
                                        {totalSharesToBuy}
                                      </span>{" "}
                                      shares.
                                    </div>
                                  </div>
                                  <div className="NetGain-div border-2 border-white shadow-inner shadow-slate-400">
                                    <h4 className="stockideacard__profitloss">
                                      Profit-Loss:
                                    </h4>
                                    {user.investmentValue ? (
                                      <NetGain
                                        className="net-gain-component"
                                        symbol={stock["Symbol"]}
                                        staticPrice={stock["CurrentPrice"]}
                                        shareToBuy={totalSharesToBuy}
                                      />
                                    ) : (
                                      <PercentageGain
                                        className="net-gain-component"
                                        symbol={stock["Symbol"]}
                                        staticPrice={stock["CurrentPrice"]}
                                        shareToBuy={totalSharesToBuy}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                              <p className="line-clamp-2 hover:line-clamp-none font-bold">
                                {stock["Message"]}
                              </p>
                              <div className="recomendationdate">
                                DATE :{stock["Recommendation Date"]}
                              </div>

                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDownload(stock.IdeaReportLink);
                                }}
                                className=""
                              >
                                <AiOutlineDownload size={24} />
                              </button>
                            </>
                          )}
                          <button
                            className="read-more-button absolute right-0 max-sm:bottom-4 bottom-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpandedCard(isExpanded ? null : index);
                            }}
                          >
                            <img
                              className="animate-pulse"
                              src={isExpanded ? UpArrows : Doubledown}
                              alt={isExpanded ? "Show Less" : "Read More"}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginInline: "2px",
                              }} // Adjust size as needed
                            />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          ) : (
            <>
              {totalDataCount === 0 ? (
                <div className="welcome-message">
                  <h2>
                    <i className="fas fa-lightbulb"></i> Stock Idea
                  </h2>
                  <p>Welcome aboard</p>
                  <p>
                    We're currently deep diving into market data, crunching the
                    latest numbers, and forging new stock ideas perfect for your
                    subscription Plan.
                  </p>
                  <p>
                    We know anticipation can be tough, but rest assured, our
                    team is on it, curating the finest insights just for you.
                    While you wait, why not explore other sections of our
                    platform?
                  </p>
                  <p>
                    <i className="fas fa-heart"></i> Your trust means everything
                    to us. Stay tuned for exciting insights!
                  </p>
                </div>
              ) : (
                <div className="w-full">
                  <UnsubscribedStockIdeaGrid
                    data={sortedStockIdeasData}
                    isUserSubscribed={isUserSubscribed}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* Expanded Card Section */}
        {expandedCard !== null && (
          <div className="expanded-card-wrapper max-h-screen overflow-auto no-scrollbar">
            {sortedStockIdeasData.map((stock, index) => {
              if (expandedCard !== index) return null;

              const recommendedInvestment =
                user.investmentValue * (stock["recommendedPercentage"] / 100);
              const totalSharesToBuy = stock["recommendedShares"];
              const investmentPercentage =
                (stock["investedAmount"] / user.investmentValue) * 100;

              return (
                <div
                  className={`rounded-lg relative w-[90%] max-sm:w-[95%] text-green-950 max-h-[90dvh] my-auto overflow-y-auto no-scrollbar ${stock["Recommendation"]}`}
                  ref={expandedCardRef}
                  key={index}
                >
                  <div className="bg-white border-2 border-white shadow-2xl shadow-slate-900 rounded-xl transition-all duration-500 ease-in-out flex max-md:text-sm max-md:flex-col">
                    <button
                      className="absolute -top-2 -right-2 py-2 px-[11px] bg-red-800 text-white z-50 shadow-inner shadow-red-950"
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedCard(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="w-full md:w-[70%] m-2">
                      <div className="flex justify-between items-center shadow-inner shadow-slate-500 p-2 rounded-lg max-md:flex-col max-md:mr-3">
                        <h5
                          className="max-md:text-xl text-2xl font-bold text-green-950 cursor-pointer transition-transform transform hover:-translate-y-1 duration-300"
                          onClick={() => handleSelectResult(stock)}
                        >
                          {stock["Company Name"]}
                        </h5>
                        <div className="flex items-center">
                          <CurrentPrice symbol={stock["Symbol"]} />
                          <PricePercentage symbol={stock["Symbol"]} />
                        </div>
                      </div>
                      <h5 className="text-green-950 text-lg text-center max-md:text-base">
                        {stock["Sector"]}
                      </h5>

                      <div className="flex justify-center items-center gap-1 max-md:flex-col">
                        <div className="p-2 w-[70%] max-md:w-full max-md:mr-3 bg-slate-100 flex gap-2 flex-col">
                          <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
                            <span className="font-semibold text-green-950">
                              Target Price:
                            </span>
                            <span>{stock["Target Price"]}</span>
                          </div>
                          <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
                            <span className="font-semibold text-green-950">
                              Stop Loss:
                            </span>
                            <span>{stock["Stop Loss"]}</span>
                          </div>
                          <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
                            <span className="font-semibold text-green-950">
                              Term:
                            </span>
                            <span>{stock["Holding Duration"]}</span>
                          </div>
                        </div>

                        <div className="w-full md:w-[30%] flex justify-center items-center shadow-inner shadow-slate-500 bg-green-100 p-2 rounded-lg max-md:mr-4">
                          <div className="flex flex-col justify-center items-center max-md:flex-row">
                            <span className="font-semibold text-xl text-green-950 max-md:text-lg max-sm:text-base">
                              Portfolio Strategy:
                            </span>
                            <span className="text-green-950 text-lg max-md:text-base max-sm:text-sm">
                              {stock["portfolioStrategy"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="my-2 mx-2 flex justify-around items-center gap-1 max-sm:flex-wrap">
                          <div className="">
                            <PotentialLeft
                              potentialLeft={stock["Potential Left"]}
                              className="potential-design"
                            />
                          </div>
                          <div className="shadow-inner shadow-slate-400 p-2 flex justify-center items-center rounded-lg">
                            {user.investmentValue ? (
                              <>
                                <h4 className="text-green-950 font-semibold text-lg max-md:text-sm">
                                  Profit-Loss:
                                </h4>
                                <NetGain
                                  className="flex flex-row justify-center items-center pl-2"
                                  symbol={stock["Symbol"]}
                                  staticPrice={stock["CurrentPrice"]}
                                  shareToBuy={totalSharesToBuy}
                                />
                              </>
                            ) : (
                              <>
                                <h4 className="text-green-950 font-semibold text-lg max-md:text-sm">
                                  Percentage_Gain:
                                </h4>
                                <PercentageGain
                                  className="flex flex-row justify-center items-center pl-2"
                                  symbol={stock["Symbol"]}
                                  staticPrice={stock["CurrentPrice"]}
                                  shareToBuy={totalSharesToBuy}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-white max-md:mr-2 max-lg:mb-2 lg:mb-4 transition-all duration-300 ease-in-out hover:bg-gray-800">
                        <div className="line-clamp-4 hover:line-clamp-none  hover:overflow-hidden  hover:no-scrollbar">
                          {stock["Message"]}
                        </div>
                      </div>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(stock.IdeaReportLink);
                        }}
                        className="max-md:absolute max-md:-bottom-60 max-md:right-1 max-md:z-50 p-2 rounded-lg bg-gray-600 shadow-inner shadow-gray-950 transform hover:scale-105 transition duration-300 text-white"
                      >
                        <span className="hidden md:inline-block">
                          Download Full Recommendation Report
                        </span>
                        <AiOutlineDownload
                          size={24}
                          className="text-white inline"
                        />
                      </button>
                    </div>

                    <div className="w-full md:w-[30%] relative p-2">
                      <div className="flex justify-center items-center flex-col w-full gap-2">
                        <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
                          <span className="font-semibold text-green-950">
                            Recommendation:
                          </span>
                          <span
                            className={`recommendation-data ${
                              stock["Recommendation"]
                                ? stock["Recommendation"].toLowerCase()
                                : ""
                            }`}
                          >
                            {stock["Recommendation"]}
                          </span>
                        </div>

                        <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
                          <span className="font-semibold text-green-950">
                            Buy Price:
                          </span>
                          <span>{stock["Buy Option"]}</span>
                        </div>

                        <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
                          <span className="font-semibold text-green-950">
                            Recommendation Date:
                          </span>
                          <span className="">
                            {stock["Recommendation Date"]}
                          </span>
                        </div>
                      </div>

                      {showInvestmentInput ? (
                        <div className="investment-value-input-wrapper__stockCard">
                          <InvestmentValueInput
                            initialInvestmentValue={user.investmentValue}
                            userId={user._id}
                            stockIdeasData={stockIdeasData}
                            isButton={false}
                            onInvestmentSet={handleInvestmentSet}
                          />
                        </div>
                      ) : (
                        <>
                          {recommendedInvestment &&
                          recommendedInvestment > 0 ? (
                            <>
                              <div
                                className={`recomended-container border-2 border-white shadow-inner shadow-slate-400`}
                              >
                                <div className="stockideaCard__recommeded_investment">
                                  ₹ {recommendedInvestment.toFixed(2)}
                                </div>
                                <div className="w-[65%] max-sm:w-[45%] max-md:w-[40%]">
                                  <CircularProgressbar
                                    value={investmentPercentage}
                                    text={`${investmentPercentage.toFixed(2)}%`}
                                    styles={buildStyles({
                                      textColor: "green",
                                      pathColor: "green",
                                      trailColor: "#d6d6d6",
                                    })}
                                  />
                                </div>
                                <div className="recomended-text">
                                  INVEST: ₹ {stock["investedAmount"].toFixed(2)}
                                </div>
                                <div className="share-text-div">
                                  You can buy:{" "}
                                  <span className="share-count-span">
                                    {totalSharesToBuy}
                                  </span>{" "}
                                  shares.
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={`relative flex flex-col items-center justify-center p-6 rounded-lg border-2 border-white shadow-inner shadow-slate-400 bg-gray-300 text-white`}
                              >
                                <div className="absolute inset-0 flex items-center justify-center z-20">
                                  <div
                                    className="relative px-6 py-3 w-full max-w-lg text-center text-white font-bold shadow-lg cursor-pointer"
                                    style={{
                                      background:
                                        "linear-gradient(to right, rgba(56,189,248,1), rgba(29,78,216,1))",
                                    }}
                                  >
                                    <InvestmentValueInput
                                      initialInvestmentValue={
                                        user.investmentValue
                                      }
                                      userId={user._id}
                                      stockIdeasData={stockIdeasData}
                                      isButton={false} // Renders as plain text
                                      onInvestmentSet={handleInvestmentSet}
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-col items-center justify-center blur-sm pointer-events-none select-none">
                                  <div className="stockideaCard__recommended_investment text-center">
                                    ₹ {recommendedInvestment.toFixed(2)}
                                  </div>
                                  <div className="w-[65%] max-sm:w-[45%] max-md:w-[40%] my-4">
                                    <CircularProgressbar
                                      value={100}
                                      text={`${20}%`}
                                      styles={buildStyles({
                                        textColor: "green",
                                        pathColor: "green",
                                        trailColor: "#d6d6d6",
                                      })}
                                    />
                                  </div>
                                  <div className="recommended-text text-center">
                                    INVEST: ₹{" "}
                                    {stock["investedAmount"].toFixed(2)}
                                  </div>
                                  <div className="share-text-div text-center">
                                    You can buy:{" "}
                                    <span className="share-count-span">50</span>{" "}
                                    shares.
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Active Stock Ideas Section */}
      {isUserSubscribed && (
        <div className="lg:sticky shadow-inner shadow-slate-500 border-2 border-white lg:top-0 lg:self-start lg:h-screen lg:overflow-auto lg:flex lg:flex-col lg:items-end p-4 lg:justify-center relative">
          <h1 className="text-2xl text-green-950 font-bold text-center mb-4 lg:mx-auto lg:pl-6 mt-3">
            Active Stock-Ideas
          </h1>
          <div className="flex flex-wrap items-center justify-around gap-4 mt-4 mb-4 lg:flex-col lg:justify-center lg:w-[100%]">
            <div className="stockidea__userSubscription_name md:shadow-inner md:shadow-slate-500 text-center lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
              <b className="flex items-center gap-2 justify-center">
                <StarBorderIcon className="stockIdea__staricon" />
                {userSubscriptionTier}
              </b>
            </div>
            <div className="stockidea__totalActiveStock_container md:shadow-inner md:shadow-slate-500 text-center lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
              <b>
                <TipsAndUpdatesIcon className="stockIdea__bulbicon" />
                Active Ideas :
              </b>
              <b className="dashborad_stockideadata">{totalDataCount}</b>
            </div>
            <div className="stockIdea__totalprofit_loss_main flex justify-center md:shadow-inner md:shadow-slate-500 lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
              <b className="totalprofit__title flex items-center gap-2 justify-center">
                <ImportExportIcon className="stockidea__updownIcon" />
                Total Profit/Loss
              </b>
              :
              <b className="dashborad_stockideadata">
                {user.totalProfit.toFixed(2)}
              </b>
            </div>
            {openDialog && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-md shadow-md w-96">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    Reset Profit
                  </h2>
                  <p className="mb-4 text-center">
                    Are you sure you want to reset the profit?
                  </p>
                  <div className="flex justify-end space-x-2">
                    <button
                      className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-blue-600 text-white rounded-md"
                      onClick={handleResetProfit}
                    >
                      Yes, Reset
                    </button>
                  </div>
                </div>
              </div>
            )}
            {user.investmentValue !== 0 && (
              <>
                <div className="stockIdea__currentinvestment text-center flex justify-center lg:w-full md:shadow-inner md:shadow-slate-500 hover:transform hover:-translate-y-2 hover:text-blue-800">
                  <b className="flex items-center gap-2 justify-center">
                    <FaRegMoneyBillAlt style={{ fontSize: 22 }} />
                    Current Capital
                  </b>
                  :
                  <b className="dashborad_stockideadata">
                    ₹ {(user.investmentValue + user.totalProfit).toFixed(2)}
                  </b>
                </div>
                <div className="stockIdea__remainingInvestment text-center flex justify-center lg:w-full md:shadow-inner md:shadow-slate-500 hover:transform hover:-translate-y-2 hover:text-blue-800">
                  <b className="flex items-center gap-2 justify-center">
                    <AccountBalanceWalletIcon className="stockidea__walletIcons" />
                    Free Capital :
                  </b>
                  <b className="dashborad_stockideadata">
                    ₹ {user.remainingInvestment.toFixed(2)}
                  </b>
                </div>
              </>
            )}
          </div>
          <div className="investment_input_div relative px-1 pb-1 max-lg:flex max-lg:justify-center lg:flex lg:flex-col lg:justify-end w-full shadow-inner shadow-slate-600 rounded-lg">
            <div
              className="p-2 w-full text-white text-center mt-2"
              style={{
                background:
                  "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
                boxShadow:
                  "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                borderRadius: "50px", // More rounded corners for a button-like appearance
                border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
              }}
            >
              <ArchivedIdeas
                archivedStockIdeas={archivedStockIdeas}
                userSubscriptionTier={userSubscriptionTier}
                isButton={false}
              />
            </div>
            <div
              className="p-2 w-full text-white text-center rounded-lg cursor-pointer"
              style={{
                background:
                  "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
                boxShadow:
                  "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                borderRadius: "50px", // More rounded corners for a button-like appearance
                border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
              }}
            >
              <InvestmentValueInput
                initialInvestmentValue={user.investmentValue}
                userId={user._id}
                stockIdeasData={stockIdeasData}
                onInvestmentSet={handleInvestmentSet}
                isButton={false}
              />
            </div>
            <div
              className="px-2 py-2 text-white rounded-lg cursor-pointer flex justify-center  lg:w-full"
              style={{
                background:
                  "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
                boxShadow:
                  "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                borderRadius: "50px", // More rounded corners for a button-like appearance
                border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
              }}
              onClick={handleOpenDialog}
            >
              <ImportExportIcon
                style={{ color: "white" }}
                className="stockidea__updownIcon"
              />
              Total Profit/Loss :
              <b className="dashborad_stockideadata_prift_loss">
                {user.totalProfit.toFixed(2)}
              </b>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockIdeas;

// import React, { useState, useEffect, useContext, useRef } from "react";
// import "./StockIdeas.css"; // Import your custom CSS for additional styles
// import { AuthContext } from "../../Shared/context/auth-context";
// import CurrentPrice from "../../Screener/components/stock-price";
// import StockIdeaSkeleton from "../components/StockIdea component/StockIdea-Skeleton";
// import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import ArchivedIdeas from "../components/StockIdea component/HistoryCard";
// import InvestmentValueInput from "../components/StockIdea component/InvestmentValue";
// import NetGain from "../components/StockIdea component/NetGain";
// import PotentialLeft from "../components/StockIdea component/Potenticalleft";
// import { FaRegMoneyBillAlt } from "react-icons/fa";
// import ImportExportIcon from "@mui/icons-material/ImportExport";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import UnsubscribedStockIdeaGrid from "../components/StockIdea component/UnsubscribedGrid";
// import axios from "axios";
// import Doubledown from "../../images/Double-down.png";
// import UpArrows from "../../images/up-arrows.png";
// import { AiOutlineDownload } from "react-icons/ai";
// import { useHistory } from "react-router-dom";
// import PricePercentage from "../../Screener/components/price-percentage";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import PercentageGain from "../components/StockIdea component/PercentageGain";

// const StockIdeas = (props) => {
//   const { user, updateUser } = useContext(AuthContext);
//   const [stockIdeasData, setStockIdeasData] = useState([]);
//   const [isUserSubscribed] = useState(
//     user.subscription && user.subscription.activated
//   );
//   const userSubscriptionTier = user.subscription
//     ? user.subscription.tier
//     : null;
//   const [totalDataCount, setTotalDataCount] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [archivedStockIdeas, setArchivedStockIdeas] = useState([]);
//   const [totalRecommendedInvestment, setTotalRecommendedInvestment] =
//     useState(0);
//   const [unusedInvestment, setUnusedInvestment] = useState(0);
//   const [remainingInvestment, setRemainingInvestment] = useState(0);
//   const [showInvestmentInput] = useState(false);
//   const userId = user._id;
//   const [openDialog, setOpenDialog] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//   const [expandedCard, setExpandedCard] = useState(null); // Track expanded card
//   const expandedCardRef = useRef(null);
//   const [sortAZ, setSortAZ] = useState(false);
//   const [sortZA, setSortZA] = useState(false);
//   const [sortLatest, setSortLatest] = useState(false);
//   const [sortOldest, setSortOldest] = useState(false);
//   const [sortLowToHigh, setSortLowToHigh] = useState(false);
//   const [sortHighToLow, setSortHighToLow] = useState(false);
//   const [sortDurationLowToHigh, setSortDurationLowToHigh] = useState(false);
//   const [sortDurationHighToLow, setSortDurationHighToLow] = useState(false);
//   const [sortPotentialLowToHigh, setSortPotentialLowToHigh] = useState(false);
//   const [sortPotentialHighToLow, setSortPotentialHighToLow] = useState(false);
//   const [filterBuy, setFilterBuy] = useState(false);
//   const [filterSell, setFilterSell] = useState(false);
//   const [filterHold, setFilterHold] = useState(false);
//   const [filterBTST, setFilterBTST] = useState(false);
//   const [filterBTSM, setFilterBTSM] = useState(false);
//   const [filterBTSF, setFilterBTSF] = useState(false);
//   const [filterBTSW, setFilterBTSW] = useState(false);
//   const [showFilters, setShowFilters] = useState(false);
//   const filterRef = useRef(null);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (expandedCard !== null) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [expandedCard]);

//   useEffect(() => {
//     if (showFilters) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showFilters]);

//   const history = useHistory();
//   const handleSelectResult = (company) => {
//     const companyNameWithHyphens = company["Company Name"]
//       ? company["Company Name"].replace(/ /g, "-")
//       : "";
//     history.push(
//       `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
//       {
//         company: company,
//       }
//     );
//   };

//   const handleClickOutside = (event) => {
//     if (
//       expandedCardRef.current &&
//       !expandedCardRef.current.contains(event.target)
//     ) {
//       setExpandedCard(null);
//     }
//     if (filterRef.current && !filterRef.current.contains(event.target)) {
//       setShowFilters(false);
//     }
//   };

//   const fetchData = async () => {
//     try {
//       const response = await axios(`${SERVER_URL}/finsha/stock-ideas`, {
//         withCredentials: true,
//       });

//       if (response.status !== 200) {
//         throw new Error(
//           `Failed to fetch data, status code: ${response.status}`
//         );
//       }

//       const data = response.data;

//       let filteredData = data;
//       if (isUserSubscribed) {
//         filteredData = data.filter(
//           (stock) => stock.Visibility === userSubscriptionTier
//         );
//       }

//       setTotalDataCount(filteredData.length);
//       setStockIdeasData(filteredData);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchArchivedIdeas();
//   }, []);

//   async function fetchArchivedIdeas() {
//     try {
//       const response = await axios.get(`${SERVER_URL}/finsha/archived-ideas`, {
//         withCredentials: true,
//       });

//       const data = response.data;
//       let filteredData = data;
//       if (isUserSubscribed) {
//         filteredData = data.filter(
//           (stock) => stock.Visibility === userSubscriptionTier
//         );
//       }
//       setArchivedStockIdeas(filteredData);
//     } catch (error) {
//       console.error("Error fetching archived stock ideas:", error);
//     }
//   }

//   const handleDownload = (link) => {
//     const a = document.createElement("a");
//     a.href = link;
//     a.download = "IdeaReport.pdf";
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   };

//   const handleResetProfit = async () => {
//     try {
//       const response = await axios.post(
//         `${SERVER_URL}/finsha/reset-total-profit`,
//         { userId: user._id },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//           withCredentials: true,
//         }
//       );

//       if (response.headers["content-type"].includes("application/json")) {
//         const data = response.data;
//         if (response.status === 200) {
//           updateUser({ ...user, totalProfit: 0 });
//         }
//       } else {
//         const text = response.data;
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       handleCloseDialog();
//     }
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleInvestmentSet = () => {
//     fetchData();
//     window.location.reload();
//   };

//   const handleSortAndFilter = () => {
//     let sortedData = [...stockIdeasData];

//     // Function to convert duration string to days
// function durationToDays(duration) {
//   const [number, unit] = duration.split(" ");
//   const num = parseFloat(number);

//   // Assuming unit is always 'month' or 'months', we convert months to days.
//   const daysInMonth = 30; // Average month length in days

//   return num * daysInMonth; // Convert to days for comparison
// }

//     if (sortAZ) {
//       sortedData.sort((a, b) =>
//         a["Company Name"].localeCompare(b["Company Name"])
//       );
//     } else if (sortZA) {
//       sortedData.sort((a, b) =>
//         b["Company Name"].localeCompare(a["Company Name"])
//       );
//     } else if (sortLatest) {
//       sortedData.sort(
//         (a, b) =>
//           new Date(b["Recommendation Date"]) -
//           new Date(a["Recommendation Date"])
//       );
//     } else if (sortOldest) {
//       sortedData.sort(
//         (a, b) =>
//           new Date(a["Recommendation Date"]) -
//           new Date(b["Recommendation Date"])
//       );
//     } else if (sortLowToHigh) {
//       sortedData.sort((a, b) => a.CurrentPrice - b.CurrentPrice);
//     } else if (sortHighToLow) {
//       sortedData.sort((a, b) => b.CurrentPrice - a.CurrentPrice);
//     }
//     else if (sortDurationLowToHigh) {
//       sortedData.sort(
//         (a, b) =>
//           durationToDays(a["Holding Duration"]) - durationToDays(b["Holding Duration"])
//       );
//     } else if (sortDurationHighToLow) {
//       sortedData.sort(
//         (a, b) =>
//           durationToDays(b["Holding Duration"]) - durationToDays(a["Holding Duration"])
//       );
//     }
//      else if (sortPotentialLowToHigh) {
//       sortedData.sort((a, b) => a["Potential Left"] - b["Potential Left"]);
//     } else if (sortPotentialHighToLow) {
//       sortedData.sort((a, b) => b["Potential Left"] - a["Potential Left"]);
//     }

//     if (filterBuy) {
//       sortedData = sortedData.filter((stock) => stock.Recommendation === "Buy");
//       sortedData = sortedData.filter((stock) => stock.Recommendation === "Buy");
//     } else if (filterSell) {
//       sortedData = sortedData.filter(
//         (stock) => stock.Recommendation === "Sell"
//       );
//       sortedData = sortedData.filter(
//         (stock) => stock.Recommendation === "Sell"
//       );
//     } else if (filterHold) {
//       sortedData = sortedData.filter(
//         (stock) => stock.Recommendation === "Hold"
//       );
//     }

//     if (filterBTST) {
//       sortedData = sortedData.filter(
//         (stock) => stock.portfolioStrategy === "BTST"
//       );
//     } else if (filterBTSM) {
//       sortedData = sortedData.filter(
//         (stock) => stock.portfolioStrategy === "BTSM"
//       );
//     } else if (filterBTSF) {
//       sortedData = sortedData.filter(
//         (stock) => stock.portfolioStrategy === "BTSF"
//       );
//     } else if (filterBTSW) {
//       sortedData = sortedData.filter(
//         (stock) => stock.portfolioStrategy === "BTSW"
//       );
//     }

//     return sortedData;
//   };

//   const groupByDate = (data) => {
//     return data.reduce((acc, curr) => {
//       (acc[curr.Date] = acc[curr.Date] || []).push(curr);
//       return acc;
//     }, {});
//   };

//   const groupedData = groupByDate(stockIdeasData);
//   const buyCount = stockIdeasData.filter(
//     (stock) => stock.Recommendation === "Buy"
//   ).length;
//   const holdCount = stockIdeasData.filter(
//     (stock) => stock.Recommendation === "Hold"
//   ).length;
//   const sellCount = stockIdeasData.filter(
//     (stock) => stock.Recommendation === "Sell"
//   ).length;

//   const resetFilters = () => {
//     setSortAZ(false);
//     setSortZA(false);
//     setSortLatest(false);
//     setSortOldest(false);
//     setSortLowToHigh(false);
//     setSortHighToLow(false);
//     setSortDurationLowToHigh(false);
//     setSortDurationHighToLow(false);
//     setSortPotentialLowToHigh(false);
//     setSortPotentialHighToLow(false);
//     setFilterBuy(false);
//     setFilterSell(false);
//     setFilterHold(false);
//     setFilterBTST(false);
//     setFilterBTSM(false);
//     setFilterBTSF(false);
//     setFilterBTSW(false);
//   };

//   const sortedStockIdeasData = handleSortAndFilter();

//   return (
//     <div className="font-roboto flex flex-col lg:flex-row lg:justify-between mt-16 max-lg:flex-col-reverse">
//       {isUserSubscribed && (
//         <div className="lg:flex">
//           {/* Filters Section */}
//           <div className="lg:sticky bg-gray-100 lg:w-60 lg:top-0 lg:flex lg:flex-col lg:h-screen overflow-y-auto">
//             <button
//               className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 my-2 lg:hidden mx-2"
//               onMouseEnter={() => setShowFilters(true)}
//             >
//               Toggle Filters
//             </button>
//             <div
//               ref={filterRef}
//               className={`absolute top-12 left-0 w-64 p-4 bg-white rounded-lg shadow-md space-y-6 z-50 lg:static lg:w-full lg:bg-transparent lg:shadow-none lg:p-0 lg:block ${
//                 showFilters ? "" : "hidden lg:block"
//               }`}
//             >
//               {/* Filter options */}
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Lexicographically
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortAZ}
//                     onChange={() => {
//                       setSortAZ(!sortAZ);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">A-Z</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortZA}
//                     onChange={() => {
//                       setSortZA(!sortZA);
//                       setSortAZ(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Z-A</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Date
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortLatest}
//                     onChange={() => {
//                       setSortLatest(!sortLatest);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Latest First</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortOldest}
//                     onChange={() => {
//                       setSortOldest(!sortOldest);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Oldest First</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Price
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortLowToHigh}
//                     onChange={() => {
//                       setSortLowToHigh(!sortLowToHigh);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Low to High</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortHighToLow}
//                     onChange={() => {
//                       setSortHighToLow(!sortHighToLow);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">High to Low</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Duration
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortDurationLowToHigh}
//                     onChange={() => {
//                       setSortDurationLowToHigh(!sortDurationLowToHigh);
//                       setSortDurationHighToLow(false);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Low to High</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortDurationHighToLow}
//                     onChange={() => {
//                       setSortDurationHighToLow(!sortDurationHighToLow);
//                       setSortDurationLowToHigh(false);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                       setSortPotentialLowToHigh(false);
//                       setSortPotentialHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">High to Low</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Potential Left
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortPotentialLowToHigh}
//                     onChange={() => {
//                       setSortPotentialLowToHigh(!sortPotentialLowToHigh);
//                       setSortPotentialHighToLow(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Low to High</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={sortPotentialHighToLow}
//                     onChange={() => {
//                       setSortPotentialHighToLow(!sortPotentialHighToLow);
//                       setSortPotentialLowToHigh(false);
//                       setSortDurationLowToHigh(false);
//                       setSortDurationHighToLow(false);
//                       setSortAZ(false);
//                       setSortZA(false);
//                       setSortLatest(false);
//                       setSortOldest(false);
//                       setSortLowToHigh(false);
//                       setSortHighToLow(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">High to Low</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Recommendation
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterBuy}
//                     onChange={() => {
//                       setFilterBuy(!filterBuy);
//                       setFilterSell(false);
//                       setFilterHold(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Buy</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterSell}
//                     onChange={() => {
//                       setFilterSell(!filterSell);
//                       setFilterBuy(false);
//                       setFilterHold(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Sell</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterHold}
//                     onChange={() => {
//                       setFilterHold(!filterHold);
//                       setFilterBuy(false);
//                       setFilterSell(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">Hold</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <label className="text-lg font-semibold text-gray-700">
//                   Portfolio Strategy
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterBTST}
//                     onChange={() => {
//                       setFilterBTST(!filterBTST);
//                       setFilterBTSM(false);
//                       setFilterBTSF(false);
//                       setFilterBTSW(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">BTST</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterBTSM}
//                     onChange={() => {
//                       setFilterBTSM(!filterBTSM);
//                       setFilterBTST(false);
//                       setFilterBTSF(false);
//                       setFilterBTSW(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">BTSM</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterBTSF}
//                     onChange={() => {
//                       setFilterBTSF(!filterBTSF);
//                       setFilterBTST(false);
//                       setFilterBTSM(false);
//                       setFilterBTSW(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">BTSF</span>
//                 </label>
//                 <label className="flex items-center space-x-2">
//                   <input
//                     type="checkbox"
//                     checked={filterBTSW}
//                     onChange={() => {
//                       setFilterBTSW(!filterBTSW);
//                       setFilterBTST(false);
//                       setFilterBTSM(false);
//                       setFilterBTSF(false);
//                     }}
//                     className="form-checkbox text-blue-600"
//                   />
//                   <span className="text-gray-600">BTSW</span>
//                 </label>
//               </div>
//               <div className="space-y-2">
//                 <button
//                   className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
//                   onClick={resetFilters}
//                 >
//                   Reset Filters
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Stock card Section */}
//       <div className="relative w-full lg:mx-auto lg:w-[43dvw] xl:w-[54dvw] max-lg:h-[90dvh] max-lg:overflow-y-auto">
//         <div className={`${expandedCard !== null ? "blur-background" : ""}`}>
//           {loading && <StockIdeaSkeleton />}
//           {isUserSubscribed ? (
//             totalDataCount === 0 ? (
//               <div className="welcome-message">
//                 <h2>
//                   <i className="fas fa-lightbulb"></i> Stock Idea
//                 </h2>
//                 <p>Welcome aboard</p>
//                 <p>
//                   We're currently deep diving into market data, crunching the
//                   latest numbers, and forging new stock ideas perfect for your
//                   subscription Plan.
//                 </p>
//                 <p>
//                   We know anticipation can be tough, but rest assured, our team
//                   is on it, curating the finest insights just for you. While you
//                   wait, why not explore other sections of our platform?
//                 </p>
//                 <p>
//                   <i className="fas fa-heart"></i> Your trust means everything
//                   to us. Stay tuned for exciting insights!
//                 </p>
//               </div>
//             ) : (
//               <div className="mt-4 flex justify-center items-center mb-8">
//                 <div className="flex flex-col gap-6 w-[97%] lg:w-[43dvw] xl:w-[54dvw] max-w-[500px] mx-auto ">
//                   {sortedStockIdeasData.map((stock, index) => {
//                     const isExpanded = expandedCard === index;
//                     const recommendedInvestment =
//                       user.investmentValue *
//                       (stock["recommendedPercentage"] / 100);
//                     const totalSharesToBuy = stock["recommendedShares"];
//                     const investmentPercentage =
//                       (stock["investedAmount"] / user.investmentValue) * 100;

//                     return (
//                       <div
//                         className={`stockidea__stock-card max-sm:text-sm ${stock["Recommendation"]}`}
//                         ref={isExpanded ? expandedCardRef : null}
//                         key={index}
//                         onClick={() =>
//                           setExpandedCard(isExpanded ? null : index)
//                         }
//                       >
//                         <div
//                           className={`stockidea__stock-card-content ${
//                             isExpanded ? "expanded" : ""
//                           }`}
//                         >
//                           <div className="flex justify-between">
//                             <h5
//                               className="max-sm:text-[17px] text-[19px] rounded-2xl text-green-900 font-bold max-sm:max-w-[30%] max-w-[45%] line-clamp-2 hover:line-clamp-none"
//                               onClick={() => handleSelectResult(stock)}
//                             >
//                               {stock["Company Name"]}
//                             </h5>
//                             <div className="flex flex-end justify-center items-center">
//                               <CurrentPrice symbol={stock["Symbol"]} />
//                               <PricePercentage symbol={stock["Symbol"]} />
//                             </div>
//                           </div>

//                           <div className="">
//                             <div className="absolute bg-green-200 -inset-0 -rotate-45 opacity-40"></div>

//                             <div className="flex justify-between max-sm:flex-col">
//                               <div className="">
//                                 <span className="font-semibold text-green-950">
//                                   Buy Price:
//                                 </span>
//                                 <span className="">{stock["Buy Option"]}</span>
//                               </div>

//                               <div className="">
//                                 <span className="font-semibold text-green-950">
//                                   Term:
//                                 </span>
//                                 <span className="">
//                                   {stock["Holding Duration"]}
//                                 </span>
//                                 <span>({stock["portfolioStrategy"]})</span>
//                               </div>
//                             </div>

//                             <div className="flex justify-between max-sm:flex-col">
//                               <div className="">
//                                 <span className="font-semibold text-green-950">
//                                   Target Price:
//                                 </span>
//                                 <span className="">
//                                   {stock["Target Price"]}
//                                 </span>
//                               </div>

//                               <div>
//                                 <span className="font-semibold text-green-950">
//                                   Stop Loss:
//                                 </span>
//                                 <span className="">{stock["Stop Loss"]}</span>
//                               </div>
//                             </div>
//                           </div>

//                           <div className="flex items-center pt-2 absolute max-sm:right-4 max-sm:top-1/4 top-1/3 right-[42%]">
//                             <span
//                               className={`recommendation-data h-[56px] w-[56px] rounded-full flex justify-center items-center max-sm:h-[62px] max-sm:w-[62px] ${
//                                 stock["Recommendation"]
//                                   ? stock["Recommendation"].toLowerCase()
//                                   : ""
//                               }`}
//                             >
//                               {stock["Recommendation"]}
//                             </span>
//                           </div>

//                           <div className="mr-4 flex justify-between ml-1">
//                             <div className="text-green-950 font-bold max-sm:text-sm">
//                               <PotentialLeft
//                                 symbol={stock["Symbol"]}
//                                 targetPrice={stock["Target Price"]}
//                                 className="potential-design"

//                               />
//                             </div>

//                             <div className="">
//                               <span className="text-green-950 font-bold max-sm:text-sm">
//                                 Invest:
//                               </span>
//                               <span className="text-green-950 max-sm:text-sm">
//                                 {stock["recommendedPercentage"]}%
//                               </span>{" "}
//                             </div>
//                           </div>

//                           {isExpanded && (
//                             <>
//                               {showInvestmentInput ? (
//                                 <div className="investment-value-input-wrapper__stockCard">
//                                   <InvestmentValueInput
//                                     initialInvestmentValue={
//                                       user.investmentValue
//                                     }
//                                     userId={user._id}
//                                     stockIdeasData={stockIdeasData}
//                                   />
//                                 </div>
//                               ) : (
//                                 <>
//                                   <div
//                                     className={`recomended-container border-2 border-white shadow-inner shadow-slate-400 ${
//                                       !user.investmentValue ? "blur" : ""
//                                     }`}
//                                   >
//                                     <div className="stockideaCard__recommeded_investment">
//                                       ₹ {recommendedInvestment.toFixed(2)}
//                                     </div>
//                                     <div className="w-[80%] max-sm:w-[45%] max-md:w-[40%]">
//                                       <CircularProgressbar
//                                         value={investmentPercentage}
//                                         text={`${investmentPercentage.toFixed(
//                                           2
//                                         )}%`}
//                                         styles={buildStyles({
//                                           textColor: "green",
//                                           pathColor: "green",
//                                           trailColor: "#d6d6d6",
//                                         })}
//                                       />
//                                     </div>
//                                     <div className="recomended-text">
//                                       INVEST: ₹{" "}
//                                       {stock["investedAmount"].toFixed(2)}
//                                     </div>
//                                     <div className="share-text-div">
//                                       You can buy:{" "}
//                                       <span className="share-count-span">
//                                         {totalSharesToBuy}
//                                       </span>{" "}
//                                       shares.
//                                     </div>
//                                   </div>
//                                   <div className="NetGain-div border-2 border-white shadow-inner shadow-slate-400">
//                                     <h4 className="stockideacard__profitloss">
//                                       Profit-Loss:
//                                     </h4>
//                                     {user.investmentValue ? (
//                                       <NetGain
//                                         className="net-gain-component"
//                                         symbol={stock["Symbol"]}
//                                         staticPrice={stock["CurrentPrice"]}
//                                         shareToBuy={totalSharesToBuy}
//                                       />
//                                     ) : (
//                                       <PercentageGain
//                                         className="net-gain-component"
//                                         symbol={stock["Symbol"]}
//                                         staticPrice={stock["CurrentPrice"]}
//                                         shareToBuy={totalSharesToBuy}
//                                       />
//                                     )}
//                                   </div>
//                                 </>
//                               )}
//                               <p className="line-clamp-2 hover:line-clamp-none font-bold">
//                                 {stock["Message"]}
//                               </p>
//                               <div className="recomendationdate">
//                                 DATE :{stock["Recommendation Date"]}
//                               </div>

//                               <button
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   handleDownload(stock.IdeaReportLink);
//                                 }}
//                                 className=""
//                               >
//                                 <AiOutlineDownload size={24} />
//                               </button>
//                             </>
//                           )}
//                           <button
//                             className="read-more-button absolute right-0 max-sm:bottom-4 bottom-2"
//                             onClick={(e) => {
//                               e.stopPropagation();
//                               setExpandedCard(isExpanded ? null : index);
//                             }}
//                           >
//                             <img
//                               className="animate-pulse"
//                               src={isExpanded ? UpArrows : Doubledown}
//                               alt={isExpanded ? "Show Less" : "Read More"}
//                               style={{
//                                 width: "20px",
//                                 height: "20px",
//                                 marginInline: "2px",
//                               }} // Adjust size as needed
//                             />
//                           </button>
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             )
//           ) : (
//             <>
//               {totalDataCount === 0 ? (
//                 <div className="welcome-message">
//                   <h2>
//                     <i className="fas fa-lightbulb"></i> Stock Idea
//                   </h2>
//                   <p>Welcome aboard</p>
//                   <p>
//                     We're currently deep diving into market data, crunching the
//                     latest numbers, and forging new stock ideas perfect for your
//                     subscription Plan.
//                   </p>
//                   <p>
//                     We know anticipation can be tough, but rest assured, our
//                     team is on it, curating the finest insights just for you.
//                     While you wait, why not explore other sections of our
//                     platform?
//                   </p>
//                   <p>
//                     <i className="fas fa-heart"></i> Your trust means everything
//                     to us. Stay tuned for exciting insights!
//                   </p>
//                 </div>
//               ) : (
//                 <div className="w-full">
//                   <UnsubscribedStockIdeaGrid
//                     data={sortedStockIdeasData}
//                     isUserSubscribed={isUserSubscribed}
//                   />
//                 </div>
//               )}
//             </>
//           )}
//         </div>

//         {expandedCard !== null && (
//           <div className="expanded-card-wrapper max-h-screen overflow-auto no-scrollbar">
//             {sortedStockIdeasData.map((stock, index) => {
//               if (expandedCard !== index) return null;

//               const recommendedInvestment =
//                 user.investmentValue * (stock["recommendedPercentage"] / 100);
//               const totalSharesToBuy = stock["recommendedShares"];
//               const investmentPercentage =
//                 (stock["investedAmount"] / user.investmentValue) * 100;

//               return (
//                 <div
//                   className={`rounded-lg relative w-[90%] max-sm:w-[95%] text-green-950 max-h-[90dvh] my-auto overflow-y-auto no-scrollbar ${stock["Recommendation"]}`}
//                   ref={expandedCardRef}
//                   key={index}
//                 >
//                   <div className="bg-white border-2 border-white shadow-2xl shadow-slate-900 rounded-xl transition-all duration-500 ease-in-out flex max-md:text-sm max-md:flex-col">
//                     <button
//                       className="absolute -top-2 -right-2 py-2 px-[11px] bg-red-800 text-white z-50 shadow-inner shadow-red-950"
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         setExpandedCard(null);
//                       }}
//                     >
//                       <FontAwesomeIcon icon={faTimes} />
//                     </button>
//                     <div className="w-full md:w-[70%] m-2">
//                       <div className="flex justify-between items-center shadow-inner shadow-slate-500 p-2 rounded-lg max-md:flex-col max-md:mr-3">
//                         <h5
//                           className="max-md:text-xl text-2xl font-bold text-green-950 cursor-pointer transition-transform transform hover:-translate-y-1 duration-300"
//                           onClick={() => handleSelectResult(stock)}
//                         >
//                           {stock["Company Name"]}
//                         </h5>
//                         <div className="flex items-center">
//                           <CurrentPrice symbol={stock["Symbol"]} />
//                           <PricePercentage symbol={stock["Symbol"]} />
//                         </div>
//                       </div>
//                       <h5 className="text-green-950 text-lg text-center max-md:text-base">
//                         {stock["Sector"]}
//                       </h5>

//                       <div className="flex justify-center items-center gap-1 max-md:flex-col">
//                         <div className="p-2 w-[70%] max-md:w-full max-md:mr-3 bg-slate-100 flex gap-2 flex-col">
//                           <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
//                             <span className="font-semibold text-green-950">
//                               Target Price:
//                             </span>
//                             <span>{stock["Target Price"]}</span>
//                           </div>
//                           <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
//                             <span className="font-semibold text-green-950">
//                               Stop Loss:
//                             </span>
//                             <span>{stock["Stop Loss"]}</span>
//                           </div>
//                           <div className="flex justify-around shadow-inner shadow-slate-500 rounded-lg">
//                             <span className="font-semibold text-green-950">
//                               Term:
//                             </span>
//                             <span>{stock["Holding Duration"]}</span>
//                           </div>
//                         </div>

//                         <div className="w-full md:w-[30%] flex justify-center items-center shadow-inner shadow-slate-500 bg-green-100 p-2 rounded-lg max-md:mr-4">
//                           <div className="flex flex-col justify-center items-center max-md:flex-row">
//                             <span className="font-semibold text-xl text-green-950 max-md:text-lg max-sm:text-base">
//                               Portfolio Strategy:
//                             </span>
//                             <span className="text-green-950 text-lg max-md:text-base max-sm:text-sm">
//                               {stock["portfolioStrategy"]}
//                             </span>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="">
//                         <div className="my-2 mx-2 flex justify-around items-center gap-1 max-sm:flex-wrap">
//                           <div className="">
//                             <PotentialLeft
//                               symbol={stock["Symbol"]}
//                               targetPrice={stock["Target Price"]}
//                               className="potential-new"
//                             />
//                           </div>
//                           <div className="shadow-inner shadow-slate-400 p-2 flex justify-center items-center rounded-lg">
//                             {user.investmentValue ? (
//                               <>
//                                 <h4 className="text-green-950 font-semibold text-lg max-md:text-sm">
//                                   Profit-Loss:
//                                 </h4>
//                                 <NetGain
//                                   className="flex flex-row justify-center items-center pl-2"
//                                   symbol={stock["Symbol"]}
//                                   staticPrice={stock["CurrentPrice"]}
//                                   shareToBuy={totalSharesToBuy}
//                                 />
//                               </>
//                             ) : (
//                               <>
//                                 <h4 className="text-green-950 font-semibold text-lg max-md:text-sm">
//                                   Percentage_Gain:
//                                 </h4>
//                                 <PercentageGain
//                                   className="flex flex-row justify-center items-center pl-2"
//                                   symbol={stock["Symbol"]}
//                                   staticPrice={stock["CurrentPrice"]}
//                                   shareToBuy={totalSharesToBuy}
//                                 />
//                               </>
//                             )}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-white max-md:mr-2 max-lg:mb-2 lg:mb-4 transition-all duration-300 ease-in-out hover:bg-gray-800">
//                         <div className="line-clamp-4 hover:line-clamp-none  hover:overflow-hidden  hover:no-scrollbar">
//                           {stock["Message"]}
//                         </div>
//                       </div>

//                       <button
//                         onClick={(e) => {
//                           e.stopPropagation();
//                           handleDownload(stock.IdeaReportLink);
//                         }}
//                         className="max-md:absolute max-md:-bottom-60 max-md:right-1 max-md:z-50 p-2 rounded-lg bg-gray-600 shadow-inner shadow-gray-950 transform hover:scale-105 transition duration-300 text-white"
//                       >
//                         <span className="hidden md:inline-block">
//                           Download Full Recommendation Report
//                         </span>
//                         <AiOutlineDownload
//                           size={24}
//                           className="text-white inline"
//                         />
//                       </button>
//                     </div>

//                     <div className="w-full md:w-[30%] relative p-2">
//                       <div className="flex justify-center items-center flex-col w-full gap-2">
//                         <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
//                           <span className="font-semibold text-green-950">
//                             Recommendation:
//                           </span>
//                           <span
//                             className={`recommendation-data ${
//                               stock["Recommendation"]
//                                 ? stock["Recommendation"].toLowerCase()
//                                 : ""
//                             }`}
//                           >
//                             {stock["Recommendation"]}
//                           </span>
//                         </div>

//                         <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
//                           <span className="font-semibold text-green-950">
//                             Buy Price:
//                           </span>
//                           <span>{stock["Buy Option"]}</span>
//                         </div>

//                         <div className="shadow-inner shadow-slate-500 p-2 flex justify-center items-center w-full rounded-lg md:flex-col lg:flex-row">
//                           <span className="font-semibold text-green-950">
//                             Recommendation Date:
//                           </span>
//                           <span className="">
//                             {stock["Recommendation Date"]}
//                           </span>
//                         </div>
//                       </div>

//                       {showInvestmentInput ? (
//                         <div className="investment-value-input-wrapper__stockCard">
//                           <InvestmentValueInput
//                             initialInvestmentValue={user.investmentValue}
//                             userId={user._id}
//                             stockIdeasData={stockIdeasData}
//                             isButton={false}
//                             onInvestmentSet={handleInvestmentSet}
//                           />
//                         </div>
//                       ) : (
//                         <>
//                           {recommendedInvestment &&
//                           recommendedInvestment > 0 ? (
//                             <>
//                               <div
//                                 className={`recomended-container border-2 border-white shadow-inner shadow-slate-400`}
//                               >
//                                 <div className="stockideaCard__recommeded_investment">
//                                   ₹ {recommendedInvestment.toFixed(2)}
//                                 </div>
//                                 <div className="w-[65%] max-sm:w-[45%] max-md:w-[40%]">
//                                   <CircularProgressbar
//                                     value={investmentPercentage}
//                                     text={`${investmentPercentage.toFixed(2)}%`}
//                                     styles={buildStyles({
//                                       textColor: "green",
//                                       pathColor: "green",
//                                       trailColor: "#d6d6d6",
//                                     })}
//                                   />
//                                 </div>
//                                 <div className="recomended-text">
//                                   INVEST: ₹ {stock["investedAmount"].toFixed(2)}
//                                 </div>
//                                 <div className="share-text-div">
//                                   You can buy:{" "}
//                                   <span className="share-count-span">
//                                     {totalSharesToBuy}
//                                   </span>{" "}
//                                   shares.
//                                 </div>
//                               </div>
//                             </>
//                           ) : (
//                             <>
//                               <div
//                                 className={`relative flex flex-col items-center justify-center p-6 rounded-lg border-2 border-white shadow-inner shadow-slate-400 bg-gray-300 text-white`}
//                               >
//                                 <div className="absolute inset-0 flex items-center justify-center z-20">
//                                   <div
//                                     className="relative px-6 py-3 w-full max-w-lg text-center text-white font-bold shadow-lg cursor-pointer"
//                                     style={{
//                                       background:
//                                         "linear-gradient(to right, rgba(56,189,248,1), rgba(29,78,216,1))",
//                                     }}
//                                   >
//                                     <InvestmentValueInput
//                                       initialInvestmentValue={
//                                         user.investmentValue
//                                       }
//                                       userId={user._id}
//                                       stockIdeasData={stockIdeasData}
//                                       isButton={false} // Renders as plain text
//                                       onInvestmentSet={handleInvestmentSet}
//                                     />
//                                   </div>
//                                 </div>

//                                 <div className="flex flex-col items-center justify-center blur-sm pointer-events-none select-none">
//                                   <div className="stockideaCard__recommended_investment text-center">
//                                     ₹ {recommendedInvestment.toFixed(2)}
//                                   </div>
//                                   <div className="w-[65%] max-sm:w-[45%] max-md:w-[40%] my-4">
//                                     <CircularProgressbar
//                                       value={100}
//                                       text={`${20}%`}
//                                       styles={buildStyles({
//                                         textColor: "green",
//                                         pathColor: "green",
//                                         trailColor: "#d6d6d6",
//                                       })}
//                                     />
//                                   </div>
//                                   <div className="recommended-text text-center">
//                                     INVEST: ₹{" "}
//                                     {stock["investedAmount"].toFixed(2)}
//                                   </div>
//                                   <div className="share-text-div text-center">
//                                     You can buy:{" "}
//                                     <span className="share-count-span">50</span>{" "}
//                                     shares.
//                                   </div>
//                                 </div>
//                               </div>
//                             </>
//                           )}
//                         </>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         )}
//       </div>

//       {/* Active Stock Ideas Section */}
//       {isUserSubscribed && (
//         <div className="lg:sticky shadow-inner shadow-slate-500 border-2 border-white lg:top-0 lg:self-start lg:h-screen lg:overflow-auto lg:flex lg:flex-col lg:items-end p-4 lg:justify-center relative">
//           <h1 className="text-2xl text-green-950 font-bold text-center mb-4 lg:mx-auto lg:pl-6 mt-3">
//             Active Stock-Ideas
//           </h1>
//           <div className="flex flex-wrap items-center justify-around gap-4 mt-4 mb-4 lg:flex-col lg:justify-center lg:w-[100%]">
//             <div className="stockidea__userSubscription_name md:shadow-inner md:shadow-slate-500 text-center lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
//               <b className="flex items-center gap-2 justify-center">
//                 <StarBorderIcon className="stockIdea__staricon" />
//                 {userSubscriptionTier}
//               </b>
//             </div>
//             <div className="stockidea__totalActiveStock_container md:shadow-inner md:shadow-slate-500 text-center lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
//               <b>
//                 <TipsAndUpdatesIcon className="stockIdea__bulbicon" />
//                 Active Ideas :
//               </b>
//               <b className="dashborad_stockideadata">{totalDataCount}</b>
//             </div>
//             <div className="stockIdea__totalprofit_loss_main flex justify-center md:shadow-inner md:shadow-slate-500 lg:w-full hover:transform hover:-translate-y-2 hover:text-blue-800">
//               <b className="totalprofit__title flex items-center gap-2 justify-center">
//                 <ImportExportIcon className="stockidea__updownIcon" />
//                 Total Profit/Loss
//               </b>
//               :
//               <b className="dashborad_stockideadata">
//                 {user.totalProfit.toFixed(2)}
//               </b>
//             </div>
//             {openDialog && (
//               <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//                 <div className="bg-white p-6 rounded-md shadow-md w-96">
//                   <h2 className="text-xl font-bold mb-4 text-center">
//                     Reset Profit
//                   </h2>
//                   <p className="mb-4 text-center">
//                     Are you sure you want to reset the profit?
//                   </p>
//                   <div className="flex justify-end space-x-2">
//                     <button
//                       className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
//                       onClick={handleCloseDialog}
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       className="px-4 py-2 bg-blue-600 text-white rounded-md"
//                       onClick={handleResetProfit}
//                     >
//                       Yes, Reset
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//             {user.investmentValue !== 0 && (
//               <>
//                 <div className="stockIdea__currentinvestment text-center flex justify-center lg:w-full md:shadow-inner md:shadow-slate-500 hover:transform hover:-translate-y-2 hover:text-blue-800">
//                   <b className="flex items-center gap-2 justify-center">
//                     <FaRegMoneyBillAlt style={{ fontSize: 22 }} />
//                     Current Capital
//                   </b>
//                   :
//                   <b className="dashborad_stockideadata">
//                     ₹ {(user.investmentValue + user.totalProfit).toFixed(2)}
//                   </b>
//                 </div>
//                 <div className="stockIdea__remainingInvestment text-center flex justify-center lg:w-full md:shadow-inner md:shadow-slate-500 hover:transform hover:-translate-y-2 hover:text-blue-800">
//                   <b className="flex items-center gap-2 justify-center">
//                     <AccountBalanceWalletIcon className="stockidea__walletIcons" />
//                     Free Capital :
//                   </b>
//                   <b className="dashborad_stockideadata">
//                     ₹ {user.remainingInvestment.toFixed(2)}
//                   </b>
//                 </div>
//               </>
//             )}
//           </div>
//           <div className="investment_input_div relative px-1 pb-1 max-lg:flex max-lg:justify-center lg:flex lg:flex-col lg:justify-end w-full shadow-inner shadow-slate-600 rounded-lg">
//             <div
//               className="p-2 w-full text-white text-center mt-2"
//               style={{
//                 background:
//                   "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
//                 boxShadow:
//                   "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
//                 borderRadius: "50px", // More rounded corners for a button-like appearance
//                 border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
//               }}
//             >
//               <ArchivedIdeas
//                 archivedStockIdeas={archivedStockIdeas}
//                 userSubscriptionTier={userSubscriptionTier}
//                 isButton={false}
//               />
//             </div>
//             <div
//               className="p-2 w-full text-white text-center rounded-lg cursor-pointer"
//               style={{
//                 background:
//                   "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
//                 boxShadow:
//                   "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
//                 borderRadius: "50px", // More rounded corners for a button-like appearance
//                 border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
//               }}
//             >
//               <InvestmentValueInput
//                 initialInvestmentValue={user.investmentValue}
//                 userId={user._id}
//                 stockIdeasData={stockIdeasData}
//                 onInvestmentSet={handleInvestmentSet}
//                 isButton={false}
//               />
//             </div>
//             <div
//               className="px-2 py-2 text-white rounded-lg cursor-pointer flex justify-center  lg:w-full"
//               style={{
//                 background:
//                   "radial-gradient(circle, rgba(56,189,158,1) 0%, rgba(5,150,105,1) 100%)",
//                 boxShadow:
//                   "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
//                 borderRadius: "50px", // More rounded corners for a button-like appearance
//                 border: "2px solid rgba(5,150,105,1)", // Adding a subtle border for a 3D effect
//               }}
//               onClick={handleOpenDialog}
//             >
//               <ImportExportIcon
//                 style={{ color: "white" }}
//                 className="stockidea__updownIcon"
//               />
//               Total Profit/Loss :
//               <b className="dashborad_stockideadata_prift_loss">
//                 {user.totalProfit.toFixed(2)}
//               </b>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default StockIdeas;
