import React from "react";
import { Link } from "react-router-dom";
import CurrentPrice from "../../../Screener/components/stock-price";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MUIDataTable from "mui-datatables";
import "./HoldingPortfolio.css";


const getHyphenatedName = (name) => {
  return name ? name.replace(/ /g, "-") : "";
};


function HoldingPortfolio({
  portfolio,
  editEntry,
  handleSell,
  handleAddNew,
}) {
  const columns = [
    "Sl No.",
    {
      name: "Stock name",
      options: {
        customBodyRender: (value) => {
          const companyNameWithHyphens = getHyphenatedName(value);
          return (
            <Link
            className="Portfolio_stock-name-link"
            to={`/company-name/${encodeURIComponent(companyNameWithHyphens)}`}
          >
            {value}
          </Link>
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value) => {
          const statusClass =
            value.toLowerCase() === "compliant"
              ? "text-green-600 text-sm"
              : "text-orange-600 text-sm";
          return (
            <div className={`business-status ${statusClass}`}>{value}</div>
          );
        },
      },
    },
    {
      name: "Current price",
      options: {
        customBodyRender: (value) => {
          return (
            <CurrentPrice className="portfolio_current-price" symbol={value} />
          );
        },
      },
    },
    {
      name: "Buy-Price",
      options: {
        customBodyRender: (value) => {
          return <div className="portfolio_company-price-value">{value}</div>;
        },
      },
    },
    {
      name: "Quantity",
      options: {
        customBodyRender: (value) => {
          return <div className="portfolio_quantity-value">{value}</div>;
        },
      },
    },
    {
      name: "Profit/Loss",
      options: {
        customBodyRender: (value) => {
          return (
            <div className={`profit-loss ${value >= 0 ? "green" : "red"}`}>
              {value >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              {Math.abs(value).toFixed(2)}
            </div>
          );
        },
      },
    },
    {
      name: "Purgable Income",
      options: {
        customBodyRender: (value) => {
          return <div className="purgable-income-value">{value}</div>;
        },
      },
    },
    {
      name: "Target Price (%)",
      options: {
        customBodyRender: (value) => {
          return <div className="target-price-value">{value}</div>;
        },
      },
    },
    {
      name: "Stop Loss (%)",
      options: {
        customBodyRender: (value) => {
          return <div className="stop-loss-value">{value}</div>;
        },
      },
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          return (
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon
                className="text-blue-500 hover:text-blue-700 cursor-pointer transition-colors duration-300 ease-in-out"
                icon={faEdit}
                onClick={() => editEntry(rowIndex)}
              />
              <button
                className="px-3 py-1 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300 ease-in-out"
                onClick={() => handleSell(rowIndex)}
              >
                Sell
              </button>
            </div>
          );
        },
      },
    }
    
  ];

  const data = portfolio.map((entry, index) => {
    return [
      index + 1,
      entry.companyName,
      entry.businessStatus,
      entry.symbol, // Assuming CurrentPrice can derive the price from the symbol
      entry.companyPrice,
      entry.quantity,
      entry.profitOrLoss,
      Number(entry.purgableIncome).toFixed(2),
      Number(entry.targetPrice).toFixed(2),
      Number(entry.stopLoss).toFixed(2),
    ];
  });

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    search: false,
    download: false,
    print:false,
    filter:false,
    viewColumns: false,
    tableBodyHeight: "auto",
    setTableProps: () => {
      return {
        className: "custom-mui-table", // Add a custom class to the MUIDataTable
      };
    },
  };

  return (
    <div className="holding_portfolio">
      <MUIDataTable
        title={<div className="holding-portfolio-title">Holding Portfolio</div>}
        data={data}
        columns={columns}
        options={options}
        className="holding_portfolio"
      />
    <button
  className="px-4 py-2 ml-2 bg-gradient-to-r from-green-400 to-green-600 text-white font-semibold rounded-lg shadow-md hover:from-green-500 hover:to-green-700 transform transition-transform duration-300 ease-in-out hover:scale-105"
  onClick={handleAddNew}
>
 + Add New Stock
</button>

    </div>
  );
}

export default HoldingPortfolio;