import React, { useState, useEffect, useContext } from "react";
import "./home.css";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import FinshaLogo from "../../images/name.png";
import IndexChart from "../component/indexchart";
import { Skeleton } from "@mui/material";
import SearchBar from "../../Shared/SearchBar/SearchBar";
import { AuthContext } from "../../Shared/context/auth-context";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { addCompanies } from "../../utils/companiesSlice";
import axios from "axios";

function Home() {
  const [page, setPage] = useState(1);
  const [searchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [fontSize, setFontSize] = useState(getFontSize(window.innerWidth));
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const { user } = useContext(AuthContext);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const companiess = useSelector((store) => store.companies.companies); //subscribing to the store
  const dispatch = useDispatch(); //writing data to store

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, searchQuery]);

  useEffect(() => {
    const handleResize = () => {
      setFontSize(getFontSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getFontSize(width) {
    if (width <= 768) {
      return "0.8em";
    } else if (width <= 1024) {
      return "0.9em";
    } else {
      return "1em";
    }
  }

  const fetchCompanyNames = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/screener/companies?limit=7`
      );
      const data = response.data;
      // sorting in ascending order
      const sortedData = data.data.sort((a, b) => {
        if (a["Company Name"] < b["Company Name"]) {
          return -1;
        }
        if (a["Company Name"] > b["Company Name"]) {
          return 1;
        }
        return 0;
      });
      dispatch(addCompanies(sortedData));
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanyNames(); // Fetch company names when the component mounts
  }, []);

  const history = useHistory();

  // replace"%" with "-"
  const handleSelectResult = (company) => {
    // Replace spaces with hyphens
    const companyNameWithHyphens = company["Company Name"]
      ? company["Company Name"].replace(/ /g, "-")
      : "";

    // Define the behavior for selecting a result
    history.push(
      `/company-name/${encodeURIComponent(companyNameWithHyphens)}`,
      {
        company: company,
      }
    );
  };

  useEffect(() => {
    if (user) {
      setShowLoginPrompt(false); // Hide prompt if user is logged in
      return;
    }

    const loginPromptClosedAt = localStorage.getItem("loginPromptClosedAt");
    const now = new Date().getTime();

    // Function to show login prompt
    const showLoginPromptIfNeeded = () => {
      if (!user) {
        setShowLoginPrompt(true);
      }
    };

    // Check if the prompt was closed within the last hour
    if (loginPromptClosedAt && now - loginPromptClosedAt < 60 * 60 * 1000) {
      // It's been less than 1 hour since the prompt was closed, set timer to show after 1 hour
      const delay = 60 * 60 * 1000 - (now - loginPromptClosedAt);
      const timer = setTimeout(showLoginPromptIfNeeded, delay);
      return () => clearTimeout(timer);
    } else {
      // Show immediately if not logged in
      showLoginPromptIfNeeded();
    }
  }, [user]);

  const handleCloseLoginPrompt = () => {
    const now = new Date().getTime(); // Current time in milliseconds
    localStorage.setItem("loginPromptClosedAt", now);
    setShowLoginPrompt(false);
  };

  const googleLogin = () => {
    window.open(`${SERVER_URL}/auth/login/federated/google`, "_self");
  };

  return (
    <>
      {loading ? (
        <div>
          <div
            className="Welcome_text_div_home"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "9rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={200}
              height={50}
              style={{ marginRight: "5px" }}
            />
            <Skeleton
              variant="trianglular"
              width={50}
              height={50}
              style={{ marginRight: "5px" }}
            />
            <Skeleton variant="rectangular" width={360} height={50} />
          </div>
          <div
            className="Home_page_search_div"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "4rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={600}
              height={60}
              style={{ borderRadius: "25px" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "5rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={300}
              height={30}
              style={{ marginRight: "10px" }}
            />
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={200}
                height={30}
                style={{ borderRadius: "10px" }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="Home-page-container">
          <Helmet>
            <title>Compliant Stock Screener & Financial Indices | Finsha</title>
            <meta
              name="description"
              content="Explore Compliant and Non-Compliant stocks with our advanced screener. Stay updated with major indices like Nifty50 and Sensex30."
            />
            <meta
              name="keywords"
              content="Compliant Stocks, Stock Screener, Financial Indices"
            ></meta>
            <meta
              name="tags"
              content="Stock Screener, Compliant and Non-Compliant Stocks, Nifty50, Sensex30"
            />
          </Helmet>
          <div className="homepage__second_container">
            <div className="Welcome_text_div_home">
              <h1 className="hello-text mt-10">
                <img
                  src={FinshaLogo}
                  alt="Finsha"
                  className="ourImage inline animate-bounce"
                />
                <b
                  className="Finvestshariah_name"
                  style={{ fontSize: fontSize }}
                >
                  {" "}
                  <SendRoundedIcon style={{ color: "green" }} />
                  Stock Search Engine
                </b>
                <span className="mobile-content">
                  A Compliant Stock Screener
                </span>
              </h1>
            </div>
            <div className="Home_page_search_div">
              {/* <SearchBar handleSelectResult={handleSelectResult} /> */}
              <SearchBar
                autoFocus={true}
                handleSelectResult={handleSelectResult}
              />
            </div>
            <div className="w-[80%] lg:w-[60%] xl:w-[50%] mx-auto">
              {companiess.length > 0 && (
                <div className=" suggested-companies">
                  <div className="font-bold inline">Suggested :</div>
                  <div className="flex whitespace-nowrap overflow-auto gap-2 no-scrollbar scroll-smooth">
                    {companiess.map((company) => (
                      <div
                        key={company._id}
                        onClick={() => handleSelectResult(company)}
                        className="suggested-company"
                      >
                        {company["Company Name"]}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {showLoginPrompt && (
              <div
                style={{
                  position: "fixed",
                  top: "90px", // Adjust based on your header height
                  right: "20px",
                  zIndex: 50,
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "26px",
                  width: "300px",
                  animation: showLoginPrompt
                    ? "fadeIn 0.5s ease-out"
                    : "fadeOut 0.5s ease-in",
                  transition: "opacity 0.5s ease-in-out",
                  opacity: showLoginPrompt ? 1 : 0,
                }}
              >
                <CloseIcon
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "5px",
                    color: "#4B5563",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseLoginPrompt}
                />
                <div style={{ position: "relative" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "16px",
                    }}
                  >
                    Sign in to Finsha with Google
                  </p>
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      border: "1px solid #d1d5db",
                      borderRadius: "25px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: 500,
                      width: "100%",
                      transition: "background-color 0.3s",
                    }}
                    onClick={googleLogin}
                  >
                    <img
                      src="https://img.icons8.com/color/24/000000/google-logo.png"
                      alt="Google Logo"
                      style={{ marginRight: "8px" }}
                    />
                    Login with Google
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <IndexChart />
    </>
  );
}

export default Home;
