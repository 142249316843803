import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css";
import axios from "axios";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState({ sections: [] });
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const cachedPolicy = localStorage.getItem("privacyPolicy");
  //   if (cachedPolicy) {
  //     setPrivacyPolicy(JSON.parse(cachedPolicy));
  //   } else {
  //     fetch(`${SERVER_URL}/finsha/privacypolicy`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setPrivacyPolicy(data);
  //         localStorage.setItem("privacyPolicy", JSON.stringify(data));
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching privacy policy:", error);
  //       });
  //   }
  // }, []);

  // replace fetch with axios
  useEffect(() => {
    const cachedPolicy = localStorage.getItem("privacyPolicy");
    if (cachedPolicy) {
      setPrivacyPolicy(JSON.parse(cachedPolicy));
    } else {
      axios
        .get(`${SERVER_URL}/finsha/privacypolicy`)
        .then((response) => {
          const data = response.data;
          setPrivacyPolicy(data);
          localStorage.setItem("privacyPolicy", JSON.stringify(data));
        })
        .catch((error) => {
          console.error("Error fetching privacy policy:", error);
        });
    }
  }, []);

  return (
    <div className="privacy-container">
      <h1 className="policy-heading">Privacy Policy</h1>
      {privacyPolicy.sections.map((section, index) => (
        <div key={index} className="policy-section" style={{ "--i": index }}>
          {section.title && <h2 className="policy-titles">{section.title}</h2>}
          {section.content &&
            section.content.map((paragraph, pIndex) => (
              <p key={pIndex} className="policy-paragraph">
                {paragraph}
              </p>
            ))}
          {section.bulletPoints &&
            section.bulletPoints.map((bullet, bIndex) => (
              <ul key={bIndex} className="policy-bullet-list">
                {bullet.title && (
                  <li className="policy-bullet-title">{bullet.title}</li>
                )}
                {Array.isArray(bullet.content) ? (
                  <ul className="policy-sub-bullet-list">
                    {bullet.content.map((subBullet, sbIndex) => (
                      <li key={sbIndex} className="policy-sub-bullet">
                        {subBullet}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <li className="policy-bullet-content">{bullet.content}</li>
                )}
              </ul>
            ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
