import React, { useState, useEffect } from "react";
import "./InvestorsGrievancesPortal.css";
import LoadingSpinner from "../../Shared/UIElements/LoadingSpinner";
import axios from "axios";

function InvestorsGrievancesPortal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [grievance, setGrievance] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    category: "", // Initialize the category state
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleChange = (event) => {
    setGrievance({
      ...grievance,
      [event.target.name]: event.target.value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   setLoading(true);

  //   fetch(`${SERVER_URL}/auth/submit-grievance`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(grievance),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(response.statusText);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.status !== "success") {
  //         throw new Error(data.message);
  //       }
  //       setFormSubmitted(true);
  //       setShowBackdrop(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setFormSubmitted(false);
  //       setShowBackdrop(true);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setGrievance({
  //         name: "",
  //         email: "",
  //         subject: "",
  //         message: "",
  //         category: "", // Reset the category as well
  //       });
  //     });
  // };

  // replace fetch with axios
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        `${SERVER_URL}/auth/submit-grievance`,
        grievance,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;

      if (data.status !== "success") {
        throw new Error(data.message);
      }

      setFormSubmitted(true);
      setShowBackdrop(true);
    } catch (error) {
      console.error("Error:", error);
      setFormSubmitted(false);
      setShowBackdrop(true);
    } finally {
      setLoading(false);
      setGrievance({
        name: "",
        email: "",
        subject: "",
        message: "",
        category: "", // Reset the category as well
      });
    }
  };

  const closeBackdrop = () => {
    setShowBackdrop(false);
  };

  return (
    <>
      <div className="investors-portal-container bg-gray-100 min-h-[70vh] p-4 mt-12 font-roboto ">
        <div
          itemScope
          itemType="https://schema.org/WebPage"
          className="grievances-section bg-white shadow-md rounded-md p-6 mx-auto max-sm:w-[95%] md:max-w-[80%] "
          key={formSubmitted}
        >
          <h1
            itemProp="name"
            className="portal-title text-2xl font-bold mb-6 text-center"
          >
            Connect - Finsha
          </h1>
          <div className="form-overlay-container relative">
            {loading && (
              <div className="loading-container absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                <LoadingSpinner overlay />
              </div>
            )}

            {formSubmitted && showBackdrop && (
              <div
                className="backdrop-container fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                onClick={closeBackdrop}
              >
                <div className="success-message-container bg-white p-6 rounded-md shadow-lg text-center">
                  <h6 className="success_message_giverance text-lg mb-4">
                    🌟 Thank you for submitting your Feedback! We truly value
                    your input. 🌟
                  </h6>
                  <button
                    className="close-button bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={closeBackdrop}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
            <form className="grievance-form space-y-4" onSubmit={handleSubmit}>
              <div className="form-group name-group">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name:*
                </label>
                <input
                  className="input-name mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  type="text"
                  id="name"
                  name="name"
                  value={grievance.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group email-group">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email:*
                </label>
                <input
                  className="input-email mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  type="email"
                  id="email"
                  name="email"
                  value={grievance.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group category-group">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category:*
                </label>
                <select
                  className="select-category mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  id="category"
                  name="category"
                  value={grievance.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a category</option>
                  <option value="Subscription Issue">Subscription Issue</option>
                  <option value="Stock Enquiry">Stock Enquiry</option>
                  <option value="Feedback/Suggestions">
                    Feedback/Suggestions
                  </option>
                  <option value="Compliant/Grievance">
                    Compliant/Grievance
                  </option>
                </select>
              </div>
              <div className="form-group subject-group">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-700"
                >
                  Subject:*
                </label>
                <input
                  className="input-subject mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  type="text"
                  id="subject"
                  name="subject"
                  value={grievance.subject}
                  onChange={handleChange}
                  required
                  maxLength="100"
                />
              </div>
              <div className="form-group message-group">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your Message (1500 characters limit):
                </label>
                <textarea
                  className="textarea-message mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  id="message"
                  name="message"
                  value={grievance.message}
                  onChange={handleChange}
                  required
                  maxLength="1500"
                  rows="10"
                ></textarea>
              </div>
              <div className="form-group submit-group">
                <button
                  className="submit-button w-full bg-blue-500 text-white py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestorsGrievancesPortal;

// import React, { useState, useEffect } from 'react';
// const SERVER_URL = process.env.REACT_APP_BACKEND_LOCAL_URL
// import './InvestorsGrievancesPortal.css';
//  from '../Footer';

// function InvestorsGrievancesPortal() {

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const [grievance, setGrievance] = useState({
//     name: '',
//     email: '',
//     subject: '',
//     message: '',
//   });

//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [showBackdrop, setShowBackdrop] = useState(false);

//   const handleChange = (event) => {
//     setGrievance({
//       ...grievance,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     // Send grievance data to the backend server
//     fetch(`${SERVER_URL}/auth/submit-grievance`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(grievance),
//     })
//       .then((data) => {
//         setFormSubmitted(true);
//         setShowBackdrop(true);
//         // console.log('Form submitted:', formSubmitted);
//         // console.log('Show backdrop:', showBackdrop);
//         // console.log(data.message);
//       })
//       .catch((error) => {
//         // console.error('Error:', error);
//       });

//     setGrievance({
//       name: '',
//       email: '',
//       subject: '',
//       message: '',
//     });
//   };

//   const closeBackdrop = () => {
//     setShowBackdrop(false);
//   };

//   return (
//     <><div className='investorsPortal'>
//       <div itemScope itemType="https://schema.org/WebPage" className="InvestorsGrievances" key={formSubmitted}>
//         <h1 itemProp="name" className='Invester-portal-Title'>Feedback - Finsha</h1>
//         {formSubmitted && showBackdrop ? (
//           <div className="backdrop" onClick={closeBackdrop}>
//             <div className="success-message">
//               Thank you for submitting your Feedback.
//               <button className="ok-button" onClick={closeBackdrop}>OK</button>
//             </div>
//           </div>
//         ) : null}
//         <form className="portal-giverance" onSubmit={handleSubmit}>
//   <div className="giverance-form">
//     <label htmlFor="name">Name:*</label>
//     <input
//     className='Name-inp'
//       type="text"
//       id="name"
//       name="name"
//       value={grievance.name}
//       onChange={handleChange}
//       required
//     />
//   </div>
//   <div className="giverance-form">
//     <label htmlFor="email">Email:*</label>
//     <input
//       type="email"
//       id="email"
//       name="email"
//       value={grievance.email}
//       onChange={handleChange}
//       required
//     />
//   </div>
//   <div className="giverance-form">
//     <label htmlFor="subject">Subject:*</label>
//     <input
//       type="text"
//       id="subject"
//       name="subject"
//       value={grievance.subject}
//       onChange={handleChange}
//       required
//     />
//   </div>
//   <div className="giverance-message">
//     <label htmlFor="message">Your Message (1000 characters limit):</label>
//     <textarea
//       id="message"
//       name="message"
//       value={grievance.message}
//       onChange={handleChange}
//       required
//       maxLength="1000"
//     ></textarea>
//   </div>
//   <button className='givernace-submit' type="submit">Submit</button>
// </form>

//       </div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default InvestorsGrievancesPortal;
