import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./UnSubscribeEmail.css";

const UnSubscribeStockIdeaEmail = () => {
  const { token } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState("processing");
  const [message, setMessage] = useState("Processing your request...");
  const [loading, setLoading] = useState(true); // Define loading state
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const unsubscribe = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/finsha/unsubscribe/stockidea/${token}`
        );
        setMessage(response.data.message);
        setStatus("success"); // Update the status on success
      } catch (error) {
        setMessage(error.response?.data?.message || "An error occurred.");
        setStatus("error"); // Update the status on error
      } finally {
        setLoading(false); // Set loading to false after the request completes
      }
    };

    unsubscribe();
  }, [token]);

  const renderIcon = () => {
    if (loading) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "success") {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="4x"
          className="icon-green"
        />
      );
    } else if (status === "error") {
      return (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          size="4x"
          className="icon-red"
        />
      );
    }
  };

  const renderTitle = () => {
    if (status === "success") {
      return "Successfully Unsubscribed";
    } else if (status === "error") {
      return "Failed to Unsubscribe";
    } else {
      return "Unsubscribe";
    }
  };

  const handleButtonClick = () => {
    history.push("/");
  };

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-box">
        {renderIcon()}
        <h1 className="unsubscibe__title">{renderTitle()}</h1>
        <div className="message-container">
          {message.split("\n").map((line, index) => (
            <p
              key={index}
              style={{ textAlign: index >= 2 ? "left" : "center" }}
            >
              {line}
            </p>
          ))}
        </div>
        <div>
          <button className="home-button" onClick={handleButtonClick}>
            Go to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnSubscribeStockIdeaEmail;
