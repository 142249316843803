import React, { useState, useEffect } from "react";
import axios from "axios";
import "./News.css";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Skeleton from "@mui/material/Skeleton";

const NewsComponent = ({ name }) => {
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (!name) return;

    const fetchNews = async () => {
      setLoading(true);
      try {
        // Replace spaces with hyphens
        const formattedName = name.replace(/\s+/g, "-");
        const response = await axios.get(
          `${SERVER_URL}/screener/news/name/${formattedName}`
        );
        setNews(response.data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch news");
        console.error("Error fetching news:", err);
      }
      setLoading(false);
    };

    fetchNews();
  }, [name, SERVER_URL]);

  // Don't render anything if there's an error or no news
  if (error || !news?.articles?.length) return null;

  if (loading) {
    return (
      <div className="news-container__newComponent">
        <h2 className="news_component_title">
          Latest News <NewspaperIcon />
        </h2>
        <ul className="news_component__unorderedlist">
          {Array.from({ length: 5 }).map((_, index) => (
            <li key={index} className="news_component__list_data">
              <Skeleton variant="text" width={210} height={30} />
              <Skeleton variant="text" width={190} />
              <Skeleton variant="rectangular" height={118} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="news-container__newComponent">
      <h2 className="news_component_title">
        Latest News <NewspaperIcon />
      </h2>
      <ul className="news_component__unorderedlist">
        {news.articles
          .slice()
          .reverse() // Reverse the order of articles
          .map((article, index) => (
            <li key={index} className="news_component__list_data">
              <p className="newscomponent__headline">
                <strong>{article.headline}</strong>
              </p>
              <a
                className="news_Compoentn__linkingurl"
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more...
              </a>
              <div className="newscomponent__date_time">
                <span className="newscomponent__time">
                  Time: {article.time}
                </span>
                <span className="newscomponent__date">
                  Date: {article.date}
                </span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NewsComponent;
