import ReactGA from 'react-ga4';

// Initialize GA4 with your Measurement ID and enable debug mode
export const initializeGA = () => {
  ReactGA.initialize('G-3QMJ2F7Q3Q');  // Replace with your GA4 Measurement ID , { debug: true } 
  ReactGA.send('pageview'); // Optional: Sends the initial pageview event when the app loads
};

// Track page view
export const trackPageView = (path,title) => {
  ReactGA.send({ hitType: 'pageview', page: path,title });
  // console.log(`GA Page View Tracked: ${title}`);
};

// Track custom events
export const trackEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
  // console.log(`GA Event Tracked: Category: ${category}, Action: ${action}, Label: ${label}`);
};


