import React, { useContext, useState } from "react";
import { Avatar } from "@mui/material";
import { AuthContext } from "../context/auth-context";

const UserAvatar = ({ size = 70 }) => {
  const auth = useContext(AuthContext);
  const { user } = auth;
  const [imageUpdated, setImageUpdated] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getImageURL = () => {
    if (!user || !user.profileImageURL) return "";

    if (user.profileImageURL.startsWith("http")) {
      return `${user.profileImageURL}?${imageUpdated ? "1" : "0"}`;
    } else {
      return `${SERVER_URL}/${user.profileImageURL}?${
        imageUpdated ? "1" : "0"
      }`;
    }
  };

  return (
    <div className="shadow-2xl shadow-black rounded-full user-avatar">
      {user && user.profileImageURL ? (
        <Avatar
          alt="Profile"
          src={getImageURL()}
          style={{ width: size, height: size }}
        />
      ) : user && user.fname ? (
        <Avatar alt="Profile" style={{ width: size, height: size }}>
          {user.fname[0].toUpperCase()}
        </Avatar>
      ) : (
        <Avatar alt="Profile" style={{ width: size, height: size }} />
      )}
    </div>
  );
};

export default UserAvatar;
